
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import { getIcons } from '../../../shared/services/settings/icons';

// icons state
import { investmentObjectTypeIconsModule } from '../../../shared/state/submodules/objects';

// state module
import { investmentObjectTypesModule } from '../../../shared/state/submodules/objects';

// dataRequest
import { InvestmentObjectDataRequest } from '../../../shared/requests/investment-object-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions, IconChooser},
})
export default class ObjectsTypesSettingsForm extends FormComponent<InvestmentObjectDataRequest> {
    public actionsTypes = investmentObjectTypesModule.actionsTypes;
    public store = this.$store.state.investmentsState.objectTypesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new InvestmentObjectDataRequest();

    public iconsActionsTypes = investmentObjectTypeIconsModule.actionsTypes;
    public iconsStore: any = this.$store.state.investmentsState
        .objectTypesState;

    get loadingIcons() {
        return this.$store.state.investmentsState.objectTypeIconsState.loading;
    }

    get iconsAvailable() {
        return this.$store.state.investmentsState.objectTypeIconsState.data;
    }

    public created() {
        this.$store.dispatch(this.iconsActionsTypes.FETCH_DATA, {
            simple: false,
        });
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj typ kategorii' : 'Dodaj typ kategorii';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }

    public focusOnPreviousInput() {
        setTimeout(() => {
            /// @ts-ignore
            this.$refs.input1.focus();
        }, 10);
    }

}
