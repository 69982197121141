
import {Component} from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import {parseDate} from '@/shared/helpers';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';

import {mdiPencil} from '@mdi/js';
import TicketsForm from '@/modules/tickets/components/form.vue';

import StatusesPicker from '@/modules/tickets/components/pickers/status-picker.vue';
import PriorityPicker from '@/modules/tickets/components/pickers/priority-picker.vue';
import TagsPicker from '@/modules/tickets/components/pickers/tags-picker.vue';
import CategoryPicker from '@/modules/tickets/components/pickers/category-picker.vue';

import {Ticket, ticketsModule} from '@/modules/tickets/shared';
import {FormRules} from '@/shared/validation/form-rules';
import TicketComments from '@/modules/tickets/components/messages/comments.vue';
import UserPicker from '@/modules/tickets/components/pickers/user-picker.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';
import LinkedEvents from '@/modules/calendar/components/linked-events.vue';
import NoteDetails from '@/modules/acceptances/components/notes/details.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import {investmentSubjectsModule} from '@/modules/investments/shared/state/submodules';
import { investmentsModule } from '@/modules/investments/shared/state/module';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import { httpClient } from '@/shared/services';
import SubjectSimpleDetails from '@/modules/investments/components/subjects/simple-details.vue';
import ObjectSimpleDetails from '@/modules/investments/components/objects/simple-details.vue';
import InvestmentSimpleDetails from '@/modules/investments/components/simple-details.vue';

@Component({
    components: {
        LinkedEvents,
        TopToolbar,
        StatusesPicker,
        TicketComments,
        PriorityPicker,
        TagsPicker,
        UserPicker,
        CategoryPicker,
        TicketsForm,
        TooltipBtn,
        TextContrast,
        ObservableWatch,
        NoteDetails,
        NameReduce,
        SubjectSimpleDetails,
        ObjectSimpleDetails,
        InvestmentSimpleDetails,
    },
})
export default class TicketsDetails extends DetailsComponent<Ticket> {
    public store: any = this.$store.state.ticketsState;
    public actionsTypes = ticketsModule.actionsTypes;
    public subjectActionsTypes = investmentSubjectsModule.actionsTypes;
    public investmentActionTypes = investmentsModule.actionsTypes;
    public showInvestment: string = this.investmentActionTypes.SHOW_ITEM;
    public showSubject: string = this.subjectActionsTypes.SHOW_ITEM;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public parseDate = parseDate;
    public tab: any = 0;
    public formRules = new FormRules();
    public formDialog = false;
    public editedItem = {};
    public edit!: boolean;
    public assignedObject = {};
    public isDataLoading = true;
    public icons = {
        mdiPencil,
    };

    public notesDialog = false;

    get isEditPermitted() {
        return this.isPermitted('edit');
    }

    get assignableType() {
        switch (this.item.assignable_type) {
            case 'acceptance-note':
                return 'Uwaga do odbioru';
            case 'investment':
                return 'Inwestycja';
            case 'investment-object':
                return 'Kategoria inwestycji';
            case 'investment-subject':
                return 'Przedmiot inwestycji';
        }
    }

    get assignableLoading() {
        switch (this.item.assignable_type) {
            case 'acceptance-note': return false;
            case 'investment-subject': return this.$store.state.investmentsState.subjectsState.loadingItem;
            case 'investment-object': return this.isDataLoading;
            case 'investment': return this.$store.state.investmentsState.loadingItem;
        }
        return false;
    }

    get object() {
        switch (this.item.assignable_type) {
            case 'investment-object': return this.assignedObject;
            case 'investment-subject':
                return this.$store.state.investmentsState.subjectsState.current;
            case 'investment':
                return this.$store.state.investmentsState.current;
            default: return null;
        }
    }

    public created() {
        this.fetchDetails();
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`tickets.${action}`);
    }

    public fetchCallback({data}: ModuleShowPayload<Ticket>) {
        if (data && data.assignable_id && data.assignable_type === 'investment-subject') {
            this.$store.dispatch(this.showSubject, data.assignable_id);
        } else if (data && data.assignable_id && data.assignable_type === 'investment-object') {
            httpClient.get(`api/v1/investments/objects/${this.item.assignable_id}/summary`)
                .then((d: any) => {
                    this.assignedObject = d.data.data;
                    this.isDataLoading = false;
                }).catch((e: any) => {
                    this.isDataLoading = false;
                });
        } else if (data && data.assignable_id && data.assignable_type === 'investment') {
            this.$store.dispatch(this.showInvestment, data.assignable_id);
        }
    }

    public editItemToolbar() {
        this.formDialog = true;
        this.editedItem = {...this.item};
    }

    public closeForm() {
        this.formDialog = false;
    }

    public updateUser(e: Promise<ModuleShowPayload<Ticket>>) {
        e.then(({data}) => {
            this.$store.commit('SHOW_SNACKBAR', {
                type: 'success',
                text: `Osoba została przypisana do zadania`,
            });

            this.$store.commit(this.mutationTypes.UPDATE_CURRENT, data);
        })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
            });
    }

    public itemSaved() {
        this.fetchDetails();
        this.formDialog = false;
    }
}
