
import { Component } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import {
    investmentSubjectPropertyTypesModule,
    investmentSubjectRoomTypesModule,
    investmentSubjectTypesModule,
} from '@/modules/investments/shared/state/submodules';
import { privateStatuses, publicStatuses, subjectCodeVariables, subjectNameVariables } from '@/shared/config/investments';
import { InvestmentSubjectGenerated } from '@/modules/investments/shared/models/investment-subject';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import SubjectForm from '../../../settings/subject/form.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import GeneratorForm from '@/modules/investments/components/generator/components/form-component';
import {
    investmentSubjectPropertiesListGeneratorHeaders,
    investmentSubjectRoomsListGeneratorEditorHeaders,
} from '@/modules/investments/shared/config';
import SelectMediaList from '@/modules/media/components/select.vue';
import { roomTypes } from '@/modules/investments/components/generator/templates/blocks/models/types';
import SubjectPropertyTypesForm from '@/modules/investments/components/settings/subject-properties/form.vue';
import { InvestmentSubjectRoomGenerated } from '@/modules/investments/shared/models/subjects/investment-subject-room';
import { MediaFile } from '@/modules/media/shared/models/media-file';
import { MediaFileSelect } from '@/modules/media/shared/models/media-file-select';
import { InvestmentSubjectMediaFileGenerated } from '@/modules/investments/shared/models/subjects/investment-subject-media-file';
import { InvestmentSubjectRoomType } from '@/modules/investments/shared/models/subjects/rooms/investment-room-type';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import { subjectCodeRules } from '@/modules/investments/shared/helpers/subjects';
import Hint from '@/shared/components/elements/hint.vue';

@Component({
    components: {
        VariableTextField,
        ActionConfirmDialog,
        FormActions,
        IconChooser,
        SelectMediaList,
        AutoCompleteWithAddNew,
        Hint,
    },
    props: {
        itemData: Object,
        show: Boolean,
        storey: Number,
        edit: {
            type: Boolean,
            default: false,
        },
    },
})
export default class GeneratorInvestmentSubjectForm extends GeneratorForm<InvestmentSubjectGenerated> {
    public roomsHeaders = investmentSubjectRoomsListGeneratorEditorHeaders;
    public propertiesHeaders = investmentSubjectPropertiesListGeneratorHeaders;
    public propertyTypesState = this.$store.state.investmentsState.subjectPropertyTypesState;
    public subjectVariables = subjectNameVariables;
    public subjectCodeVariables = subjectCodeVariables;
    public roomTypesActions = investmentSubjectRoomTypesModule.actionsTypes;
    public propertyForm = SubjectPropertyTypesForm;
    public propertyTypeModule = investmentSubjectPropertyTypesModule;
    public mediaSelectDialog = false;
    public typesActions = investmentSubjectTypesModule.actionsTypes;
    public publicStatuses = publicStatuses;
    public privateStatuses = privateStatuses;
    public codeErrorMessages: string[] = [];
    public subjectCodeRules = subjectCodeRules;

    // data required for add new type
    public subjectTypesState = this.$store.state.investmentsState.subjectTypesState;
    public investmentSubjectTypesModule = investmentSubjectTypesModule;
    public investmentSubjectTypesFetchAction = investmentSubjectTypesModule.actionsTypes.FETCH_DATA;
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public SubjectForm = SubjectForm;

    get roomTypes() {
        return this.$store.state.investmentsState.subjectRoomTypesState.dataList;
    }

    get roomTypesLoading() {
        return this.$store.state.investmentsState.subjectRoomTypesState.loadingList;
    }

    get subjectTypes() {
        return this.subjectTypesState.data;
    }

    public preventCancel() {
        this.cancelForm();
    }

    get subjectTypesLoading() {
        return this.subjectTypesState.loading;
    }

    get formTitle() {
        return this.edit ? 'Edytuj przedmiot inwestycji' : 'Dodaj przedmiot inwestycji';
    }

    public created() {
        this.$store.dispatch(this.typesActions.FETCH_DATA, {
            simple: false,
        });
        this.$store.dispatch(this.roomTypesActions.FETCH_DATA, { simple: true });
    }

    public mounted() {
        this.form = this.$refs.createInvestmentSubjectForm;
    }

    public changeName(value: any) {
        if (this.itemData && !this.itemData.name) {
            this.$set(this.itemData, 'name', value.name);
        }
    }

    public addMedia(items: MediaFileSelect[]) {
        this.mediaSelectDialog = false;
        // tslint:disable-next-line:variable-name
        this.itemData.media = items.map((media_file: MediaFile) => {
            return { media_file_id: media_file.id, media_file };
        }) as InvestmentSubjectMediaFileGenerated[];
    }

    public changeRoomNameByType(item: InvestmentSubjectRoomGenerated, id: number) {
        const room = { ...item };
        if (!room.name || this.roomTypes.map(({ name }: InvestmentSubjectRoomType) => name).includes(room.name)) {
            const roomType = this.roomTypes.find((type: InvestmentSubjectRoomType) => type.id === id);
            room.name = roomType ? roomType.name : '';
            if (this.itemData.rooms) {
                this.itemData.rooms.splice(this.itemData.rooms.indexOf(item), 1, room);
            }
        }
    }
}
