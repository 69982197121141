
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

import {tagListHeaders, tagsModule, TicketTag, TicketTagDataRequest} from '@/modules/tickets/shared';
import TagForm from './form.vue';

@Component({
    components: {
        ItemsTable,
        TagForm,
        TextContrast,
        IconContrast,
    },
})
export default class TicketTagsList extends ListComponent<TicketTag, TicketTagDataRequest> {
    public headers: object[] = tagListHeaders;
    public actionsTypes = tagsModule.actionsTypes;
    public mutationTypes = tagsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.ticketsState.tagsState;
    public props: ModuleProps = tagsModule.moduleProps;

    public mounted() {
        this.fetchData();
    }

}
