import {Component, Prop, Vue} from 'vue-property-decorator';
import {FormRules} from '@/shared/validation/form-rules';
import {InvestmentObjectGenerated} from '@/modules/investments/shared/models/investment-object';
import {InvestmentSubjectGenerated} from '@/modules/investments/shared/models/investment-subject';
import TemplateForm from '@/modules/investments/components/generator/templates/template/template-form';

@Component
export default class TemplateSubjectForm extends TemplateForm {
    @Prop(Object) public itemData!: InvestmentSubjectGenerated;

    get edit() {
        return !!this.itemData.id;
    }
}
