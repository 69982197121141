import {InvestmentSubjectOperationDataRequest} from '@/modules/investments/shared/requests/subjects/investment-subject-operation-data-request';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';

export class InvestmentSubjectCopyOperationDataRequest extends InvestmentSubjectOperationDataRequest {
    // tslint:disable-next-line:variable-name
    public investment_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_object_id: number|InvestmentObject = 0;
    public name?: string;
    public code?: string;
}
