
import {Component, Inject, Vue} from 'vue-property-decorator';
import humanizeDuration from 'humanize-duration';

@Component({
  props: {
    from: Object,
    createdAt: String,
    updatedAt: String,
    message: String,
    value: String,
    isEdited: Boolean,
    ownerId: Number,
  },
})
export default class MessageCard extends Vue {
  @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

  public currentTime = new Date();
  public isHovered = false;
  public ownerId!: number;

  get suitableDate() {
    const date1: any = new Date(this.$props.createdAt);
    const date2: any = new Date(this.currentTime);
    const diffTime = Math.abs(date2 - date1);
    return humanizeDuration(diffTime, {language: 'pl', largest: 1, round: true}) + ' temu';
  }

  get isOwner() {
    return this.$store.state.authState.user.id === this.ownerId;
  }

  get wasEdited() {
    return new Date(this.$props.createdAt) < new Date(this.$props.updatedAt);
  }
}
