import { ListFilter } from '@/shared/types';


export const ticketsFilters: ListFilter[] = [
    { name: 'created_at', type: 'date', label: 'Data utworzenia', description: 'Wybierz zakres dat (od - do)' },
    { name: 'due_date', type: 'date', label: 'Termin', description: 'Wybierz zakres dat (od - do)' },
    { name: 'ticket_priority_id', type: 'select', label: 'Priorytet', description: 'Wybierz priorytet', data: [] },
    { name: 'assigned_user_id', type: 'search', label: 'Przydzielona osoba', description: 'Wyszukaj przydzieloną osobę',
     data: [] },
    { name: 'ticket_category_id', type: 'select', label: 'Kategoria', description: 'Wybierz kategorie', data: [] },
    { name: 'ticket_tag_id', type: 'search', label: 'Tag', description: 'Wybierz tagi', chip: true },
];

