
import ListComponent from '@/shared/components/layout/list/list-component';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { Component, Watch } from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import {
    investmentSubjectOperationsListFilters,
    investmentSubjectOperationsListHeaders,
} from '@/modules/investments/shared/config';
import {
    investmentObjectsModule,
    investmentSubjectOperationsModule,
    investmentSubjectTypesModule,
} from '@/modules/investments/shared/state/submodules';
import InvestmentSubjectForm from '@/modules/investments/components/objects/subjects/form.vue';
import { FooterProps } from '@/shared/components/layout/list/models/footer-props';
import { privateStatuses, publicStatuses } from '@/shared/config/investments';
import { investmentsModule } from '@/modules/investments/shared/state/module';
import { logger } from '@/shared/services';
import { callSuper, sortItemsTree } from '@/shared/helpers';
import echoInstance from '@/echo';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import MoveSubjectsForm from '@/modules/investments/components/subjects/move.vue';
import { InvestmentSubjectOperationDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-operation-data-request';
import { InvestmentSubjectsState } from '@/modules/investments/shared/state/state';
import DataChangedWarningDialog from '@/shared/components/dialogs/data-changed-warning-dialog.vue';
import CopySubjectsForm from '@/modules/investments/components/subjects/copy.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        NameReduce,
        FormActions,
        MoveSubjectsForm,
        CopySubjectsForm,
        ItemsTable,
        InvestmentSubjectForm,
        TooltipBtn,
        DataChangedWarningDialog,
    },
})
export default class InvestmentSubjectsList extends ListComponent<
    InvestmentSubject,
    InvestmentSubjectOperationDataRequest
> {
    public headers: object[] = investmentSubjectOperationsListHeaders;
    public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
    public investmentsActions = investmentsModule.actionsTypes;
    public subjectTypesActions = investmentSubjectTypesModule.actionsTypes;
    public objectsActions = investmentObjectsModule.actionsTypes;
    public mutationTypes = investmentSubjectOperationsModule.mutationsTypes;
    public objectFilterIndex = 0;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: InvestmentSubjectsState = this.$store.state.investmentsState.subjectOperationsState;
    public itemsCount = [10, 25, 50, 100, 500, 1000, 5000];
    public footer = new FooterProps(this.itemsCount);
    public filters = investmentSubjectOperationsListFilters;
    public props: ModuleProps = investmentSubjectOperationsModule.moduleProps;
    public load: boolean = false;
    public moveSubjectsDialog: boolean = false;
    public copySubjectsDialog: boolean = false;
    public removeSubjectsDialog: boolean = false;
    public dataChanged: boolean = false;
    public dataChangedWarning: string = '';

    public copySubjectsRequest() {
        this.editedItem = new InvestmentSubjectOperationDataRequest();
        this.editedItem.selection = this.selected;
        this.copySubjectsDialog = true;
    }

    public moveSubjectsRequest() {
        this.editedItem = new InvestmentSubjectOperationDataRequest();
        this.editedItem.selection = this.selected;
        this.moveSubjectsDialog = true;
    }

    public editSubjectsRequest() {
        this.editedItem = new InvestmentSubjectOperationDataRequest();
        this.editedItem.selection = this.selected;
        this.$router.push({ name: 'edit-subjects-operation' });
    }

    public removeSubjectsRequest() {
        this.editedItem = new InvestmentSubjectOperationDataRequest();
        this.editedItem.selection = this.selected;
        const item = Object.assign({}, { selection: this.editedItem.selection.map((e: InvestmentSubject) => e.id) });
        this.$store.dispatch(this.actionsTypes.REMOVE_SUBJECTS_CHECK, item).then((response) => {
            if (response === 200) {
                this.$router.push({ name: 'remove-subjects-summary' });
            }
        });
    }

    get subjectTypes() {
        return this.$store.state.investmentsState.subjectTypesState.dataList;
    }

    get investments() {
        return this.$store.state.investmentsState.dataList;
    }

    get objects() {
        return this.$store.state.investmentsState.objectsState.dataList;
    }

    get investmentsLoading() {
        return this.$store.state.investmentsState.loadingList;
    }

    get subjectTypesLoading() {
        return this.$store.state.investmentsState.subjectTypesState.loadingList;
    }

    get objectsLoading() {
        return this.$store.state.investmentsState.objectsState.loadingList;
    }

    public leavePresenceChannel() {
        const channelNameWithPrefix = `presence-client.${this.$store.state.authState.user.client_id}.investment_subject.${(this.editedItem as any).id}.edit`;
        echoInstance.leaveChannel(channelNameWithPrefix);
    }

    public itemSaved() {
        this.leavePresenceChannel();
        callSuper(this, 'itemSaved');
    }

    public closeForm() {
        this.leavePresenceChannel();
        callSuper(this, 'closeForm');
    }

    @Watch('editedItem')
    public onEditedItemChange() {
        if (this.editedItem) {
            const channelName = `client.${this.$store.state.authState.user.client_id}.investment_subject.${(this.editedItem as any).id}.edit`;

            echoInstance.join(channelName)
            .listen('.investment_subject_updated', (e: any) => {
                this.dataChanged = true;
                this.dataChangedWarning = e.description;
            });
        }
    }

    public created() {
        this.fetchData(() => {
            // TODO: Do ogarnięcia jak znajdziemy sposób na przytrzymywanie filtrów
            // if (this.editedItem && this.editedItem.selection) {
            //     this.selected = this.editedItem.selection;
            // }
            if (this.editedItem && this.editedItem.selection) {
                this.editedItem.selection = [];
            }
        });
    }

    public fetchData(callback?: (data: InvestmentSubject[]) => void) {
        if (!this.filters.map(({ name }) => name).includes('investment_id')) {
            this.$store
                .dispatch(this.investmentsActions.FETCH_DATA, { simple: true })
                .then(() => {
                    this.filters = [
                        ...this.filters,
                        {
                            type: 'search',
                            name: 'investment_id',
                            label: 'Inwestycja',
                            data: this.investments,
                            description: 'Wybierz inwestycje',
                        },
                    ];
                })
                .catch((error) => {
                    logger.error(error);
                });
        }

        if (!this.filters.map(({ name }) => name).includes('investment_subject_type_id')) {
            this.$store
                .dispatch(this.subjectTypesActions.FETCH_DATA, { simple: true })
                .then(() => {
                    this.filters = [
                        ...this.filters,
                        {
                            type: 'search',
                            name: 'investment_subject_type_id',
                            label: 'Typ przedmiotu',
                            data: this.subjectTypes,
                            description: 'Wybierz typ przedmiotu',
                        },
                    ];
                })
                .catch((error) => {
                    logger.error(error);
                });
        }

        this.setObjectFilter();

        callSuper(this, 'fetchData', callback);
    }

    public itemPrivateStatus(item: InvestmentSubject) {
        return privateStatuses.find((status) => status.value === item.private_status);
    }

    public setObjectFilter() {
        this.objectFilterIndex = this.filters.map(({ name }) => name).indexOf('investment_object_id');

        if (this.objectFilterIndex === -1) {
            this.$store
                .dispatch(this.objectsActions.FETCH_DATA, {
                    simple: true,
                })
                .then(() => {
                    this.filters = [
                        ...this.filters,
                        {
                            type: 'search',
                            name: 'investment_object_id',
                            label: 'Kategoria',
                            data: sortItemsTree(this.objects),
                            treeParent: 'investment',
                            tree: true,
                            description: 'Wybierz kategorię',
                        },
                    ];
                    this.objectFilterIndex = this.filters.map(({ name }) => name).indexOf('investment_object_id');
                })
                .catch((error) => {
                    logger.error(error);
                });
        }
    }

    public itemPublicStatus(item: InvestmentSubject) {
        return publicStatuses.find((status) => status.value === item.public_status);
    }
}
