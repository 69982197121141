var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({style:({ 'pointer-events': _vm.readOnly ? 'none' : 'auto' }),attrs:{"pill":"","color":_vm.activeCategory.color}},_vm.categories.length > 0 ? on : {}),[_c('text-contrast',{attrs:{"color":_vm.activeCategory.color}},[_vm._v(" "+_vm._s(_vm.activeCategory.name)+" ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"width":"300"}},[_c('v-list',_vm._l(((_vm.categories ?
              [
                  {
                      id: null,
                      name: 'Brak',
                  },
                  ..._vm.categories
              ]
              : [])),function(option){return _c('v-list-item',{key:option.id,staticClass:"d-block",attrs:{"disabled":_vm.activeCategory.id === option.id},on:{"click":() => {
                          _vm.chosenOption = option;
                          _vm.changeCategory();
                      }}},[_c('v-chip',{staticClass:"mt-2",attrs:{"disabled":_vm.activeCategory.id === option.id,"pill":"","color":option.color}},[_c('text-contrast',{attrs:{"color":option.color}},[_vm._v(" "+_vm._s(option.name)),_c('br')])],1),_c('br')],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }