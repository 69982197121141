
import { Component, Prop, Ref } from 'vue-property-decorator';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import echoInstance from '@/echo';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { parseDate } from '@/shared/helpers';
import { Investment } from '@/modules/investments/shared/models/investment';
import { investmentsModule } from '@/modules/investments/shared/state/module';
import { investmentsObjectsListHeaders } from '@/modules/investments/shared/config';
import InvestmentObjectsList from '@/modules/investments/components/objects/list.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import TotalCosts from '@/modules/investments/components/total-costs.vue';
import { costStatuses } from '@/shared/config/costs';
import LinkedEvents from '@/modules/calendar/components/linked-events.vue';
import RelatedContractors from '@/modules/investments/components/related-contractors/related-contractors.vue';
import DataChangedWarningDialog from '@/shared/components/dialogs/data-changed-warning-dialog.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        RelatedContractors,
        LinkedEvents,
        InvestmentObjectsList,
        ActionConfirmDialog,
        InvestmentTagChip,
        TopToolbar,
        TotalCosts,
        DataChangedWarningDialog,
    },
})
export default class InvestmentDetails extends DetailsComponent<Investment> {
    @Ref() public relatedContractors!: any;
    public store: any = this.$store.state.investmentsState;
    public actionsTypes = investmentsModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public headers = investmentsObjectsListHeaders;
    public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));
    public parseDate = parseDate;
    public dataChanged: boolean = false;
    public dataChangedWarning: string = '';

    public created() {
        if (!this.item || this.item !== ({} as Investment)) {
            this.fetchDetails();
        }
    }

    public beforeRouteLeave(from: any, to: any, next: any) {
        const channelNameWithPrefix = `presence-client.${this.$store.state.authState.user.client_id}.investment.${this.item.id}`;
        echoInstance.leaveChannel(channelNameWithPrefix);
        // To fix possible scrollbar issues
        this.relatedContractors.onRouteLeave();
        next();
    }

    public fetchCallback(response: ModuleShowPayload<Investment>) {
        const channelName = `client.${this.$store.state.authState.user.client_id}.investment.${this.$store.state.investmentsState.current.id}`;
        echoInstance.join(channelName)
        .listen('.investment_updated', (e: any) => {
            this.dataChanged = true;
            this.dataChangedWarning = e.description;
        });
    }
}
