import {InvestmentObjectGenerated} from '@/modules/investments/shared/models/investment-object';

export interface BlockInvestmentStage extends InvestmentObjectGenerated {
    hasStages: boolean;
}

export interface BlockInvestmentBuilding extends InvestmentObjectGenerated {
    lowestLevel: number;
}

export interface BlockInvestmentLevel extends InvestmentObjectGenerated {
    level: number;
}

export const roomTypes = [
    {id: 1, name: 'Łazienka'},
    {id: 2, name: 'Kuchnia'},
    {id: 3, name: 'Salon'},
    {id: 4, name: 'Sypialnia'},
    {id: 5, name: 'Pokój'},
    {id: 6, name: 'Garaż'},
    {id: 7, name: 'Miejsce Parkingowe'},
];
