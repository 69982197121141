
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import ObjectForm from './form.vue';
import {Component, Watch} from 'vue-property-decorator';
import {InvestmentObjectGenerated} from '@/modules/investments/shared/models/investment-object';
import GeneratorList from '@/modules/investments/components/generator/components/list-component';
import {InvestmentObjectsState} from '@/modules/investments/shared/state/state';
import {investmentObjectsModule} from '@/modules/investments/shared/state/submodules';
import { callSuper } from '@/shared/helpers';
import Hint from '@/shared/components/elements/hint.vue';

@Component({
    props: {
        items: Array,
        hasDuplications: Boolean,
        value: Object,
        objects: Array,
    },
    components: {
        ListToolbar,
        ObjectForm,
        Hint,
    },
})
export default class InvestmentGeneratorObjectsList extends GeneratorList<InvestmentObjectGenerated> {
    public store: InvestmentObjectsState = this.$store.state.investmentsState.objectsState;
    public mutationTypes = investmentObjectsModule.mutationsTypes;
    public parent?: InvestmentObjectGenerated = {} as InvestmentObjectGenerated;
    public objects!: InvestmentObjectGenerated[];
    public activeObject: InvestmentObjectGenerated[] = [];
    public openObject: InvestmentObjectGenerated[] = [];

    get currentObject() {
        return this.value;
    }

    set currentObject(value) {
        this.$emit('input', value);
    }

    public closeForm() {
        this.formDialog = false;
        this.parent = {} as InvestmentObjectGenerated;
    }

    public editItem(item: InvestmentObjectGenerated) {
        const editedObject = this.objects.find((object: InvestmentObjectGenerated) => item.id === object.id);
        if (item.parent_id) {
            this.parent = item.parent;
        }
        callSuper(this, 'editItem', editedObject);
    }

    public newItem(item?: InvestmentObjectGenerated) {
        this.editedItem = {} as InvestmentObjectGenerated;
        if (item) {
            this.editedItem = {parent_id: item.id} as InvestmentObjectGenerated;
            this.parent = item;
        } else {
            this.parent = {} as InvestmentObjectGenerated;
        }
        callSuper(this, 'newItem', );
    }

    public submitForm() {
        if (this.parent && !this.openObject.includes(this.parent)) {
            this.openObject.push(this.parent);
        }
        callSuper(this, 'submitForm', );
    }

    @Watch('activeObject')
    public activeObjectChanged(newVal: InvestmentObjectGenerated[], oldVal: InvestmentObjectGenerated[]) {
        if (newVal && newVal.length > 0 && newVal[0]) {
            this.currentObject = newVal[0];
            this.openObject.push(newVal[0]);
        } else {
            if (oldVal[0] && this.objects.find((item: InvestmentObjectGenerated) => oldVal[0].id === item.id)) {
                this.activeObject = [oldVal[0]];
            }
        }
    }

    @Watch('currentObject')
    public onCurrentChange(value: InvestmentObjectGenerated) {
        if (this.activeObject.length < 1 || (!this._.isEmpty(value) && !this._.isEqual(value, this.activeObject[0]))) {
            this.activeObject = [value];
        }
    }
}
