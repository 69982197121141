
import { Contractor } from '@/modules/contractors/shared/models/contractor';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ShowMore from './show-more.vue';

@Component({
    components: {
        ShowMore,
    },
})
export default class RelatedContractorsListItem extends Vue {
    @Prop(Object) public contractor!: Contractor;
    @Prop(Boolean) public isMobile!: boolean;
}
