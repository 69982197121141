var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),(_vm.itemData.parent_id && _vm.parent)?_c('v-card-text',{staticClass:"pt-0 d-flex align-center"},[_c('h3',{staticClass:"heading"},[_vm._v("Nadrzędna kategoria:")]),_c('v-chip',{staticClass:"ml-2",attrs:{"small":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.parent.icon))]),_vm._v(" "+_vm._s(_vm.parent.name)+" ")],1)],1):_vm._e(),_c('v-card-text',[_c('v-form',{ref:"investmentGeneratorObjectForm",attrs:{"id":"investment-generator-object-form","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitAndNext()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auto-complete-with-add-new',{attrs:{"label":"Typ","rules":[
                            _vm.formRules.required,
                            _vm.levelValidation,
                            _vm.levelStageValidation,
                            _vm.levelInceptionValidation,
                            _vm.buildingInceptionValidation,
                            _vm.buildingInHouseValidation,
                            _vm.buildingInLevelValidation,
                            _vm.houseInLevelValidation,
                            _vm.houseInBuildingValidation,
                            _vm.stageOnlyRootValidation,
                            _vm.houseInceptionValidation,
                        ],"state":_vm.objectTypesState,"formComponent":_vm.objectTypesForm,"module":_vm.investmentObjectTypesModule,"return-object":""},on:{"changed":(newValue) => {
                                _vm.changeName(newValue.name);
                            }},model:{value:(_vm.investmentObjectType),callback:function ($$v) {_vm.investmentObjectType=$$v},expression:"investmentObjectType"}})],1),(
                        (_vm.itemData.investment_object_type_id === 2 &&
                            typeof _vm.buildingInceptionValidation() !== 'string' &&
                            typeof _vm.buildingInHouseValidation() !== 'string' &&
                            typeof _vm.buildingInLevelValidation() !== 'string' &&
                            typeof _vm.houseInBuildingValidation() !== 'string') ||
                        (_vm.itemData.investment_object_type_id === 6 &&
                            typeof _vm.houseInceptionValidation() !== 'string' &&
                            typeof _vm.houseInLevelValidation() !== 'string' &&
                            typeof _vm.houseInBuildingValidation() !== 'string' &&
                            typeof _vm.buildingInHouseValidation() !== 'string')
                    )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Najniższy poziom","type":"number","rules":[_vm.formRules.required, _vm.validMinLevel, _vm.validLesserThanZeroLevel]},model:{value:(_vm.itemData.lowestLevel),callback:function ($$v) {_vm.$set(_vm.itemData, "lowestLevel", $$v)},expression:"itemData.lowestLevel"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('variable-text-field',{attrs:{"label":"Nazwa","variables":_vm.variables,"rules":[_vm.formRules.required, _vm.levelVariableValidation]},model:{value:(_vm.itemData.name),callback:function ($$v) {_vm.$set(_vm.itemData, "name", $$v)},expression:"itemData.name"}}),_c('v-divider',{staticClass:"mt-5"})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Opis","rules":[_vm.formRules.maximumText(500)],"counter":"500"},model:{value:(_vm.itemData.description),callback:function ($$v) {_vm.$set(_vm.itemData, "description", $$v)},expression:"itemData.description"}})],1),_c('form-actions',{staticClass:"mt-5 pa-5 pb-md-5",attrs:{"edit":_vm.edit,"disabled":!_vm.valid,"loading":_vm.typesLoading},on:{"canceled":function($event){return _vm.cancelForm()},"submited":function($event){return _vm.submitForm()}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }