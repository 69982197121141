
import {objectToQueryString} from '@/shared/helpers';
import draggable from 'vuedraggable';
import {httpClient, logger} from '@/shared/services';
import Vue from 'vue';
import Component from 'vue-class-component';
import {Inject, Prop, PropSync, Ref, VModel, Watch} from 'vue-property-decorator';
import {Ticket} from '../../shared/models/ticket';
import item from './item.vue';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import {MetaItem} from '@/shared/types';
import {TicketStatusWithTickets} from '@/modules/tickets/shared/models/status';
import {AxiosResponse} from 'axios';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {ticketsModule} from '@/modules/tickets/shared';
import {TicketsState} from '@/modules/tickets/shared/state/state';

@Component({
    components: {
        item,
        draggable,
        InfiniteScroll,
    },
})
export default class SubList extends Vue {
    @Prop(Object) public status!: TicketStatusWithTickets;
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    @Ref('scrollable') public scrollable!: HTMLElement;
    @Prop(Boolean) public statusLoading!: boolean;
    @Prop(String) public title!: string;
    @Prop(Number) public statusIndex!: number;
    @Prop(String) public filters!: string;
    @Prop(String) public search!: string;
    @Prop(Boolean) public searchReload!: boolean;
    public mutationsTypes = ticketsModule.mutationsTypes;

    public store: any = null;
    public meta = {} as MetaItem;
    public isLoading = false;
    public stopInfiniteScroll = false;
    public dragOptions = {
        animation: 75,
        disabled: false,
        ghostClass: 'ghost',
        chosenClass: 'chosen',
        direction: 'horizontal',
    };

    get ticketsCount() {
        return this.status.tickets_count;
    }

    get items() {
        return this.status.data;
    }

    get statusId() {
        return this.status.id;
    }

    get isAnyTicketDragged() {
        return this.store.isAnyTicketDragged;
    }

    set isAnyTicketDragged(value) {
        this.$store.commit(this.mutationsTypes.SET_ANY_DRAGGED, value);
    }

    get tableToRefresh() {
        return this.store && this.store.statusWithNewTicket === this.statusIndex;
    }

    get fetchParams() {
        return {filters: {...JSON.parse(this.filters), search: this.search}};
    }

    public mounted() {
        this.store = this.$store.state.ticketsState as TicketsState;
    }

    public fetchData() {
        this.stopInfiniteScroll = true;

        if (this.tableToRefresh) {
            this.$store.state.ticketsState.statusWithNewTicket = null;
        }

        this.scrollable.scroll(0, 0);
        this.isLoading = true;

        let url = `/api/v1/tickets/statuses/${this.statusId}/tickets?`;
        url += objectToQueryString(this.fetchParams);

        httpClient
            .get(url)
            .then((response: AxiosResponse<ModuleFetchPayload<Ticket>>) => response.data)
            .then((response) => {
                if (response.data && response.meta) {
                    this.isLoading = false;
                    if (this.status) {
                        this.status.data = response.data;
                        this.status.tickets_count = response.meta.total;
                    }
                    this.meta = response.meta;
                }
            })
            .catch((err) => {
                logger.error(err);
                this.isLoading = false;
            });
        this.stopInfiniteScroll = false;
    }

    public appendExtra({data}: any) {
        this.status.data.push(...data);
    }

    public onRemove(id: number) {
        this.$emit('removed', {
            index: this.items.map((ticket) => ticket.id).indexOf(id),
            statusIndex: this.statusIndex,
        });
    }

    public onDrag(e: any) {
        this.isAnyTicketDragged = true;
        document.body.style.cursor = 'n-resize';
    }

    public onDrop(e: any) {
        document.body.style.cursor = 'unset';
        this.isAnyTicketDragged = false;
    }

    @Watch('searchReload')
    public onSearchReload(val: boolean) {
        if (val === false) {
            this.isLoading = false;
        } else {
            this.isLoading = true;
        }
    }

    @Watch('tableToRefresh')
    public onTableToRefreshChange(val: any) {
        if (!val) {
            return;
        }
        this.fetchData();
    }
}
