
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {httpClient} from '@/shared/services';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {Snackbar} from '@/shared/types';
import {cardThemes} from '@/modules/investments/components/objects/subjects/card/themes/themes';
import {MediaFile} from '@/modules/media/shared/models/media-file';

@Component({
    components: {FormActions, MediaFileInput}
})
export default class SubjectCardDialog extends Vue {
    @Prop(Object) public subject!: InvestmentSubject;
    @Prop(Array) public objects!: InvestmentObject[];

    public loadingCard: boolean = false;
    public thumbnail: MediaFile | null = null;
    public cardThemes = cardThemes;

    get parentObjects() {
        return this.objects;
    }

    set parentObjects(value) {
        this.$emit('update:objects', value);
    }

    public generateCard() {
        this.loadingCard = true;
        httpClient
            .post(
                `api/v1/investments/subjects/${this.subject.id}/generate-card`,
                {
                    thumbnail_id: this.thumbnail ? this.thumbnail.id : null,
                },
                {
                    headers: {},
                    responseType: 'arraybuffer',
                },
            )
            .then((response) => {
                if (response.data && (response.data instanceof ArrayBuffer)) {
                    const blob = new Blob([response.data]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Karta lokalu - ${this.subject.name}.pdf`);
                    document.body.appendChild(link);
                    this.$store.commit('SHOW_SNACKBAR', {text: 'Pobrano kartę lokalu', type: 'success'} as Snackbar);
                    link.click();
                    this.loadingCard = false;
                    this.$emit('close');
                }
            });
    }
}

