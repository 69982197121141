
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { Component } from 'vue-property-decorator';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { ModuleProps } from '@/shared/state/template/module-props';
    import { investmentObjectTypesModule } from '@/modules/investments/shared/state/submodules';
    import { InvestmentObjectType } from '@/modules/investments/shared/models/objects/investment-object-type';
    import { InvestmentObjectTypeDataRequest } from '@/modules/investments/shared/requests/objects/investment-object-type-data-request';
    import { objectTypesListHeaders } from '@/modules/investments/shared/config';
    import ObjectsTypesFrom from './form.vue';
    import { ModuleState } from '@/shared/state/template/module-state';

    @Component({
        components: {
            ItemsTable,
            ObjectsTypesFrom,
        },
    })
    export default class ObjectTypeSettingsList extends ListComponent<
        InvestmentObjectType,
        InvestmentObjectTypeDataRequest
    > {
        public headers: object[] = objectTypesListHeaders;
        public actionsTypes = investmentObjectTypesModule.actionsTypes;
        public mutationTypes = investmentObjectTypesModule.mutationsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public store: ModuleState<
            InvestmentObjectType,
            InvestmentObjectTypeDataRequest
        > = this.$store.state.investmentsState.objectTypesState;
        public props: ModuleProps = investmentObjectTypesModule.moduleProps;
    }
