
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { Component } from 'vue-property-decorator';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { ModuleProps } from '@/shared/state/template/module-props';
    import { investmentSubjectRoomTypesModule } from '../../../shared/state/submodules/subjects';
    import { InvestmentSubjectRoom } from '@/modules/investments/shared/models/subjects/investment-subject-room';
    import { InvestmentSubjectRoomDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-room-data-request';
    import { roomTypesListHeaders } from '@/modules/investments/shared/config';
    import SubjectRoomTypeForm from './form.vue';

    @Component({
        components: {
            ItemsTable,
            SubjectRoomTypeForm,
        },
    })
    export default class SubjectRoomTypesList extends ListComponent<
        InvestmentSubjectRoom,
        InvestmentSubjectRoomDataRequest
    > {
        public headers = roomTypesListHeaders;
        public actionsTypes = investmentSubjectRoomTypesModule.actionsTypes;
        public mutationTypes = investmentSubjectRoomTypesModule.mutationsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public store: any = this.$store.state.investmentsState
            .subjectRoomTypesState;
        public props: ModuleProps = investmentSubjectRoomTypesModule.moduleProps;
    }
