
import { Component, Prop, Watch } from 'vue-property-decorator';
import TemplateSubjectForm from '@/modules/investments/components/generator/templates/template/template-subject-form';
import { roomTypes } from '@/modules/investments/components/generator/templates/blocks/models/types';
import {
    privateStatuses,
    publicStatuses,
    subjectNameVariables,
    subjectCodeVariables,
} from '@/shared/config/investments';
import { HeaderFieldType } from '@/shared/types';
import {
    investmentSubjectPropertiesListGeneratorHeaders,
    investmentSubjectRoomsListGeneratorHeaders,
} from '@/modules/investments/shared/config';
import { InvestmentSubjectRoomGenerated } from '@/modules/investments/shared/models/subjects/investment-subject-room';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import {
    investmentSubjectPropertyTypesModule,
    investmentSubjectTypesModule,
} from '@/modules/investments/shared/state/submodules';
import SubjectPropertyTypesForm from '@/modules/investments/components/settings/subject-properties/form.vue';
import { getIcon } from '@/modules/media/shared/helpers';
import SelectMediaList from '@/modules/media/components/select.vue';
import { MediaFileSelect } from '@/modules/media/shared/models/media-file-select';
import { InvestmentSubjectMediaFileGenerated } from '@/modules/investments/shared/models/subjects/investment-subject-media-file';
import { InvestmentSubjectRoomType } from '@/modules/investments/shared/models/subjects/rooms/investment-room-type';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import SubjectTypesForm from '../../../settings/subject/form.vue';
import { InvestmentSubjectType } from '@/modules/investments/shared/models/subjects/investment-subject-type';
import { httpClient } from '@/shared/services';
import { AxiosError, AxiosResponse } from 'axios';
import { mdiBarcode } from '@mdi/js';
import { subjectCodeRules } from '@/modules/investments/shared/helpers/subjects';

@Component({
    components: {
        VariableTextField,
        AutoCompleteWithAddNew,
        SelectMediaList,
    },
    props: {
        itemData: Object,
        storey: Number,
        canBack: {
            type: Boolean,
            default: true,
        },
    },
})
export default class BlockInvestmentSubjectForm extends TemplateSubjectForm {
    @Prop(Number) public storey!: number;
    public roomTypes = roomTypes;
    public getIcon = getIcon;
    public subjectNameVariables = subjectNameVariables;
    public subjectCodeVariables = [
        ...subjectCodeVariables,
        {
            icon: mdiBarcode,
            text: 'Automatyczny',
            value: 'automatyczny',
            overwrite: true,
        },
    ];
    public privateStatuses = privateStatuses;
    public mediaSelectDialog: boolean = false;
    public propertyTypesState = this.$store.state.investmentsState.subjectPropertyTypesState;
    public propertyForm = SubjectPropertyTypesForm;
    public propertyTypeModule = investmentSubjectPropertyTypesModule;
    public publicStatuses = publicStatuses;
    public roomsHeaders: HeaderFieldType[] = investmentSubjectRoomsListGeneratorHeaders;
    public propertiesHeaders: HeaderFieldType[] = investmentSubjectPropertiesListGeneratorHeaders;
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';

    public subjectTypesForm = SubjectTypesForm;
    public investmentSubjectTypesModule = investmentSubjectTypesModule;
    public subjectTypesState = this.$store.state.investmentsState.subjectTypesState;

    public codeErrorMessages: string[] = [];
    public subjectCodeRules = subjectCodeRules;

    public changeName(val: InvestmentSubjectType) {
        if (!val) {
            return;
        }

        if (this.itemData && !this.itemData.name) {
            this.$set(this.itemData, 'name', val.name + ' ' + '{{numer_przedmiotu}}');
        }
    }

    public addMedia(items: MediaFileSelect[]) {
        this.mediaSelectDialog = false;
        this.itemData.media = items.map(
            // tslint:disable-next-line:variable-name
            (media_file: MediaFileSelect) => {
                return { media_file_id: media_file.id, media_file };
            },
        ) as InvestmentSubjectMediaFileGenerated[];
    }

    public changeRoomNameByType(item: InvestmentSubjectRoomGenerated, id: number) {
        const room = { ...item };
        if (!room.name || this.roomTypes.map(({ name }: InvestmentSubjectRoomType) => name).includes(room.name)) {
            const roomType = this.roomTypes.find((type) => type.id === id);
            room.name = roomType ? roomType.name : '';
            if (this.itemData.rooms) {
                this.itemData.rooms.splice(this.itemData.rooms.indexOf(item), 1, room);
            }
        }
    }

    public uniqueCode(v: string) {
        if (v) {
            httpClient.post('api/v1/investments/subjects/validate-code', { code: v }).catch((err: any) => {
                return this.codeErrorMessages.push(err.message);
            });
        }
    }

    @Watch('itemData.code', { deep: true })
    public onCodeChange(val: string) {
        this.codeErrorMessages = [];
        this.uniqueCode(val);
    }
}
