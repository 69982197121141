

import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class FormActions extends Vue {
    @Prop(Boolean) public valid!: boolean;
    @Prop(Boolean) public edit!: boolean;
}

