

import {Component, Vue, Watch} from 'vue-property-decorator';
import SelectMediaList from '@/modules/media/components/select.vue';
import mime from 'mime';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import {bytesToSize, getIcon} from '@/modules/media/shared/helpers';
import {MediaFileSelect} from '@/modules/media/shared/models/media-file-select';

@Component({
    components: {
        SelectMediaList,
    },
    props: {
        value: Number,
        page: Number,
        items: Array,
    },
})
export default class SubjectMediaCarousel extends Vue {
    public current: number = 0;
    public items!: MediaFileSelect[];
    public value!: number;
    public getIcon = getIcon;
    public bytesToSize = bytesToSize;
    public player: any;
    public page!: number;

    public created() {
        this.current = this.value;
    }

    public closeDialog() {
        this.stopVideo();
        this.$emit('closed');
    }

    public playerOptions(item: MediaFileSelect) {
        return {
            sources: [{
                type: mime.getType(item.extension),
                src: item.video_url,
            }],
            height: '500px',
            fill: true,
            aspectRatio: '16:9',
            language: 'pl',
            poster: item.has_thumbnail ? item.thumbnail : '',
        };
    }

    public stopVideo() {
        if (this.page !== this.items[this.current].page) {
            this.$emit('change-page', this.items[this.current].page);
        }
        this.$emit('input', this.current);
        if (this.player && !this.player.paused()) {
            this.player.pause();
            this.player = null;
        }
    }

    @Watch('value')
    public onValueChange() {
        this.current = this.value;
    }
}

