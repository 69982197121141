import {Component, Prop, Vue} from 'vue-property-decorator';
import {ProspectDataRequest} from '@/modules/investments/shared/requests/prospect-data-request';
import {InvestmentSubjectsState} from '@/modules/investments/shared/state/state';
import {FormRules} from '@/shared/validation/form-rules';
import {ModuleState} from '@/shared/state/template/module-state';
import {Investment} from '@/modules/investments/shared/models/investment';
import {InvestmentDataRequest} from '@/modules/investments/shared/requests/investment-data-request';
import {ProspectSetting} from '@/modules/investments/shared/models/prospect-setting';

@Component
export default class ProspectStepComponent extends Vue {
    @Prop(Object) public itemData!: ProspectDataRequest;
    @Prop(Number) public currentStep!: number;
    @Prop(Number) public step!: number;

    public investmentsState = this.$store.state.investmentsState;

    public settingExistsDialog: boolean = false;
    public settingExistsKey: string = '';

    public formRules: FormRules = new FormRules();

    get prospectSettings() {
        return this.investmentsState.prospectSettingsState.dataList;
    }

    get formData() {
        return this.itemData;
    }

    set formData(value) {
        this.$emit('update:itemData', value);
    }

    public checkIfSettingExists(key: string, id: number, value: boolean) {
        if (value) {
            this.settingExistsDialog = !!this.prospectSettings.find(
                (setting: ProspectSetting) => setting.key === key && setting.assignable_id === id
            );
            if (this.settingExistsDialog) {
                this.settingExistsKey = key;
            }
        }
    }

    public closeSettingExistsDialog() {
        if (this.formData && this.formData[this.settingExistsKey]) {
            this.formData[this.settingExistsKey].remember = false;
            this.formData[this.settingExistsKey].assignable_id = null;
        }
        this.settingExistsDialog = false;
    }
}
