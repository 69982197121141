
    import { Component } from 'vue-property-decorator';
    import FormComponent from '@/shared/components/layout/form/form-component';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';

    // state module
    import { investmentSubjectTypesModule } from '../../../shared/state/submodules/subjects';

    import { InvestmentSubjectTypeDataRequest } from '../../../shared/requests/subjects/investment-subject-type-data-request';
    import { subjectPurposes, subjectEstateTypes } from '@/shared/config/investments';
    import { callSuper } from '@/shared/helpers';

    @Component({
        components: { FormActions },
    })
    export default class SubjectTypesForm extends FormComponent<InvestmentSubjectTypeDataRequest> {
        public actionsTypes = investmentSubjectTypesModule.actionsTypes;
        public store = this.$store.state.investmentsState.subjectTypesState;
        public storeAction = this.actionsTypes.STORE_ITEM;
        public updateAction = this.actionsTypes.UPDATE_ITEM;
        public requestData = new InvestmentSubjectTypeDataRequest();
        public subjectPurposes = subjectPurposes;
        public subjectEstateTypes = subjectEstateTypes;

        public created() {
            //
        }

        public estateTypeChanged() {
            this.clearError('estate_type');
            this.clearError('subject_purpose');
            this.$delete(this.itemData, 'subject_purpose');
        }

        get formTitle(): string {
            return this.edit ? 'Edytuj typ przedmiotu inwestycji' : 'Dodaj typ przedmiotu inwestycji';
        }

        public initForm() {
            this.form = this.$refs.createDepartmentForm;
        }
    }
