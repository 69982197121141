
import FormComponent from '@/shared/components/layout/form/form-component';
import {InvestmentObjectDataRequest} from '@/modules/investments/shared/requests/investment-object-data-request';
import {Component, Prop, VModel} from 'vue-property-decorator';
import {InvestmentObjectsState} from '@/modules/investments/shared/state/state';
import {investmentObjectMediaFilesModule, investmentObjectsModule} from '@/modules/investments/shared/state/submodules';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';
import ObjectMediaFileInput from '@/modules/investments/components/objects/helpers/object-media-file-input.vue';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        ObjectMediaFileInput,
        FormActions,
        MediaFileInput,
    }
})
export default class ThumbnailForm extends FormComponent<InvestmentObjectDataRequest> {
    @VModel({type: Object}) public thumbnail!: MediaFile | null;
    @Prop(Object) public subject!: InvestmentSubject;
    @Prop(Array) public objects!: InvestmentObject[];

    public store: InvestmentObjectsState = this.$store.state.investmentsState.objectsState;

    public requestData: InvestmentObjectDataRequest = new InvestmentObjectDataRequest();
    public objectMediaFileMutations = investmentObjectMediaFilesModule.mutationsTypes;
    public actionsTypes = investmentObjectsModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public rememberForObject = false;

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.objectsForm;
         }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        if (this.rememberForObject) {
            if (this.itemData.thumbnail) {
                this.$set(this.itemData, 'thumbnail_id', this.itemData.thumbnail.id);
            }
            return callSuper(this, 'submitForm', e, next);
        } else {
            this.thumbnail = this.itemData.thumbnail || null;
            return this.cancelForm();
        }
    }

    get formTitle() {
        return '';
    }
}
