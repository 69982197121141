
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import {investmentSubjectsModule, investmentSubjectTypesModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubjectDataRequest} from '@/modules/investments/shared/requests/investment-subject-data-request';
import {
  privateStatuses,
  publicStatuses,
  subjectCodeVariables,
  subjectNameVariables,
} from '@/shared/config/investments';
import {ModuleState} from '@/shared/state/template/module-state';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import SubjectForm from '../../settings/subject/form.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';

import {catalogsModule} from '@/modules/changes/shared/state/submodules';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import {Land, landModule} from '@/modules/lands/shared';
import {callSuper} from '@/shared/helpers';

import {actionsTypes as adminActionsTypes} from '@/modules/admin/shared/state/types';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import {subjectCodeRules} from '@/modules/investments/shared/helpers/subjects';

@Component({
  components: {
    Wysiwyg,
    ActionConfirmDialog,
    FormActions,
    IconChooser,
    AutoCompleteWithAddNew,
    DatePicker,
    VariableTextField,
  },
})
export default class InvestmentSubjectForm extends FormComponent<InvestmentSubjectDataRequest> {
  public store: ModuleState<InvestmentSubject, InvestmentSubjectDataRequest> =
      this.$store.state.investmentsState.subjectsState;
  public investmentSubjectTypesModule = investmentSubjectTypesModule;
  public actionsTypes = investmentSubjectsModule.actionsTypes;
  public typesActions = investmentSubjectTypesModule.actionsTypes;
  public catalogsTypes = catalogsModule.actionsTypes;
  public storeAction = this.actionsTypes.STORE_ITEM;
  public updateAction = this.actionsTypes.UPDATE_ITEM;

  public subjectCodeVariables = subjectCodeVariables;
  public subjectNameVariables = subjectNameVariables;
  public preventDialog: boolean = false;
  public requestData = new InvestmentSubjectDataRequest();
  public publicStatuses = publicStatuses;
  public privateStatuses = privateStatuses;
  public searchTypesTimer: any = null;
  public subjectCodeRules = subjectCodeRules;

  // data required for add new type
  public subjectTypesState = this.$store.state.investmentsState.subjectTypesState;
  public investmentSubjectTypesFetchAction = investmentSubjectTypesModule.actionsTypes.FETCH_DATA;

  public SubjectForm = SubjectForm;

  public searchLand = '';
  public landSearch: any = null;
  public landActions = landModule.actionsTypes;
  public currentTab: number = 0;

  public users: any = [];
  public assignedUserLoading: boolean = false;

  public cancelForm() {
    this.preventDialog = false;
    callSuper(this, 'cancelForm');
  }

  get landsLoading() {
    return this.$store.state.landsState.loading;
  }

  get lands() {
    return this.$store.state.landsState.data;
  }

  public landChanged() {
    this.clearError('land_id');
    if (this.itemData.land_id) {
      this.itemData.land = this.lands.find((e: Land) => e.id === this.itemData.land_id);
      this.searchLand = this.itemData.land.name;
    } else {
      this.$store.dispatch(this.landActions.FETCH_DATA, {simple: false});
    }
  }

  public searchLands(search: string) {
    clearTimeout(this.landSearch);

    this.landSearch = setTimeout(() => {
      if (!this._.isEqual(this.searchLand, search)) {
        this.searchLand = search;
        this.$store.dispatch(this.landActions.FETCH_DATA, {filters: {search}});
      }
    }, 500);
  }

  get subjectTypes() {
    return this.subjectTypesState.data;
  }

  get subjectTypesLoading() {
    return this.subjectTypesState.loading;
  }

  get exampleCode() {
    if (!this.show) {
      return '';
    }

    const itemData: any = {...this.itemData};

    const investmentName = this.$store.state.investmentsState.current
        ? this.$store.state.investmentsState.current.name.toLowerCase()
        : itemData.investment.name;
    const objectName = this.object ? this.object.name.toLowerCase() : itemData.object.name;

    return `np. ${investmentName.substring(0, 3)}/${objectName.substring(0, 3)}${
        objectName.match(/\d/) || ''
    }/${this.store.data.length + 1}`;
  }

  get object() {
    return this.$store.state.investmentsState.objectsState.current;
  }

  get catalogs() {
    return this.$store.state.changesState.catalogsState.dataList;
  }

  get formTitle() {
    return this.edit ? 'Edytuj przedmiot inwestycji' : 'Dodaj przedmiot inwestycji';
  }

  get currentUser() {
    return this.$store.state.authState.user;
  }

  public pullToArraysHeadCurrentUser(arrayUsers: [any]) {
    const currentArray = [...arrayUsers];
    const {id, name} = this.currentUser;
    const currentUser = {
      ...this.currentUser,
      name: `(Ja) ${name}`,
    };
    const arrayWithoutCurrentUser = this._.filter(currentArray, this._.negate(this._.matches({id})));
    return [currentUser, ...arrayWithoutCurrentUser];
  }

  public async submitForm(e: any, next?: boolean): Promise<void> {
        if (this.itemData.code !== null && this.itemData.code.length === 0) {
            this.itemData.code = null;
        }

        return callSuper(this, 'submitForm', e, next);
    }

  public created() {
    this.assignedUserLoading = true;
    this.$store.dispatch(this.typesActions.FETCH_DATA, {
      simple: false,
    });

    this.$store.dispatch(this.landActions.FETCH_DATA, {simple: false});
    this.$store.dispatch(this.catalogsTypes.FETCH_DATA, {
      simple: true,
    });

    this.$store
        .dispatch(adminActionsTypes.FETCH_USER_DATA)
        .then((res) => {
          this.users = this.pullToArraysHeadCurrentUser(res.data);
        })
        .finally(() => {
          this.assignedUserLoading = false;
        });
  }

  public initForm() {
    this.initFocus();
    this.form = this.$refs.createInvestmentSubjectForm;
  }

  public checkName(name: any) {
    if (name !== null) {
      this.changeName(name);
    } else {
      name = '';
      this.changeName(name);
    }
  }

  public changeName(value: any) {
    if (this.itemData && !this.itemData.name) {
      this.itemData.name = value;
    }
  }

  public initFocus() {
    if (this.edit && this.itemData.land) {
      this.searchLand = this.itemData.land.name;
    }
  }
}
