

import {Component, Vue, Watch} from 'vue-property-decorator';
import {investmentSubjectOperationsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {mediaActions, privateStatuses, propertyActions, publicStatuses} from '@/shared/config/investments';
import {knownFieldNames} from '@/shared/config/investments';
import {InvestmentSubjectPropertyOperation} from '@/modules/investments/shared/models/subjects/investment-subject-property-operation';
import {simplifyData} from '@/modules/investments/shared/helpers/subjects';
import {parsePrice} from '@/shared/helpers';

@Component
export default class EditSubjectsSummary extends Vue {
    public store = this.$store.state.investmentsState.subjectOperationsState;
    public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
    public mutationTypes = investmentSubjectOperationsModule.mutationsTypes;
    public parsePrice = parsePrice;
    public hiddenProps = [
        'description_replace',
    ];
    public fields = knownFieldNames;

    public created() {
        if (!this.editedItem.selection || this.editedItem.selection.length < 1) {
            this.$router.push({name: 'subjects-list'});
        }
    }

    get editedItem() {
        return this.store.editedItem;
    }

    set editedItem(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, value);
    }

    public propertyAction(name: string) {
        return propertyActions.find((item) => item.action === name);
    }

    public mediaAction(name: string) {
        return mediaActions.find((item) => item.action === name);
    }

    public publicStatus(name: string) {
        return publicStatuses.find((item) => item.value === name);
    }

    get knownFieldNames() {
        return Object.keys(this.editedItem.edit).filter((e) => this.fields.hasOwnProperty(e));
    }

    public privateStatus(name: string) {
        return privateStatuses.find((item) => item.value === name);
    }

    get loading() {
        return this.store.loadingItem;
    }

    get progress() {
        return this.store.progress;
    }

    public submitOperation() {
        const item = simplifyData(this.editedItem);

        this.editedItem = {} as InvestmentSubjectPropertyOperation;

        this.$store.dispatch(this.actionsTypes.EDIT_SUBJECTS, item);
    }

    @Watch('editedItem.selection')
    public onSelectionChange(val: InvestmentSubject[]) {
        if (val.length < 1) {
            this.$router.push({name: 'subjects-list'});
        }
    }
}

