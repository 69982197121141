
import {Component, Prop, Watch} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import InvestmentData from './investment-data.vue';
import EntryDataForm from './experience-data.vue';
import RecognitionData from './recognition-data.vue';
import IndividualData from './individual-data.vue';
import ExperienceData from './experience-data.vue';
import SubFormActions from './sub-form-actions.vue';
import {ProspectDataRequest} from '@/modules/investments/shared/requests/prospect-data-request';
import {investmentSubjectsModule} from '@/modules/investments/shared/state/submodules';
import {httpClient} from '@/shared/services';
// @ts-ignore
import pdf from 'vue-pdf-app';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        SubFormActions,
        InvestmentData,
        EntryDataForm,
        ExperienceData,
        pdf,
        RecognitionData,
        IndividualData
    },
})
export default class ProspectForm extends FormComponent<ProspectDataRequest> {
    @Prop(String) public subjectId!: string;
    public actionsTypes = investmentSubjectsModule.actionsTypes;

    public store = this.$store.state.prospectsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new ProspectDataRequest();
    public createdProspectId!: number;
    public pdfData: any = null;

    public currentStep: number = 1;
    public badRequest: boolean = false;
    public isDone: boolean = false;
    public loadedSteps: number[] = [1];

    get formData() {
        return this.itemData;
    }

    set formData(value) {
        this.$emit('update:itemData', value);
    }

    public goBack() {
        if (this.currentStep === 1) {
            this.$emit('canceled');
        } else {
            this.currentStep--;
        }
    }

    get formTitle(): string {
        return '';
    }

    public created() {
        //
    }

    public getFormComponent(step: number) {
        switch (step) {
            case 1:
                return 'RecognitionData';
            case 2:
                return 'ExperienceData';
            case 3:
                return 'InvestmentData';
            case 4:
                return 'IndividualData';
        }
    }

    public initForm() {
        this.form = this.$refs.createProspectForm;
        this.initFocus();
         }

    public download() {
        const blob = new Blob([this.pdfData]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Prospekt informacyjny.pdf');
        document.body.appendChild(link);
        link.click();
    }

    public itemDataChanged() {
        const itemData = JSON.stringify(this.itemData);
        localStorage.setItem(`prospectForm_${this.subjectId}`, itemData);
    }

    @Watch('formData', {deep: true})
    public onItemDataChange(value: ProspectDataRequest) {
        this.itemDataChanged();
    }

    @Watch('currentStep')
    public onCurrentStepChange(val: number) {
        if (!this.loadedSteps.includes(val)) {
            this.loadedSteps.push(val);
        }

        if (val === 5) {
            this.submitForm(null, null);
        } else {
            localStorage.setItem(`prospectFormStep_${this.subjectId}`, String(val));
        }
    }

    public async submitForm(e: any, next: any) {
        httpClient
            .post(
                '/api/v1/investments/generate-prospect',
                this.requestParams,
                {
                    headers: {},
                    responseType: 'arraybuffer',
                    method: 'POST',
                },
            )
            .then((response) => {
                if (response.data && (response.data instanceof ArrayBuffer)) {
                    this.pdfData = response.data;
                }
            })
            .catch((err: any) => {
                console.error(err);
                this.badRequest = !!err;
            })
            .finally(() => {
                this.isDone = true;
                this.$forceUpdate();
            });
    }

    public initFocus() {
        this.badRequest = false;
        this.isDone = false;
        this.loadedSteps = [1];
        const savedStep = localStorage.getItem(`prospectFormStep_${this.subjectId}`);
        this.currentStep = savedStep ? Number(savedStep) : 1;
        this.requestData = new ProspectDataRequest();
        this.itemDataChanged();
    }
}
