
import {Component, Prop} from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import {FormRules} from '@/shared/validation/form-rules';
import Vue from 'vue';
import TitleCard from './title-card.vue';
import {ProspectDataRequest} from '@/modules/investments/shared/requests/prospect-data-request';
import {parseDate} from '@/shared/helpers';

@Component({
    components: {
        DatePicker,
        FormActions,
        TitleCard,
    },
})
export default class ProspectFormExperienceData extends Vue {
    public parseDate = parseDate;

    @Prop(Object) public itemData!: ProspectDataRequest;
    @Prop(Number) public step!: number;
    public formRules: FormRules = new FormRules();

    get formData() {
        return this.itemData;
    }

    set formData(value) {
        this.$emit('update:itemData', value);
    }
}
