
import {Component, Watch} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import {prioritiesModule, TicketPriorityDataRequest} from '@/modules/tickets/shared';
import {httpClient} from '@/shared/services';

import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';

@Component({
  components: {FormActions, IconChooser, TextContrast, IconContrast},
})
export default class PriorityForm extends FormComponent<TicketPriorityDataRequest> {
  public actionsTypes = prioritiesModule.actionsTypes;
  public store: any = this.$store.state.ticketsState.prioritiesState;
  public storeAction = this.actionsTypes.STORE_ITEM;
  public updateAction = this.actionsTypes.UPDATE_ITEM;
  public requestData = new TicketPriorityDataRequest();
  public icons = [];
  public iconsLoading = false;

  public swatches: Array<[string, string]> = [
    ['#753BBD', '#147BD1'],
    ['#2DC84D', '#FF7F41'],
    ['#F7EA48', '#ea97ad'],
    ['#4A6695', '#E03C31'],
    ['#B7CC33', '#FF00A5'],
  ];

  public created() {
    //
    this.iconsLoading = true;
    httpClient
        .get('api/v1/tickets/priorities/icons/list')
        .then((res) => {
          this.icons = res.data.data;
        })
        .finally(() => {
          this.iconsLoading = false;
        });
  }

  get currentIcon() {
    const item: any = this.itemData;
    if (!item) {
      return null;
    }
    if (Number(item.ticket_priority_icon_id)) {
      return this.icons.find((icon: any) => icon.id === item.ticket_priority_icon_id);
    }
    if (item.icon && item.icon.id) {
      return this.icons.find((icon: any) => icon.id === item.icon.id);
    }
    return null;
  }

  get formTitle(): string {
    return this.edit ? 'Edytuj priorytet' : 'Dodaj priorytet';
  }

  public initForm() {
    this.form = this.$refs.createDepartmentForm;
  }

  @Watch('itemData.color')
  public ifColorObject() {
    if (!(typeof this.itemData.color === 'string')) {
      this.itemData.color = '#147BD1';
    }
  }
}
