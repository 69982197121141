
import Vue from 'vue';
import {
    statusesModule,
    ticketsModule,
    TicketStatus,
    TicketStatusDataRequest
} from '@/modules/tickets/shared';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import SubList from './sub-list.vue';
import {ModuleState} from '@/shared/state/template/module-state';
import {TicketStatusWithTickets} from '@/modules/tickets/shared/models/status';

@Component({
    components: {
        SubList,
    },
})
export default class List extends Vue {
    @Prop(Array) public statuses!: TicketStatusWithTickets[];
    @Prop(Array) public statusesIndex!: any;
    @Prop(String) public filters!: string;
    @Prop(String) public searchTickets!: string;
    @Prop(Boolean) public isLoading!: boolean;
    @Prop(Boolean) public searchReload!: boolean;
    public actionsTypes = statusesModule.actionsTypes;
    public mutationTypes = statusesModule.mutationsTypes;
    public store: ModuleState<TicketStatus, TicketStatusDataRequest> = this.$store.state.ticketsState.statusesState;

    public ticketsState = this.$store.state.ticketsState;

    public search = '';
    public searchTimer: number = 0;

    public ticketRemoved({index, statusIndex}: {index: number, statusIndex: number}) {
        this.statuses[statusIndex].tickets_count--;
        this.$delete(this.statuses[statusIndex].data, index);
    }

    @Watch('searchTickets')
    public onSearchChange(val: string) {
        clearTimeout(this.searchTimer);
        this.searchTimer = window.setTimeout(() => {
            this.search = val;
        }, 900);
    }
}
