
import ListComponent from '@/shared/components/layout/list/list-component';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {InvestmentSubjectDataRequest} from '@/modules/investments/shared/requests/investment-subject-data-request';
import {Component, Watch} from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import {investmentSubjectsListHeaders} from '@/modules/investments/shared/config';
import {investmentSubjectsModule} from '@/modules/investments/shared/state/submodules';
import InvestmentSubjectForm from '@/modules/investments/components/objects/subjects/form.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';

@Component({
    props: {
        objectId: Number,
        customCreateItem: Boolean,
    },
    components: {
        ItemsTable,
        InvestmentSubjectForm,
        ActionConfirmDialog,
    }
})
export default class InvestmentSubjectsList extends ListComponent<InvestmentSubject, InvestmentSubjectDataRequest> {
    public headers: object[] = investmentSubjectsListHeaders;
    public actionsTypes = investmentSubjectsModule.actionsTypes;
    public mutationTypes = investmentSubjectsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.investmentsState.subjectsState;
    public props: ModuleProps = investmentSubjectsModule.moduleProps;
    public objectId!: number;

    public created() {
        this.id = this.objectId;
        this.fetchData();
    }

    public itemSaved() {
        this.formDialog = false;
    }

    public emit() {
        this.$emit('save');
    }

    @Watch('objectId')
    public changeObjectId(newVal: number) {
        this.id = newVal;
        if (this.page !== 1) {
            this.page = 1;
        } else {
            this.fetchData();
        }
    }
}
