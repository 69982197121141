import {Component, Prop, Vue} from 'vue-property-decorator';
import {FormRules} from '@/shared/validation/form-rules';
import {InvestmentObjectGenerated} from '@/modules/investments/shared/models/investment-object';
import TemplateForm from '@/modules/investments/components/generator/templates/template/template-form';

@Component
export default class TemplateObjectForm<T extends InvestmentObjectGenerated> extends TemplateForm {
    @Prop(Object) public itemData!: T;
    @Prop(Boolean) public edit!: boolean;
}
