
import ListComponent from '@/shared/components/layout/list/list-component';
import {
    TicketStatus,
    TicketStatusDataRequest,
    statusesModule,
    TicketDataRequest,
    Ticket, ticketsModule
} from '@/modules/tickets/shared';
import Component from 'vue-class-component';
import kanbanTable from './table.vue';
import statusesForm from '../settings/status/form.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import {Prop, VModel, Watch} from 'vue-property-decorator';
import {TicketStatusWithTickets} from '@/modules/tickets/shared/models/status';

interface StatusesUpdatedEvent {
    oldStatusId: number;
    newStatusId: number;
    itemIndex: number;
    selectedItem: Ticket;
}

@Component({
    components: {
        kanbanTable,
        statusesForm,
    },
})
export default class Kanban extends ListComponent<TicketStatus, TicketStatusDataRequest> {
    @VModel({type: Array}) public statuses!: TicketStatusWithTickets[];
    @Prop(Array) public statusesIndex!: number[];
    @Prop(String) public filters!: string;
    @Prop(String) public searchTickets!: string;
    @Prop(Boolean) public isLoading!: boolean;
    @Prop(Boolean) public searchReload!: boolean;
    public headers: object[] = [];
    public actionsTypes = statusesModule.actionsTypes;
    public mutationTypes = statusesModule.mutationsTypes;
    public ticketsMutations = ticketsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.ticketsState.statusesState;
    public props: ModuleProps = statusesModule.moduleProps;
    public searchTimer: any = null;
    public searchTicketsCopy: string = '';

    public ticketsState = this.$store.state.ticketsState;
    public headerMouseOver = false;
    public showAddStatus = true;

    get ticketsFormDialog() {
        return this.ticketsState.formDialog;
    }

    set ticketsFormDialog(value: boolean) {
        this.$store.commit(this.ticketsMutations.UPDATE_FORM_DIALOG, value);
    }

    get editedTicket() {
        return this.ticketsState.editedItem;
    }

    set editedTicket(value: TicketDataRequest) {
        this.$store.commit(this.ticketsMutations.UPDATE_EDITED, value);
    }

    public onTableLoading(isTableLoaded: boolean) {
        if (isTableLoaded) {
            this.showAddStatus = true;
        }
    }

    public createTicket(id: number) {
        this.editedTicket = {
            ticket_status_id: id,
            ticket_status: this.items.find((item: any) => item.id === id),
        } as TicketDataRequest;
        this.ticketsFormDialog = true;
    }

    public createStatus() {
        this.createItem();
        this.formDialog = true;
    }

    public updateStatuses(e: StatusesUpdatedEvent) {
        this.statuses.forEach((status, index) => {
            if (status.id === e.oldStatusId) {
                status.data.splice(e.itemIndex, 1);
                status.tickets_count--;
                this.$set(this.statuses, index, status);
            }
            if (status.id === e.newStatusId) {
                status.data.push(e.selectedItem);
                status.tickets_count++;
                this.$set(this.statuses, index, status);
            }
        });
    }

    @Watch('searchTickets')
    public onSearchChange(val: string) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.searchTicketsCopy = val;
        }, 900);
    }
}
