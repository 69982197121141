
import {Component, Watch} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {investmentTagsModule} from '../../../shared/state/submodules';
import {InvestmentTagDataRequest} from '../../../shared/requests/investment-tag-data-request';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        InvestmentTagChip,
        FormActions,
    },
})
export default class TagsForm extends FormComponent<InvestmentTagDataRequest> {
    public actionsTypes = investmentTagsModule.actionsTypes;
    public store = this.$store.state.investmentsState.investmentTagsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new InvestmentTagDataRequest();
    public swatches: Array<[string, string]> = [
        ['#753BBD', '#147BD1'],
        ['#2DC84D', '#FF7F41'],
        ['#F7EA48', '#ea97ad'],
        ['#4A6695', '#E03C31'],
        ['#B7CC33', '#FF00A5'],
    ];
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj tag' : 'Dodaj tag';
    }

    public initForm() {
        this.form = this.$refs.createInvestmentTagForm;
    }

    @Watch('itemData.color')
    public ifColorObject() {
        if (!(typeof this.itemData.color === 'string')) { this.itemData.color = '#147BD1'; }
    }
}
