import {Land} from '@/modules/lands/shared';

export class LandDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public plot_address: string = '';
    // tslint:disable-next-line:variable-name
    public plot_number: number = 0;
    // tslint:disable-next-line:variable-name
    public land_register_number: number = 0;
    // tslint:disable-next-line:variable-name
    public plot_area: number = 0;
    // tslint:disable-next-line:variable-name
    public law_status: string = '';
    // tslint:disable-next-line:variable-name
    public plan_purpose: string|null = '';
    // tslint:disable-next-line:variable-name
    public acceptable_building_height: number|null = 0;
    // tslint:disable-next-line:variable-name
    public acceptable_building_percentage: number|null = 0;
    // tslint:disable-next-line:variable-name
    public planned_investments: string = '';
    // tslint:disable-next-line:variable-name
    public evidence_address: string = '';
    // tslint:disable-next-line:variable-name
    public evidence_name: string = '';
    // tslint:disable-next-line:variable-name
    public land_register_name: string = '';
    // tslint:disable-next-line:variable-name
    public land_register_address: string = '';
    // tslint:disable-next-line:variable-name
    public assignable_type: string = '';
    // tslint:disable-next-line:variable-name
    public assignable_id: number = 0;
    public assignable!: any;
    public parent?: Land;
    // tslint:disable-next-line:variable-name
    public has_no_plan: boolean = false;
    // tslint:disable-next-line:variable-name
    public parent_id: number = 0;
    public name: string = '';
    public status: string = '';
    // tslint:disable-next-line:variable-name
    public investment_id: number = 0;
}

