
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { InvestmentSubjectRoomDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-room-data-request';
import {
    investmentSubjectRoomsModule,
    investmentSubjectRoomTypesModule,
} from '@/modules/investments/shared/state/submodules';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import RoomsForm from '../../../settings/rooms/form.vue';
import {InvestmentSubjectRoomType} from '@/modules/investments/shared/models/subjects/rooms/investment-room-type';
import StoreyPlan from '@/modules/investments/components/plans/plan-select.vue';
import {investmentSubjectPlansModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubjectPlan} from '@/modules/investments/shared/models/investment-subject-plan';
import {InvestmentSubjectRoomsState} from '@/modules/investments/shared/state/state/rooms';
import {
    InvestmentSubjectPlanDataRequest
} from '@/modules/investments/shared/requests/investment-subject-plan-data-request';
import {ModuleState} from '@/shared/state/template/module-state';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';

@Component({
    components: {
        ActionConfirmDialog,
        FormActions,
        AutoCompleteWithAddNew,
        StoreyPlan,
    },
    props: {
        itemData: Object,
        edit: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
        next: {
            type: Boolean,
            default: true,
        },
    },
})
export default class InvestmentSubjectRoomForm extends FormComponent<InvestmentSubjectRoomDataRequest> {
    public store: InvestmentSubjectRoomsState = this.$store.state.investmentsState.subjectRoomsState;
    public planState: ModuleState<InvestmentSubjectPlan,
        InvestmentSubjectPlanDataRequest> = this.$store.state.investmentsState.subjectPlansState;
    public mutationsTypes = investmentSubjectRoomsModule.mutationsTypes;
    public actionsTypes = investmentSubjectRoomsModule.actionsTypes;
    public typesActions = investmentSubjectRoomTypesModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new InvestmentSubjectRoomDataRequest();
    public subjectRoomTypesState = this.$store.state.investmentsState
        .subjectRoomTypesState;
    public investmentSubjectRoomTypesModule = investmentSubjectRoomTypesModule;
    public planMutationTypes = investmentSubjectPlansModule.mutationsTypes;
    public RoomsForm = RoomsForm;
    public noPlanDialog: boolean = false;

    get formTitle() {
        return this.edit ? 'Edytuj pomieszczenie przedmiotu' : 'Dodaj pomieszczenie przedmiotu';
    }

    get roomTypes() {
        return this.$store.state.investmentsState.subjectRoomTypesState.data;
    }

    get typesLoading() {
        return this.$store.state.investmentsState.subjectRoomTypesState.loading;
    }

    public roomCodeExists(code: number) {
        const rooms = this.store.dataList as InvestmentSubjectRoom[];
        return !rooms.find((room) => room.code === Number(code) && this.itemData.id !== room.id) || 'Istnieje pomieszczenie z takim numerem';
    }

    public initForm() {
        this.form = this.$refs.createInvestmentSubjectRoomForm;
         }

    public changeName(type: InvestmentSubjectRoomType) {
        if (this.store.editedItem && !this.store.editedItem.name) {
            this.store.editedItem.name = type ? type.name : '';
        }
    }

    public closeNoPlanDialog() {
        this.noPlanDialog = false;
        this.planState.formDialog = true;
    }

    public openSelectPoint() {
        const storeyPlan = this.plans.find(
            ({storey}: InvestmentSubjectPlan) => storey === Number(this.itemData.storey)
        );
        if (storeyPlan) {
            this.currentPlan = storeyPlan;
            if (!this.itemData.x) {
                this.$set(this.itemData, 'x', 0);
            }
            if (!this.itemData.y) {
                this.$set(this.itemData, 'y', 0);
            }
            this.$emit('select-point');
            this.pointSelecting = true;
            this.planState.showDialog = true;
        } else {
            this.planState.editedItem = Object.assign({}, {
                storey: this.itemData.storey,
                investment_subject_id: this.itemData.investment_subject_id,
                media_file_id: 0,
            }) as InvestmentSubjectPlanDataRequest;
            this.noPlanDialog = true;
        }
    }

    get pointSelecting() {
        return this.store.pointSelecting;
    }

    set pointSelecting(value: boolean) {
        this.$store.commit(this.mutationsTypes.UPDATE_POINT_SELECTING, value);
    }

    get showPlanDialog() {
        return this.planState.showDialog;
    }

    set showPlanDialog(value) {
        this.$store.commit(this.planMutationTypes.UPDATE_SHOW_DIALOG, value);
    }

    get currentPlan() {
        return this.planState && this.planState.current ?
            this.planState.current as InvestmentSubjectPlan :
            {} as InvestmentSubjectPlan;
    }

    set currentPlan(value) {
        this.$store.commit(this.planMutationTypes.UPDATE_CURRENT, value);
    }

    get plans() {
        return this.planState.data;
    }

    public initFocus() {
        if (!this.show) {
            this.form.reset();
        }
    }

    public created() {
        //
    }
}
