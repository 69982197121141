
import ListComponent from '@/shared/components/layout/list/list-component';
import {Component, Watch} from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import { HeaderFieldType } from '@/shared/types';

import {
    investmentSubjectChangesValuationModule
} from '@/modules/investments/shared/state/submodules';
import { changesValuationHeaders } from '@/modules/changes/shared/config/index';

import { InvestmentSubjectChangesValuationDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-changes-valuation-data-request';
import { InvestmentSubjectChangesValuation } from '@/modules/investments/shared/models/subjects/investment-subject-changes-valuation';

@Component({
    props: {
        subjectId: Number
    },
    components: {
        ItemsTable,
    }
})
export default class InvestmentSubjectChangesValuationsList extends ListComponent
<InvestmentSubjectChangesValuation, InvestmentSubjectChangesValuationDataRequest> {

    public subjectId!: number;
    public headers: HeaderFieldType[] = changesValuationHeaders;
    public props: ModuleProps = investmentSubjectChangesValuationModule.moduleProps;
    public store = this.$store.state.investmentsState.subjectChangesValuationState;
    public mutationTypes = investmentSubjectChangesValuationModule.mutationsTypes;
    public actionsTypes = investmentSubjectChangesValuationModule.actionsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;

    public created() {
        this.id = this.subjectId;
        this.fetchData();
    }

    @Watch('subjectId')
    public changeSubjectId(newVal: number) {
        this.id = newVal;
        if (this.page !== 1) {
            this.page = 1;
        } else {
            this.fetchData();
        }
    }
}
