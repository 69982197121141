
import ItemsTable from '@/shared/components/layout/list.vue';
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

import { TicketPriority, prioritiesModule, priorityListHeaders, TicketPriorityDataRequest } from '@/modules/tickets/shared';
import PriorityForm from './form.vue';
import ChangeOrderDialog from '@/modules/tickets/components/settings/priorities/change-order-dialog.vue';
import { httpClient } from '@/shared/services';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
        components: {
            ItemsTable,
            PriorityForm,
            TextContrast,
            IconContrast,
            ChangeOrderDialog,
            TooltipBtn,
        },
    })
    export default class TicketPrioritiesList extends ListComponent<TicketPriority, TicketPriorityDataRequest> {
        public headers: object[] = priorityListHeaders;
        public actionsTypes = prioritiesModule.actionsTypes;
        public mutationTypes = prioritiesModule.mutationsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public store: any = this.$store.state.ticketsState.prioritiesState;
        public props: ModuleProps = prioritiesModule.moduleProps;
        public loadingSetNewDefaultPriority = false;

        public changeOrderDialog: boolean = false;

        get investmentTags() {
            return this.store.investmentTagsState.dataList;
        }

        public mounted() {
            this.fetchData();
        }

        public setNewDefaultPriority(id: number) {
            this.loadingSetNewDefaultPriority = true;
            httpClient
                .patch(`api/v1/tickets/priorities/${id}`, {
                    default: true,
                })
                .then(() => {
                    this.fetchData();
                    this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Zmieniono domyślny priorytet' });
                })
                .catch(() => {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
                    this.loadingSetNewDefaultPriority = false;
                });
        }
    }
