import {findLastId} from '@/modules/investments/components/generator/helpers';
import {InvestmentGeneratorItem} from '@/modules/investments/components/generator/types';

export class CRUD<T extends InvestmentGeneratorItem> {

    public constructor(private table: T[]) {
    }

    public setTable(table: T[]) {
        this.table = table;
    }

    public updateItem(data: T) {
        const tableCopy = [...this.table];
        let editedObject = {} as T;
        const index = tableCopy.findIndex((item) => item.id === data.id);
        if (index !== -1) {
            editedObject = {
                ...tableCopy[index],
                ...data,
            };
            tableCopy.splice(index, 1, editedObject);
        }
        return {table: tableCopy, item: editedObject};
    }

    public showItem(id: number) {
        return this.table.find((data) => data.id === id);
    }

    public cloneItem(id: number, replaceData?: object) {
        const tableCopy = [...this.table];
        const index = this.table.findIndex((item) => item.id === id);
        const editData = {
            ...tableCopy[index],
            ...replaceData,
            id: (findLastId(this.table) + 1),
        };
        tableCopy.splice(index + 1, 0, editData);

        return {table: tableCopy, item: editData};
    }

    public dropItem(id: number) {
        const tableCopy = [...this.table];
        const index = tableCopy.findIndex((item) => item.id === id);
        if (index !== -1) {
            tableCopy.splice(index, 1);
        }
        return tableCopy;
    }

    public duplicateItem(id: number, duplications?: number) {
        const tableCopy = [...this.table];
        const index = tableCopy.findIndex((item) => item.id === id);
        if (index !== -1) {
            const editedObject = {
                ...tableCopy[index],
                duplications,
            };
            tableCopy.splice(index, 1, editedObject);
        }
        return tableCopy;
    }

    public dropDuplications() {
        const tableCopy = [...this.table];

        tableCopy.map((item) => {
            delete item.duplications;
        });

        return tableCopy;
    }
}
