
import {Component, Prop, Vue} from 'vue-property-decorator';
import {errorsToArray} from '@/shared/services';

@Component
export default class DataChangedWarningDialog extends Vue {
    @Prop(Boolean) private active!: boolean;
    @Prop(String) private warning!: string;
    @Prop(String) private error!: string;
    @Prop(Object) private errorMessages!: object;
    @Prop(String) private size!: string;

    get errors() {
        return errorsToArray(this.errorMessages as any);
    }

    get dialogSize() {
        switch (this.size) {
            case 'medium':
                return '700px';
            case 'big':
                return '900px';
            default:
                return '500px';
        }
    }

    public confirmAction() {
        this.$emit('confirmed');
    }

    public cancelAction() {
        this.$emit('canceled');
    }
}
