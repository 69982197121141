import { InvestmentSubjectPropertyOperation } from '@/modules/investments/shared/models/subjects/investment-subject-property-operation';
import { MediaFile } from '@/modules/media/shared/models/media-file';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { InvestmentSubjectEditOperationDataRequest } from '@/modules/investments/shared/requests/subjects/operations';

export const simplifyData = (editedItem: any) => {
    const item = JSON.parse(JSON.stringify(editedItem));
    for (const field in item.edit) {
        if (
            item.edit.hasOwnProperty(field) &&
            typeof item.edit[field] === 'object' &&
            item.edit[field].hasOwnProperty('id')
        ) {
            item.edit[field] = item.edit[field].id;
        }
    }
    if (item.edit.media && item.edit.media.items) {
        item.edit.media.items = item.edit.media.items.map((e: MediaFile) => e.id);
    }

    if (item.edit.hasOwnProperty('description_replace') && !item.edit.hasOwnProperty('description')) {
        delete item.edit.description_replace;
    }

    if (item.edit.properties) {
        item.edit.properties = item.edit.properties.map((e: InvestmentSubjectPropertyOperation) => {
            e.investment_subject_property_type_id = e.investment_subject_property_type ?
                e.investment_subject_property_type.id : 0;
            delete e.investment_subject_property_type;
            return e;
        });
    }

    if (item.selection && item.selection.length > 0 && typeof item.selection[0] === 'object') {
        item.selection = item.selection.map((e: InvestmentSubject) => e.id);
    }

    return item;
};


export const subjectCodeRules = [
    (v: string) => {
        if (!v || v === '{{automatyczny}}') { return true; }
        const variableRegex = /(\{\{.+?\}\})/g;
        return isNaN(Number(v.replace(variableRegex, ''))) || 'Kod nie może składać się tylko liczb i zmiennych';
    },
    (v: string) => {
        if (!v || v === '{{automatyczny}}') { return true; }
        const matches = [...v.matchAll(/(\{\{numer_przedmiotu\}\}|\{\{id_inwestycji\}\}|.?)\{\{numer_przedmiotu\}\}(\{\{numer_przedmiotu\}\}|.?|)/g)];
        const charsAround = [...matches.map((match) => match[1]), ...matches.map((match) => match[2])];
        return charsAround.every((val: string) => val === '' || val.length === 1 && isNaN(Number(val))) || 'Numer przedmiotu musi być oddzielony od liczb';
    }
];
