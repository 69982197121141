import {DataRequest} from '@/shared/types';

export class InvestmentSubjectPropertyDataRequest extends DataRequest {
    // tslint:disable-next-line:variable-name
    public investment_subject_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_subject_property_type_id: number = 0;
    public public: boolean = false;
    public value: string = '';
}
