
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {investmentSubjectPropertyTypesModule} from '@/modules/investments/shared/state/submodules';
import {
    InvestmentSubjectPropertyType
} from '@/modules/investments/shared/models/subjects/investment-subject-property-type';
import {InvestmentSubjectPropertyTypeDataRequest} from '@/modules/investments/shared/requests/subjects/investment-subject-property-type-data-request';
import SubjectPropertyTypeForm from './form.vue';
import {investmentSubjectPropertyTypesListHeaders} from '@/modules/investments/shared/config';

@Component({
    components: {
        ItemsTable,
        SubjectPropertyTypeForm,
    },
})
export default class SubjectPropertiesTypesList extends ListComponent<InvestmentSubjectPropertyType,
    InvestmentSubjectPropertyTypeDataRequest> {
    public headers: object[] = investmentSubjectPropertyTypesListHeaders;
    public actionsTypes =
        investmentSubjectPropertyTypesModule.actionsTypes;
    public mutationTypes =
        investmentSubjectPropertyTypesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.investmentsState
        .subjectPropertyTypesState;
    public props: ModuleProps =
        investmentSubjectPropertyTypesModule.moduleProps;
}
