
import { FormRules } from '@/shared/validation/form-rules';
import { Component, Vue } from 'vue-property-decorator';
import { investmentTagsModule } from '@/modules/investments/shared/state/submodules';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import InvestmentEditor from '@/modules/investments/components/generator/editor.vue';
import BlockTemplate from '@/modules/investments/components/generator/templates/blocks/index.vue';
import InvestmentGeneratorForm from '@/modules/investments/components/generator/components/form.vue';
import { InvestmentDataRequest } from '@/modules/investments/shared/requests/investment-data-request';
import { InvestmentObjectGenerated } from '@/modules/investments/shared/models/investment-object';
import { InvestmentGeneratorTree } from '@/modules/investments/components/generator/types';
import { investmentsModule } from '@/modules/investments/shared/state/module';

@Component({
    components: {
        InvestmentEditor,
        InvestmentGeneratorForm,
        DatePicker,
    },
})
export default class InvestmentGenerator extends Vue {
    public actionsTypes = investmentsModule.actionsTypes;
    public investmentTree: InvestmentGeneratorTree = {} as InvestmentGeneratorTree;
    public dialog = true;
    public statusDialog = false;
    public step: number = 1;
    public investmentData: InvestmentDataRequest = new InvestmentDataRequest();
    public formRules = new FormRules();
    public isMApp = this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
    public step2Options = [
        {
            id: 1,
            name: 'Osiedle mieszkaniowe',
            component: BlockTemplate,
            icon: 'mdi-office-building',
        },
        {
            id: 3,
            name: 'Własna inwestycja',
            icon: 'mdi-square-edit-outline',
        },
    ];
    public optionChosen = null;

    public mounted() {
        window.addEventListener('beforeunload', this.handleUnload.bind(this));
    }

    public handleUnload(event: any) {
        if (!this._.isEmpty(this.investmentData) && this.dialog) {
            event.returnValue = true;
            return true;
        }

        return;
    }

    public beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleUnload.bind(this));
    }

    public created() {
        this.$store.dispatch(investmentTagsModule.actionsTypes.FETCH_DATA, {
            simple: false,
        });
    }

    get loading() {
        return this.$store.state.investmentsState.loadingItem;
    }

    get error() {
        return this.$store.state.investmentsState.error;
    }

    get errorMessages() {
        return this.$store.state.investmentsState.errorMessages;
    }

    get item() {
        return this.$store.state.investmentsState.current;
    }

    public generateInvestment(objects: InvestmentObjectGenerated[]) {
        this.statusDialog = true;
        const itemData = {
            ...this.investmentData,
            objects,
        };

        this.$store.dispatch(this.actionsTypes.GENERATE_INVESTMENT, itemData);
    }

    public getInvestmentTree(investmentTree: InvestmentGeneratorTree) {
        this.investmentTree = investmentTree;
        this.step = 4;
    }
}
