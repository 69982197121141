import {Component, Vue, Watch} from 'vue-property-decorator';
import {FormRules} from '@/shared/validation/form-rules';
import { parseDate, parseDateTime } from '@/shared/helpers';
import {logger} from '@/shared/services';

@Component
export default class GeneratorForm<T> extends Vue {
    public itemData!: T;
    public formRules: FormRules = new FormRules();
    public edit!: boolean;
    public show!: boolean;
    public valid: boolean = false;
    public parseDate = parseDate;
    public parseDateTime = parseDateTime;
    public form: any = null;

    public cancelForm() {
        this.$emit('canceled');
    }

    @Watch('show')
    public onShowUpdate(value: boolean) {
        if (value) {
            this.form.resetValidation();
        }
    }

    public submitAndNext(e?: any) {
        this.submitForm(e, !this.edit).catch((error) => logger.error(error));
    }

    public async submitForm(e: any, next?: boolean) {
        if (!this.form) {
            return;
        }

        await this.form.validate();

        if (this.valid) {
            this.$emit('submited');

            if (next) {
                this.$emit('next');
            }
        }
    }

    public initFocus() {
        //
    }

    public formNextCallback(data: T) {
        //
    }

    public formCallback(data: T) {
        //
    }
}
