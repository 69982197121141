
import {Component, Prop, Vue} from 'vue-property-decorator';
import {categoriesModule, TicketCategory, ticketsModule} from '@/modules/tickets/shared';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

@Component({
  components: {TextContrast, IconContrast},
})
export default class CategoryPicker extends Vue {
  @Prop(Object) public category!: TicketCategory;
  @Prop(Boolean) public readOnly!: boolean;
  public menu = false;
  public beforeLoadedChip = {name: 'Brak', id: 1};
  public chosenOption: null | TicketCategory = null;
  public mutationTypes = ticketsModule.mutationsTypes;
  public categoryActions = categoriesModule.actionsTypes;
  public actionTypes = ticketsModule.actionsTypes;
  public store: any = this.$store.state.ticketsState;

  get categories() {
    return this.store.categoriesState.data;
  }

  public fetchCategories() {
    this.$store.dispatch(this.categoryActions.FETCH_DATA, {simple: false})
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
        });
  }

  public changeCategory() {
    const id = this.$route.params.id;

    this.$store.dispatch(this.actionTypes.UPDATE_ITEM, {
      id,
      ticket_category_id: this.activeCategory?.id,
    })
        .then(({data}) => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'success', text: 'Kategoria zmieniona'});
        })
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
        });
  }

  get activeCategory() {
    return this.chosenOption
        ? this.chosenOption
        : this.$props.category
            ? this.$props.category
            : this.beforeLoadedChip;
  }

  public created() {
    if (this.categories.length === 0) {
      this.fetchCategories();
    }
  }
}
