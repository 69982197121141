
import {
    VglAmbientLight,
    VglBoxGeometry,
    VglDirectionalLight,
    VglLineBasicMaterial,
    VglLineLoop,
    VglMesh,
    VglMeshToonMaterial,
    VglPerspectiveCamera,
    VglPlaneGeometry,
    VglRenderer,
    VglScene,
    VglTexture,
} from 'vue-gl';
import {Component, Vue} from 'vue-property-decorator';
import {BlockInvestmentLevel} from '@/modules/investments/components/generator/templates/blocks/models/types';

@Component({
    props: {
        rotation: {
            type: Number,
            default: 0.25,
        },
        height: Number,
        low: Number,
        levels: {
            type: Array,
            default: () => [],
        },
        current: Number,
    },
    components: {
        VglRenderer,
        VglScene,
        VglMesh,
        VglPerspectiveCamera,
        VglDirectionalLight,
        VglAmbientLight,
        VglMeshToonMaterial,
        VglLineBasicMaterial,
        VglLineLoop,
        VglBoxGeometry,
        VglPlaneGeometry,
        VglTexture,
    },
})
export default class BlockSprite extends Vue {
    public x: number = 0;
    public y: number = 0;
    public z: number = 0;
    public levels!: BlockInvestmentLevel[];

    get levelIndexes() {
        return this.levels.map(({level}) => Number(level));
    }

    public range(start: number, end: number) {
        return this._.range(start, end + 1);
    }
}
