
import { Component, Prop, PropSync, VModel, Vue, Watch } from 'vue-property-decorator';
import { VariableFieldType } from '@/shared/types';
import { FormRules } from '@/shared/validation/form-rules';
import { clone } from 'lodash';
import { httpClient } from '@/shared/services/http-client/http-client';
import Hint from './hint.vue';

@Component({
    components: {
        Hint,
    },
})
export default class VariableTextField extends Vue {
    @Prop(String) public label!: string;
    @Prop(Boolean) public disabled!: boolean;
    @Prop(Array) public rules!: FormRules;
    @Prop(Array) public variables!: VariableFieldType[];
    @Prop(String) public externalValidationRoute!: string;
    @Prop(String) public externalValidationValue!: string;
    @Prop({
        type: Object,
        default: () => {
            return {};
        },
    })
    public externalValidationPayload!: { [k: string]: any };

    @Prop(String) public textInputTooltipText!: string;

    @Prop({
        type: Boolean,
        default: false,
    }) public textInputShowTooltip!: boolean;


    @PropSync('errorMessages', { type: [Array, String] }) public errorMessagesSync!: string[] | string;
    @VModel() public textVal!: string;

    public textField: any = null;
    public variableRegex = /(\{\{.+?\}\})/g;
    public activeOverwriteVariable: string | null = null;
    public stashedString = '';
    public validationLoading = false;
    public validationTimer!: NodeJS.Timeout ;

    get includedVariables() {
        if (!this.textVal) {
            return [];
        }
        return [...this.textVal.matchAll(this.variableRegex)];
    }

    get overwriteVariables() {
        return this.variables.filter((val) => val.overwrite);
    }

    get multiUseVariables() {
        return this.variables.filter((val) => !val.overwrite);
    }

    public mounted() {
        this.textField = this.$refs.textField as Vue;
        this.textField = this.textField.$el.querySelector('input');
    }

    public overwriteWithVariable(variable: VariableFieldType) {
        if (this.activeOverwriteVariable !== variable.value) {
            this.stashedString = clone(this.textVal);
            this.activeOverwriteVariable = variable.value;
            this.$set(this, 'textVal', `{{${variable.value}}}`);
        } else {
            this.activeOverwriteVariable = null;
            this.textVal = this.stashedString;
        }
    }

    public putInField(variable: VariableFieldType) {
        if (this.activeOverwriteVariable) {
            return;
        }

        if (this.textVal === undefined) {
            this.$set(this, 'textVal', '');
            this.$forceUpdate();
            this.$nextTick(() => this.putInField(variable));
            return;
        }

        const textUntilSelection = this.textVal.slice(0, this.textField.selectionStart);
        const textAfterSelection = this.textVal.slice(this.textField.selectionEnd, this.textVal.length);
        const variableText = `{{${variable.value}}}`;

        const text = textUntilSelection + variableText + textAfterSelection;
        this.$set(this, 'textVal', text);

        if (!document.activeElement || document.activeElement.id !== this.textField.id) {
            this.textField.focus();
        }
        this.$nextTick(() => {
            const index = textUntilSelection.length + variableText.length;
            this.textField.setSelectionRange(index, index);
        });
    }

    public keyDownHandler(event: KeyboardEvent) {
        switch (event.key) {
            case '{':
            case '}':
                event.preventDefault();
                break;
            case 'Backspace':
            case 'Delete':
                this.handleDeletion(event);
                break;
            default:
                if (this.activeOverwriteVariable && event.key.length === 1) {
                    event.preventDefault();
                    this.activeOverwriteVariable = null;
                    this.textVal = event.key;
                }
                this.selectionHandler();
        }
    }

    public handleDeletion(event: KeyboardEvent) {
        const start = this.textField.selectionStart;
        const end = this.textField.selectionEnd;

        const variablesToDelete = this.getVariablesToDelete(event, start, end);

        if (variablesToDelete.length === 0) {
            this.selectionHandler();
            return;
        }

        event.preventDefault();

        const deleteStart = variablesToDelete.reduce(
            (accumulator, currentValue) => Math.min(accumulator, currentValue.index ?? start),
            start,
        );

        const deleteEnd = variablesToDelete.reduce(
            (accumulator, currentValue) =>
                Math.max(accumulator, currentValue.index ? currentValue.index + currentValue[0].length : end),
            end,
        );

        this.textField.setSelectionRange(deleteStart, deleteStart);
        this.$set(this, 'textVal', this.textVal.slice(0, deleteStart) + this.textVal.slice(deleteEnd));

        this.$nextTick(() => {
            this.textField.setSelectionRange(deleteStart, deleteStart);
        });
    }

    public selectionHandler(_?: MouseEvent, cb = () => {/**/}) {
        if (!document.activeElement || document.activeElement.id !== this.textField.id) {
            return;
        }

        // timeout to always get current positions of selection
        setTimeout(() => {
            if (this.activeOverwriteVariable) {
                this.textField.setSelectionRange(0, this.textVal.length);
                return;
            }
            const { selectionStart, selectionEnd } = this.textField;

            // adjust selection to cover full variable if selection inside variable
            const overlapingVariable = this.getVariableAsSelection(selectionStart, selectionEnd);
            if (overlapingVariable) {
                this.textField.setSelectionRange(overlapingVariable.start, overlapingVariable.end);
                cb();
                return;
            }

            // expand selection to cover all variables if selection includes parts of them
            const overlapingSelection = this.getSelectionThatIncludesVariables(selectionStart, selectionEnd);
            if (overlapingSelection) {
                this.textField.setSelectionRange(overlapingSelection.start, overlapingSelection.end);
                cb();
                return;
            }
        }, 1);
    }

    private getVariablesToDelete(event: KeyboardEvent, start: number, end: number) {
        return this.includedVariables.filter((val) => {
            if (val.index === undefined) { return false; }

            const varEnd = val.index + val[0].length;
            const varStart = val.index;

            if (start === end && event.key === 'Backspace') {
                return varEnd === start;
            } else if (start === end && event.key === 'Delete') {
                return varStart === start;
            }

            return (start > varStart && start < varEnd) || (end > varStart && end < varEnd);
        });
    }

    private getVariableAsSelection(start: number, end: number): { start: number; end: number } | null {
        let variables = this.includedVariables;
        const isRangeSelection = start !== end;
        let position: { start: number; end: number } | null = null;
        variables = variables.filter((val) => val.index !== undefined && val.index < start);

        if (variables.length === 0) {
            return null;
        }

        variables.forEach((val, index) => {
            if (val.index === undefined) {
                return;
            }

            const selectionInsideVariable = isRangeSelection
                ? val.index + val[0].length >= end
                : val.index + val[0].length > end;

            if (selectionInsideVariable) {
                position = {
                    start: val.index,
                    end: val.index + val[0].length,
                };
                variables.length = index + 1; // Behaves like `break`
            }
        });

        return position;
    }

    private getSelectionThatIncludesVariables(start: number, end: number): { start: number; end: number } | null {
        const variables = this.includedVariables;
        let selectedText = this.textVal.slice(start, end);
        selectedText = selectedText.replace(this.variableRegex, (_, $1) => {
            return $1.replace(/./g, '*');
        });

        const position = { start, end };

        const varStart = selectedText.indexOf('{{');
        const varEnd = selectedText.indexOf('}}');

        if (varStart !== -1) {
            // @ts-ignore-next-line
            const includedVariable = variables.findLast(
                (val: RegExpMatchArray) => val.index && start <= val.index && val.index <= end,
            );

            if (!includedVariable || !includedVariable.index) {
                return null;
            }

            position.start = Math.min(includedVariable.index, position.start);
            position.end = Math.max(includedVariable.index + includedVariable[0].length, position.end);
        }
        if (varEnd !== -1) {
            const includedVariable = variables.find((val) => {
                if (val.index === undefined) {
                    return false;
                }
                const index = val.index + val[0].length;
                return start <= index && index <= end;
            });

            if (!includedVariable || includedVariable.index === undefined) {
                return null;
            }

            position.start = Math.min(includedVariable.index, position.start);
            position.end = Math.max(includedVariable.index + includedVariable[0].length, position.end);
        }

        return position;
    }

    private sendValidationRequest(v: string) {
        if (v) {
            this.validationLoading = true;

            const payload = {
                [this.externalValidationValue]: v,
                ...this.externalValidationPayload,
            };

            httpClient
                .post(this.externalValidationRoute, payload)
                .catch(({ data }: any) => {
                    this.$set(this, 'errorMessagesSync', [
                        data.message,
                        ...(this.errorMessagesSync ? this.errorMessagesSync : []),
                    ]);
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.validationLoading = false;
                });
        }
    }

    private setActiveOverwriteVariable() {
        if (!this.includedVariables[0]) {
            this.activeOverwriteVariable = null;
            return;
        }

        const overwriteVar = this.variables.find(
            (val) => `{{${val.value}}}` === this.includedVariables[0][0] && val.overwrite,
        );

        if (!overwriteVar || this.activeOverwriteVariable === overwriteVar.value) {
            return;
        }

        this.activeOverwriteVariable = overwriteVar.value;
    }

    @Watch('includedVariables')
    private preventUnlistedVariable(value: RegExpMatchArray[]) {
        value.forEach((variableMatch) => {
            const pureVar = variableMatch[0].slice(2, variableMatch[0].length - 2);

            if (!this.variables.map((val) => val.value).includes(pureVar)) {
                this.textVal = this.textVal.replaceAll(variableMatch[0], '');
            }
        });
    }

    @Watch('textVal', { immediate: true })
    private onTextValChange(val: string) {
        this.setActiveOverwriteVariable();
        if (!this.externalValidationRoute) {
            return;
        }
        this.errorMessagesSync = [];
        clearTimeout(this.validationTimer);
        this.validationTimer = setTimeout(() => {
            this.sendValidationRequest(val);
        }, 300);
    }
}
