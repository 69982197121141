

import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {investmentSubjectRoomsListHeaders} from '@/modules/investments/shared/config';
import {ModuleState} from '@/shared/state/template/module-state';
import {investmentSubjectRoomsModule} from '@/modules/investments/shared/state/submodules';
import ItemsTable from '@/shared/components/layout/list.vue';
import InvestmentSubjectRoomDetails from '@/modules/investments/components/objects/subjects/rooms/details.vue';
import InvestmentSubjectRoomForm from '@/modules/investments/components/objects/subjects/rooms/form.vue';
import {
    InvestmentSubjectRoomDataRequest
} from '@/modules/investments/shared/requests/subjects/investment-subject-room-data-request';
import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';
import {InvestmentSubjectRoomsState} from '@/modules/investments/shared/state/state/rooms';
import {SaveResponse} from '@/shared/types';
import {InvestmentSubjectPlan} from '@/modules/investments/shared/models/investment-subject-plan';
import {
    InvestmentSubjectPlanDataRequest
} from '@/modules/investments/shared/requests/investment-subject-plan-data-request';
import { getNextCode } from '@/modules/investments/shared/helpers/rooms';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        ItemsTable,
        InvestmentSubjectRoomDetails,
        InvestmentSubjectRoomForm,
    },
    props: {
        subjectId: Number,
        hideDefaultFooter: Boolean,
        customCreateItem: Boolean,
    },
})
export default class InvestmentSubjectRoomsList extends ListComponent<InvestmentSubjectRoom,
    InvestmentSubjectRoomDataRequest> {
    public actionsTypes = investmentSubjectRoomsModule.actionsTypes;
    public mutationTypes = investmentSubjectRoomsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers = investmentSubjectRoomsListHeaders;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public props = investmentSubjectRoomsModule.moduleProps;
    public subjectId!: number;
    public store: InvestmentSubjectRoomsState = this.$store.state.investmentsState.subjectRoomsState;
    public plansState: ModuleState<InvestmentSubjectPlan, InvestmentSubjectPlanDataRequest> =
        this.$store.state.investmentsState.subjectPlansState;

    public created() {
        this.id = this.subjectId;
    }

    get itemData() {
        const itemData = {
            storey: 0,
        } as InvestmentSubjectRoomDataRequest;

        itemData.code = getNextCode(this.store.dataList as InvestmentSubjectRoom[]);

        return itemData;
    }

    public closeForm() {
        this.editedItem = {} as InvestmentSubjectRoomDataRequest;
        this.pointSelecting = false;
        callSuper(this, 'closeForm');
    }

    public loadAllRooms() {
        this.$store.dispatch(this.fetchAction, {
            id: this.subjectId,
            simple: true,
        });
    }

    public roomRemoved() {
        const currentIndex = this.store.dataList.map((e) => e.id).indexOf(this.current.id);
        if (currentIndex !== -1) {
            this.$delete(this.store.dataList, currentIndex);
        }
    }

    public selectPoint() {
        this.formDialog = false;
    }

    get pointSelecting() {
        return this.store.pointSelecting;
    }

    set pointSelecting(value: boolean) {
        this.$store.commit(this.mutationTypes.UPDATE_POINT_SELECTING, value);
    }

    public roomNextSaved() {
        const itemData = {} as InvestmentSubjectRoomDataRequest;

        if (this.store.dataList.length > 0) {
            const lastRoom = this.store.dataList[this.store.dataList.length - 1] as InvestmentSubjectRoom;
            if (lastRoom) {
                this.$set(itemData, 'storey', lastRoom.storey);
            }
        }

        this.$set(itemData, 'code', getNextCode(this.store.dataList as InvestmentSubjectRoom[]));

        this.setDefaultData(itemData);
        this.$emit('saved', true);
    }

    public roomSaved(response: SaveResponse<InvestmentSubjectRoom>) {
        this.$emit('saved', false);
        if (!response.next) {
            this.pointSelecting = false;
            this.editedItem = {} as InvestmentSubjectRoomDataRequest;
        }
        const roomIndex = this.store.dataList.map((e) => e.id).indexOf(response.data.id);
        if (roomIndex !== -1) {
            this.$set(this.store.dataList, roomIndex, response.data);
        } else {
            this.store.dataList.push(response.data);
        }
        this.itemSaved();
    }
}

