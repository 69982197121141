
import echoInstance from '@/echo';
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component, Watch} from 'vue-property-decorator';
import {investmentsModule} from '../shared/state/module';
import {investmentsListFilters, investmentsListHeaders} from '../shared/config';
import ListComponent from '@/shared/components/layout/list/list-component';
import DataChangedWarningDialog from '@/shared/components/dialogs/data-changed-warning-dialog.vue';
import {InvestmentDataRequest} from '@/modules/investments/shared/requests/investment-data-request';
import InvestmentsForm from '@/modules/investments/components/form.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import {Investment} from '../shared/models/investment';
import {ListFilter} from '@/shared/types';
import {
    investmentObjectsModule,
    investmentSubjectsModule,
    investmentTagsModule
} from '@/modules/investments/shared/state/submodules';
import {logger} from '@/shared/services';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import TotalCosts from '@/modules/investments/components/total-costs.vue';
import {costStatuses} from '@/shared/config/costs';
import { callSuper } from '@/shared/helpers';
import { acceptancesModule } from '@/modules/acceptances/shared/state/module';
import { costsModule } from '@/modules/costs/shared/state/module';


@Component({
    components: {
        ItemsTable,
        InvestmentTagChip,
        InvestmentsForm,
        TotalCosts,
        DataChangedWarningDialog,
    },
})
export default class InvestmentsList extends ListComponent<Investment, InvestmentDataRequest> {
    public headers: object[] = investmentsListHeaders;
    public actionsTypes = investmentsModule.actionsTypes;
    public tagsActions = investmentTagsModule.actionsTypes;
    public mutationTypes = investmentsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.investmentsState;
    public props: ModuleProps = investmentsModule.moduleProps;
    public filters: ListFilter[] = investmentsListFilters;
    public currentStatus = 0;
    public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));
    public dataChanged: boolean = false;
    public dataChangedWarning: string = '';

    public leavePresenceChannel() {
        const channelNameWithPrefix = `presence-client.${this.$store.state.authState.user.client_id}.investment.${(this.editedItem as any).id}.edit`;
        echoInstance.leaveChannel(channelNameWithPrefix);
    }

    public itemSaved() {
        this.leavePresenceChannel();
        callSuper(this, 'itemSaved');
    }

    public closeForm() {
        this.leavePresenceChannel();
        callSuper(this, 'closeForm');
    }

    public fetchData(callback?: (data: Investment[]) => void) {
        if (!this.filters.map(({name}) => (name)).includes('investment_tag_id')) {
            this.$store.dispatch(this.tagsActions.FETCH_DATA, {simple: true})
                .then(() => {
                    this.filters = [...this.filters, {
                        type: 'search',
                        name: 'investment_tag_id',
                        label: 'Tag',
                        chip: true,
                        data: this.investmentTags,
                    }];
                })
                .catch((error) => {
                    logger.error(error);
                });
        }

        callSuper(this, 'fetchData', callback);
    }

    public mounted() {
        this.fetchData();
    }

    get investmentTags() {
        return this.store.investmentTagsState.dataList;
    }

    get warningInfo() {
        const {number_of_objects, number_of_subjects, number_of_costs, number_of_acceptances} = this.store.current;
        const getName = (names: any, amount: any) => {
            if (amount === 1) {
                return names.one;
            }
            if (amount > 1 && amount < 5) {
                return names.two;
            }
            if (amount >= 5) {
                return names.five;
            }
        };
        const childrenName = getName(investmentObjectsModule.moduleProps.names, number_of_objects);
        const subjectName = getName(investmentSubjectsModule.moduleProps.names, number_of_subjects);
        const acceptanceName = getName(acceptancesModule.moduleProps.names, number_of_acceptances);
        const costName = getName(costsModule.moduleProps.names, number_of_costs);
        let disable = false;
        let text = '';

        if (number_of_acceptances > 0 || number_of_costs > 0) {
            disable = true;
            text = 'Inwestycja nie może zostać usunięta ponieważ jest powiązana z  ';
            text += number_of_costs > 0 ? `${number_of_costs} ${costName} ` : '';
            text += number_of_costs > 0 && number_of_acceptances > 0 ? 'oraz ' : '';
            text += number_of_acceptances > 0 ? `${number_of_acceptances} ${acceptanceName} ` : '';
        } else if (number_of_objects > 0 || number_of_subjects > 0) {
            text = 'Inwestycja ma pod sobą ';
            text += number_of_objects > 0 ? `${number_of_objects} ${childrenName} ` : '';
            text += number_of_objects > 0 && number_of_subjects > 0 ? 'oraz ' : '';
            text += number_of_subjects > 0 ? `${number_of_subjects} ${subjectName} ` : '';
        }
        const result: any = {};
        result.text = text;
        result.disabled = disable;
        return result;
    }

    @Watch('editedItem')
    public onEditedItemChange() {
        if (this.editedItem) {
            const channelName = `client.${this.$store.state.authState.user.client_id}.investment.${(this.editedItem as any).id}.edit`;

            echoInstance.join(channelName)
            .listen('.investment_updated', (e: any) => {
                this.dataChanged = true;
                this.dataChangedWarning = e.description;
            });
        }
    }

}
