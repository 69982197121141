
import FormComponent from '@/shared/components/layout/form/form-component';
import { InvestmentSubjectPropertyDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-property-data-request';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { Component } from 'vue-property-decorator';
import { InvestmentSubjectProperty } from '@/modules/investments/shared/models/subjects/investment-subject-property';
import { ModuleState } from '@/shared/state/template/module-state';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import {
    InvestmentSubjectPropertyType
} from '@/modules/investments/shared/models/subjects/investment-subject-property-type';
import { InvestmentSubjectPropertyTypeDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-property-type-data-request';
import SubjectPropertyTypesForm from '@/modules/investments/components/settings/subject-properties/form.vue';
import {
    investmentSubjectPropertiesModule,
    investmentSubjectPropertyTypesModule,
} from '@/modules/investments/shared/state/submodules';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        AutoCompleteWithAddNew,
    },
})
export default class InvestmentSubjectPropertyForm extends FormComponent<InvestmentSubjectPropertyDataRequest> {
    public store: ModuleState<InvestmentSubjectProperty, InvestmentSubjectPropertyDataRequest> =
        this.$store.state.investmentsState.subjectPropertiesState;
    public actionsTypes = investmentSubjectPropertiesModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public propertyTypesState: ModuleState<InvestmentSubjectPropertyType, InvestmentSubjectPropertyTypeDataRequest> =
        this.$store.state.investmentsState.subjectPropertyTypesState;
    public propertyForm = SubjectPropertyTypesForm;
    public propertyTypeModule = investmentSubjectPropertyTypesModule;
    public requestData = new InvestmentSubjectPropertyDataRequest();

    get formTitle(): string {
        return this.edit ? 'Dodaj właściwość przedmiotu inwestycji' : 'Dodaj właściwość przedmiotu inwestycji';
    }

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.propertyForm;
         }
}
