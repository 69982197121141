
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import DetailsComponent from '@/shared/components/layout/details/details-component';
    import { InvestmentObject } from '@/modules/investments/shared/models/investment-object';
    import { InvestmentObjectDataRequest } from '@/modules/investments/shared/requests/investment-object-data-request';
    import { ModuleState } from '@/shared/state/template/module-state';
    import { investmentObjectsModule } from '@/modules/investments/shared/state/submodules';
    import Investments from '@/modules/investments/investments.vue';
    import InvestmentSubjectsList from '@/modules/investments/components/objects/subjects/list.vue';
    import { InvestmentObjectType } from '@/modules/investments/shared/models/objects/investment-object-type';
    import TotalCosts from '@/modules/investments/components/total-costs.vue';
    import {costStatuses} from '@/shared/config/costs';
    import LinkedEvents from '@/modules/calendar/components/linked-events.vue';

    @Component({
        components: {
            InvestmentSubjectsList,
            TotalCosts,
            LinkedEvents,
        },
    })
    export default class InvestmentObjectDetails extends DetailsComponent<
        InvestmentObject
    > {
        public actionsTypes = investmentObjectsModule.actionsTypes;
        public showAction: string = this.actionsTypes.SHOW_ITEM;
        public store: ModuleState<
            InvestmentObject,
            InvestmentObjectDataRequest
        > = this.$store.state.investmentsState.objectsState;
        public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));

        public created() {
            //
        }
    }
