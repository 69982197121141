
import Component from 'vue-class-component';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {
    categoriesModule,
    prioritiesModule,
    statusesModule,
    tagsModule,
    Ticket,
    TicketDataRequest,
    ticketsModule,
} from '../shared';
import Kanban from '../components/kanban/kanban.vue';
import FilterBar from '@/shared/components/layout/list/filter-bar.vue';
import {ticketsFilters} from '@/modules/tickets/shared/config/filters';
import {ListFilter} from '@/shared/types';
import {httpClient, logger} from '@/shared/services';
import {mdiFormatListBulleted, mdiViewGrid} from '@mdi/js';
import TicketsForm from './form.vue';
import TicketsList from './list/list.vue';
import {actionsTypes as adminActionTypes} from '@/modules/admin/shared/state';
import {Provide, Watch} from 'vue-property-decorator';
import Vue from 'vue';
import {objectToQueryString} from '@/shared/helpers';
import {AxiosResponse} from 'axios';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {TicketStatusWithTickets} from '@/modules/tickets/shared/models/status';
import _ from 'lodash';
import ErrorBoundary from '@/shared/components/error-boundary/error-boundary.vue';

@Component({
    components: {
        ErrorBoundary,
        ListToolbar,
        Kanban,
        FilterBar,
        TicketsForm,
        TicketsList,
    },
})
export default class TicketsView extends ListComponent<Ticket, TicketDataRequest> {
    @Provide() public ticketsEventBus = new Vue();
    public headers: object[] = [];
    public actionsTypes = ticketsModule.actionsTypes;
    public mutationTypes = ticketsModule.mutationsTypes;
    public fetchAction: string = statusesModule.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = null;
    public props: ModuleProps = ticketsModule.moduleProps;
    public kanbanView: boolean = localStorage.getItem('kanban') === 'true';
    public statusLoading: boolean = true;
    public searchReload: boolean = false;
    public statuses: TicketStatusWithTickets[] = [];
    public icons = {mdiFormatListBulleted, mdiViewGrid};
    public newItem: TicketDataRequest | null = null;
    public isFormClosing = true;

    public filters: ListFilter[] = ticketsFilters;
    public filtersCopy: object = {};
    public searchTickets = '';
    public searchTicketsCopy = '';
    public searchTimer: number = 0;
    public prioritiesFilterIndex = 2;
    public prioritiesActionsTypes = prioritiesModule.actionsTypes;
    public categoriesFilterIndex = 4;
    public categoriesActionsTypes = categoriesModule.actionsTypes;

    public itemSaved() {
        this.newItem = this.editedItem;
        this.formDialog = false;
    }

    public mounted() {
        this.store = this.$store.state.ticketsState;
        this.props = ticketsModule.moduleProps;
        this.fetchAllFilters();
        this.setUsersFilters();
        const statuses = localStorage.getItem('ticket-statuses');
        if (statuses !== null) {
            this.statuses = JSON.parse(statuses) as TicketStatusWithTickets[];
        }
        this.fetchTicketsByStatuses();
    }

    public async fetchTicketsByStatuses() {
        if (this.statusLoading) {
            this.searchReload = true;
        }
        this.statusLoading = true;
        let url = `/api/v1/tickets/statuses/tickets?`;
        url += objectToQueryString(this.fetchFilters());
        httpClient
            .get(url).then((response: AxiosResponse<ModuleFetchPayload<TicketStatusWithTickets>>) => response.data)
            .then((response) => {
                this.statusLoading = false;
                if (response.data) {
                    this.statuses = response.data;
                    this.searchReload = false;
                    let statuses = JSON.parse(JSON.stringify(response.data));
                    statuses = statuses.map((status: TicketStatusWithTickets) => {
                        status.data = [];
                        status.loading = true;
                        return status;
                    });
                    localStorage.setItem('ticket-statuses', JSON.stringify(statuses));
                }
            })
            .catch((error) => {
                logger.error(error);
                this.statusLoading = false;
            });
    }

    public fetchAllFilters() {
        if (this.store.tagsState.data.length === 0) {
            this.fetchTagsFilters();
        }
        if (this.store.categoriesState.data.length === 0) {
            this.fetchFiltersData(this.categoriesFilterIndex, this.categoriesActionsTypes);
        }
        this.filters[this.categoriesFilterIndex].data = this.store.categoriesState.data
            .map((el: any) => {
                return {name: el.id, label: el.name, value: false};
            });
        if (this.store.prioritiesState.data.length === 0) {
            this.fetchFiltersData(this.prioritiesFilterIndex, this.prioritiesActionsTypes);
        }
        this.filters[this.prioritiesFilterIndex].data = this.store.prioritiesState.data.map((el: any) => {
            return {name: el.id, label: el.name, value: false};
        });
    }

    public fetchTagsFilters() {
        const index = 5;
        const tagsActionsTypes = tagsModule.actionsTypes;
        this.$store.dispatch(tagsActionsTypes.FETCH_DATA, {simple: true})
            .then((data) => {
                this.store.tagsState.data = data.data;
                this.filters[index].data = data.data;
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    public async setUsersFilters() {
        const index = 3;
        let allUsers = await this.$store.dispatch(adminActionTypes.FETCH_USER_DATA, {
            simple: true,
            filters: {search: ''},
            page: 1,
        });
        allUsers = allUsers.data;

        this.filters[index].data = allUsers;
    }

    public fetchFiltersData(index: number, actionsTypes: { [k: string]: string }) {
        this.$store
            .dispatch(actionsTypes.FETCH_DATA, {simple: true})
            .then((res) => {
                this.filters[index].data = res.data.map((el: any) => {
                    return {name: el.id, label: el.name, value: false};
                });
                if (index === 2) {
                    this.store.prioritiesState.data = res.data;
                } else if (index === 4) {
                    this.store.categoriesState.data = res.data;
                }
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    public fetchFilters() {
        return {filters: {...JSON.parse(JSON.stringify(this.filtersCopy)), search: this.searchTicketsCopy}};
    }

    public onFiltersChange(filters: any) {
        this.filtersCopy = filters;
        this.assignFilters(filters);
        this.searchReload = true;
        this.fetchTicketsByStatuses();
    }

    public async onItemSaved({data}: any) {
        this.formDialog = false;
        this.store.statusWithNewTicket = this.statuses.map((status) => status.id).indexOf(data.ticket_status_id);
    }

    public onItemNext(data: any) {
        this.formDialog = true;
        this.store.statusWithNewTicket = this.statuses.map((status) => status.id).indexOf(data.ticket_status_id);
    }

    public onChangeView(value: boolean) {
      this.kanbanView = value;
      localStorage.setItem('kanban', String(value));
    }

    @Watch('searchTickets')
    public onSearchChange(val: string) {
        clearTimeout(this.searchTimer);
        this.searchTimer = window.setTimeout(() => {
            this.searchTicketsCopy = val;
        }, 900);
    }

    @Watch('searchTicketsCopy')
    public onSearch(val: string) {
        this.searchReload = true;
        this.fetchTicketsByStatuses();
    }
}
