import {ListFilter} from '@/shared/types';

export const contractorsListFilters: ListFilter[] = [
    {
        name: 'is_company', label: 'Rodzaj kontrahenta', type: 'select', data: [
            {name: '1', label: 'Firma', value: false},
            {name: '0', label: 'Osoba fizyczna', value: false},
        ],
    },
];
