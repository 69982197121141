
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module
import {investmentSubjectPropertyTypesModule} from '../../../shared/state/submodules/subjects';

// dataRequest
import {
    InvestmentSubjectPropertyTypeDataRequest
} from '../../../shared/requests/subjects/investment-subject-property-type-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions},
})
export default class SubjectPropertyTypesForm extends FormComponent<InvestmentSubjectPropertyTypeDataRequest> {
    public actionsTypes = investmentSubjectPropertyTypesModule.actionsTypes;
    public store = this.$store.state.investmentsState
        .subjectPropertyTypesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new InvestmentSubjectPropertyTypeDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj typ właściwości przedmiotu inwestycji' : 'Dodaj typ właściwości przedmiotu inwestycji';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }
}
