
import Component from 'vue-class-component';
import SelectTree from '@/shared/components/elements/select-tree.vue';
import SubjectCard from '@/modules/investments/components/subjects/subject-card.vue';
import TitleCard from './title-card.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import ProspectStepComponent from '@/modules/investments/components/objects/subjects/prospect/prospect-step-component';

@Component({
    components: {
        ActionConfirmDialog,
        SelectTree,
        SubjectCard,
        TitleCard,
    },
})
export default class ProspectFormRecognitionData extends ProspectStepComponent {

}
