
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { tagsModule, Ticket, ticketsModule, TicketTag } from '@/modules/tickets/shared';

import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import { isEqual } from 'lodash';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import _ from 'lodash';

@Component({
    components: {
        IconContrast,
        TextContrast,
    },
})
export default class TagPicker extends Vue {
    @Prop(Array) public selectedTags!: TicketTag[];
    @Prop(Boolean) public readOnly!: boolean;
    public menu = false;
    public chosenOptions: TicketTag[] | [] = [];
    public isDownloaded = false;
    public lastChange: TicketTag[] | [] = [];
    public store: any = this.$store.state.ticketsState;
    public tagActions = tagsModule.actionsTypes;
    public actionTypes = ticketsModule.actionsTypes;

    get tags() {
        return this.store.tagsState.data;
    }

    get loading() {
        return this.store.tagsState.loading;
    }

    public fetchTags() {
        this.$store.dispatch(this.tagActions.FETCH_DATA, { simple: false }).catch(() => {
            this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
        });
    }

    public changeTags() {
        if (isEqual(this.lastChange, this.chosenOptions)) {
            return;
        }

        const id = this.$route.params.id;
        this.$store
            .dispatch(this.actionTypes.UPDATE_ITEM, {
                id,
                tags: this.activeTags.map((el) => el.id),
            })
            .then(({ data }: ModuleShowPayload<Ticket>) => {
                if (data) {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Tagi zmienione' });
                    this.lastChange = this.activeTags;
                    this.$emit('change');
                }
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            });
    }

    get activeTags() {
        if (!this.isDownloaded && this.tags) {
            this.isDownloaded = true;
            this.chosenOptions = this.selectedTags;
        }
        return this.chosenOptions;
    }

    set activeTags(value) {
        this.chosenOptions = value;
    }

    public created() {
        this.fetchTags();
    }

    @Watch('selectedTags', { immediate: true })
    public onTagsUpdate() {
        this.$set(this, 'activeTags', this.selectedTags);

        if (!this.activeTags) { return; }

        this.activeTags.forEach((activeTag: TicketTag) => {
            if (!this.tags.some((tag: TicketTag) => tag.id === activeTag.id)) {
                this.tags.push(activeTag);
            }
        });
    }
}
