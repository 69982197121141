
import Vue from 'vue';
import Component from 'vue-class-component';
import TitleCard from './title-card.vue';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { prospectMediaAvailable } from '@/shared/config/investments';
import { InvestmentSubjectsState } from '@/modules/investments/shared/state/state';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import ProspectStepComponent from '@/modules/investments/components/objects/subjects/prospect/prospect-step-component';
import { parseDate } from '@/shared/helpers';

@Component({
    components: {
        Wysiwyg,
        TitleCard,
        ActionConfirmDialog,
        DatePicker,
    },
})
export default class ProspectFormIndividualData extends ProspectStepComponent {
    public subjectsState: InvestmentSubjectsState = this.$store.state.investmentsState.subjectsState;
    public mediaAvailable = prospectMediaAvailable;
    public parseDate = parseDate;
}
