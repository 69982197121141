import {Land} from '@/modules/lands/shared';

export class InvestmentSubjectDataRequest {
    public id: number = 0;
    public name?: string = '';
    public code: string|null = '';
    // tslint:disable-next-line:variable-name
    public change_catalog_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_subject_type_id: number = 0;
    // tslint:disable-next-line:variable-name
    public public_status: string = '';
    // tslint:disable-next-line:variable-name
    public private_status: string = '';
    // tslint:disable-next-line:variable-name
    public show_price_per_meter: boolean = false;
    // tslint:disable-next-line:variable-name
    public show_price: boolean = false;
    // tslint:disable-next-line:variable-name
    public price: number = 0;
    // tslint:disable-next-line:variable-name
    public description: string = '';
    // tslint:disable-next-line:variable-name
    public investment_object_id: number = 0;
    // tslint:disable-next-line:variable-name
    public land_id: number = 0;
    public land!: Land;

    public address: string = '';
    // tslint:disable-next-line:variable-name
    public location_of_apartment_in_building: string = '';
    // tslint:disable-next-line:variable-name
    public determination_of_position_standard: string = '';

    // // tslint:disable-next-line:variable-name
    // public way_of_subject_measurement: string = '';

    // tslint:disable-next-line:variable-name
    public trust_account_number: string = '';
    // tslint:disable-next-line:variable-name
    public transfer_of_ownership_date: string = '';

    // tslint:disable-next-line:variable-name
    public self_reliance_certificate_issue_date: string = '';
    // tslint:disable-next-line:variable-name
    public separate_ownership_establishment_date: string = '';
    // tslint:disable-next-line:variable-name
    public are_business_premises_bought_along: boolean = false;
    // tslint:disable-next-line:variable-name
    public business_premises_info: string = '';
    // tslint:disable-next-line:variable-name
    public business_premises_or_its_fraction_price: string = '';
    // tslint:disable-next-line:variable-name
    public final_transfer_of_business_premises_ownership_date: string = '';


    // tslint:disable-next-line:variable-name
    public number_of_storeys: string = '';
    // tslint:disable-next-line:variable-name
    public manufacturing_technology: string = '';
    // tslint:disable-next-line:variable-name
    public finishing_standard: string = '';
    // tslint:disable-next-line:variable-name
    public parking_spots_count: string = '';
    // tslint:disable-next-line:variable-name
    public flats_count: string = '';

    // tslint:disable-next-line:variable-name
    public assigned_user_id: number = 0;

    // tslint:disable-next-line:variable-name
    public reserving_client_id?: number = 0;
    // tslint:disable-next-line:variable-name
    public reservation_start_date?: string = '';
    // tslint:disable-next-line:variable-name
    public reservation_end_date?: string = '';
}
