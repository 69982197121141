
import GeneratorList from '@/modules/investments/components/generator/components/list-component';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import SubjectForm from '@/modules/investments/components/generator/components/subjects/form.vue';
import {Component} from 'vue-property-decorator';
import {HeaderFieldType} from '@/shared/types';
import {InvestmentSubjectGenerated} from '@/modules/investments/shared/models/investment-subject';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        ListToolbar,
        SubjectForm,
    },
    props: {
        hasDuplications: Boolean,
        items: Array,
        level: Number,
        value: Object,
        subjects: Array,
    },
})
export default class InvestmentGeneratorSubjectsList extends GeneratorList<InvestmentSubjectGenerated> {
    public subjects!: InvestmentSubjectGenerated[];
    public subjectTemplate = {
        properties: [],
        rooms: [],
        media: [],
    };

    public subjectHeaders: HeaderFieldType[] = [
        {
            text: 'Nazwa',
            align: 'start',
            value: 'name',
        },
        {
            text: 'Akcje',
            align: 'end',
            value: 'actions',
        },
    ];

    public editItem(item: InvestmentSubjectGenerated) {
        const editedObject = this.subjects.find((object: InvestmentSubjectGenerated) => item.id === object.id);
        callSuper(this, 'editItem', editedObject);
    }

    public rowClasses(item: InvestmentSubjectGenerated): string[] {
        if (item.id === this.selectedSubject.id) {
            return ['table-row-selected'];
        }

        if (item.duplicated) {
            return ['table-row-duplicated'];
        }

        return [];
    }

    public openItem(item: InvestmentSubjectGenerated) {
        if (!item.duplicated) {
            this.selectedSubject = item;
        }
    }


    get selectedSubject() {
        return this.value;
    }

    set selectedSubject(value) {
        this.$emit('input', value);
    }
}

