
    import Component from 'vue-class-component';
    import TitleCard from './title-card.vue';
    import DatePicker from '@/shared/components/elements/date-picker.vue';
    import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
    import DocumentEditor from '@/modules/documents/components/editors/document-editor.vue';
    import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
    import ProspectStepComponent from './prospect-step-component';
    import { parseDate } from '@/shared/helpers';

    @Component({
        components: {
            ActionConfirmDialog,
            DocumentEditor,
            Wysiwyg,
            DatePicker,
            TitleCard,
        },
    })
    export default class ProspectFormInvestmentData extends ProspectStepComponent {
        public noLandInfoDialog: boolean = false;
        public readMore: boolean = false;
        public parseDate = parseDate;

        public legalActList = [
            'Studium uwarunkowań i kierunków zagospodarowania przestrzennego gminy',
            'Miejscowy plan zagospodarowania przestrzennego',
            'Miejscowy plan rewitalizacji',
            'Miejscowy plan odbudowy',
            'Inne*',
        ];

        public ownedMeans = ['Kredyt', 'Środki własne', 'Inne'];

        // public readableDocuments = [
        //     'aktualnym stanem księgi wieczystej prowadzonej dla nieruchomości',
        //     'kopią aktualnego odpisu z Krajowego Rejestru Sądowego, jeśli podmiot
        // podlega wpisowi do Krajowego Rejestru Sądowego lub aktualnym zaświadczeniem o
        // wpisie do Centralnej Ewidencji i Informacji o Działalności Gospodarczej',
        //     'kopią pozwolenia na budowę',
        //     'projektem budowlanym',
        // ];

        public readableDocuments = [
            'aktualnym stanem księgi wieczystej prowadzonej dla nieruchomości',
            'aktualnym odpisem, wyciągiem, zaświadczeniem lub wydrukiem komputerowym z Centralnej Informacji Krajowego Rejestru Sądowego, jeżeli podmiot podlega wpisowi do Krajowego Rejestru Sądowego, albo aktualnym zaświadczeniem o wpisie do Centralnej Ewidencji i Informacji o Działalności Gospodarczej',
            'pozwoleniem na budowę albo zgłoszeniem budowy, o którym mowa w art. 29 ust. 1 pkt 1 ustawy z dnia 7 lipca 1994 r. – Prawo budowlane, do którego organ administracji architektoniczno-budowlanej nie wniósł sprzeciwu',
            'sprawozdaniem finansowym dewelopera za ostatnie dwa lata, a w przypadku prowadzenia działalności przez okres krótszy niż dwa lata – sprawozdaniem finansowym za okres ostatniego roku i w przypadku realizacji inwestycji przez spółkę celową – sprawozdaniem spółki dominującej oraz spółki celowej',
            'projektem budowlanym',
            'decyzją o pozwoleniu na użytkowanie budynku lub zawiadomieniem o zakończeniu budowy, do któregoorgan nadzoru budowlanego nie wniósł sprzeciwu',
            'zaświadczeniem o samodzielności lokalu',
            'aktem ustanowienia odrębnej własności lokalu',
            'dokumentem potwierdzającym zgodę banku lub innego wierzyciela hipotecznego na bezobciążeniowe ustanowienie odrębnej własności lokalu mieszkalnego i przeniesienie jego własności na nabywcę po wpłacie pełnej ceny przez nabywcę lub zobowiązanie do jej udzielenia, jeżeli takie obciążenie istnieje, albo zgodę banku lub innego wierzyciela hipotecznego na bezobciążeniowe przeniesienie na nabywcę własności nieruchomości wraz z domem jednorodzinnym lub użytkowania wieczystego nieruchomości gruntowej i własności domu jednorodzinnego stanowiącego odrębną nieruchomość, lub przeniesienie ułamkowej części własności nieruchomości wraz z prawem do wyłącznego korzystania z części nieruchomości służącej zaspokajaniu potrzeb mieszkaniowych po wpłacie pełnej ceny przez nabywcę lub zobowiązanie do jej udzielenia, jeżeli takie obciążenie istnieje, a w przypadku umów, o których mowa w art. 2 ust. 2 ustawy z dnia 20 maja 2021 r. o ochronie praw nabywcy lokalu mieszkalnego lub domu jednorodzinnego oraz Deweloperskim Funduszu Gwarancyjnym, zgodę banku lub innego wierzyciela hipotecznego na bezobciążeniowe przeniesienie własności lokalu użytkowego na nabywcę po wpłacie pełnej ceny przez nabywcę lub zobowiązanie do udzielenia takiej zgody, jeżeli takie obciążenie istnieje, albo zgodę banku lub innego wierzyciela hipotecznego na bezobciążeniowe przeniesienie na nabywcę ułamkowej części własności lokalu użytkowego po wpłacie pełnej ceny przez nabywcę lub zobowiązanie do udzielenia takiej zgody, jeżeli takie obciążenie istnieje',
        ];

        public mounted() {
            this.noLandInfoDialog = !this.formData.land_id;
        }
    }
