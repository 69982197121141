
import Component from 'vue-class-component';
import BlockSprite from '@/modules/investments/components/generator/templates/blocks/block.vue';
import {Prop, Vue, Watch} from 'vue-property-decorator';
import BlockInvestmentStageForm from '@/modules/investments/components/generator/templates/blocks/stage.vue';
import {SubjectController} from '@/modules/investments/components/generator/controllers/SubjectController';
import {ObjectController} from '@/modules/investments/components/generator/controllers/ObjectController';
import {InvestmentObjectGenerated} from '@/modules/investments/shared/models/investment-object';
import {
    findLastId,
    getChildOrChildWithChildren,
    getObjectOrObjectWithSubjects,
    getRootsObjects,
    getSubjectOrSubjectWithRooms,
    getTreeViewObject,
} from '@/modules/investments/components/generator/helpers';
import BlockInvestmentLevelForm from '@/modules/investments/components/generator/templates/blocks/level.vue';
import BlockInvestmentBuildingForm from '@/modules/investments/components/generator/templates/blocks/building.vue';
import BlockInvestmentSubjectForm from '@/modules/investments/components/generator/templates/blocks/subject.vue';
import {InvestmentSubjectRoomGenerated} from '@/modules/investments/shared/models/subjects/investment-subject-room';
import {
    BlockInvestmentBuilding,
    BlockInvestmentLevel,
    BlockInvestmentStage,
} from '@/modules/investments/components/generator/templates/blocks/models/types';
import {InvestmentSubjectGenerated} from '@/modules/investments/shared/models/investment-subject';
import {InvestmentSubjectType} from '@/modules/investments/shared/models/subjects/investment-subject-type';
import {InvestmentGenerated} from '@/modules/investments/shared/models/investment';
import {investmentsModule} from '@/modules/investments/shared/state/module';
import _ from 'lodash';

@Component({
    components: {
        BlockSprite,
        BlockInvestmentStageForm,
        BlockInvestmentLevelForm,
        BlockInvestmentBuildingForm,
        BlockInvestmentSubjectForm,
    },
})
export default class BlockTemplate extends Vue {
    @Prop(Object) public investmentData!: InvestmentGenerated;
    public editedObject: any = {
        name: 'Etap {{numer_kategorii}}',
        investment_object_type_id: 1,
        hasStages: true,
        icon: 'mdi-city',
    };
    public editedSubject: InvestmentSubjectGenerated = {} as InvestmentSubjectGenerated;
    public subjectsController = new SubjectController();
    public objectsController = new ObjectController(this.subjectsController);
    public duplicateObjectDialog: boolean = false;
    public duplicateSubjectDialog: boolean = false;
    public duplicateData: any = {};

    public currentStageId: number = 0;
    public currentLevelId: number = 0;
    public currentBuildingId: number = 0;
    public currentSubjectId: number = 0;

    public activeObject: number[] = [];
    public openObject: number[] = [];
    public objectEditing: boolean = false;
    public activeSubject: InvestmentSubjectGenerated[] | InvestmentSubjectRoomGenerated[] = [];
    public openSubject: InvestmentSubjectGenerated[] = [];
    public step = 1;
    public investmentHasStages = true;
    public checked = false;

    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';

    get subjectTypes() {
        return this.$store.state.investmentsState.subjectTypesState.data;
    }

    get subjectsHasDuplications() {
        return this.subjectsController.hasDuplications();
    }

    get objectsHasDuplications() {
        return this.objectsController.hasDuplications();
    }

    get currentBuildingHeight() {
        return this.currentBuilding.children
            ? Math.max(...this.currentBuilding.children.map(({level}) => Number(level)))
            : 0;
    }

    get objectsTree() {
        return getTreeViewObject(
            this.objectsController.getObjectsTable(),
            this.subjectsController.getSubjectTable(),
        );
    }

    get currentStage() {
        const currentStage = getRootsObjects(this.objectsController.getObjectsTable()).find(
            (e) => e.id === this.currentStageId,
        );
        const index = getRootsObjects(this.objectsController.getObjectsTable()).indexOf(currentStage);
        return currentStage
            ? getChildOrChildWithChildren(this.objectsController.getObjectsTable(), currentStage, index)
            : ({} as BlockInvestmentStage);
    }

    get currentLevel() {
        return (
            getObjectOrObjectWithSubjects(
                this.currentBuilding && this.currentBuilding.children ? this.currentBuilding.children : [],
                getSubjectOrSubjectWithRooms(this.subjectsController.getSubjectTable()),
            ).find((e) => e.id === this.currentLevelId) || ({} as BlockInvestmentLevel)
        );
    }

    get currentBuilding(): BlockInvestmentBuilding {
        if (this.investmentHasStages) {
            return this.currentStageId > 0 && this.currentBuildingId > 0
                ? this.currentStage.children.find((e: BlockInvestmentBuilding) => e.id === this.currentBuildingId)
                : ({} as BlockInvestmentBuilding);
        } else {
            const currentBuilding = getRootsObjects(this.objectsController.getObjectsTable()).find(
                (e) => e.id === this.currentBuildingId,
            );
            const index = getRootsObjects(this.objectsController.getObjectsTable()).indexOf(currentBuilding);
            return currentBuilding
                ? getChildOrChildWithChildren(this.objectsController.getObjectsTable(), currentBuilding, index)
                : ({} as BlockInvestmentBuilding);
        }
    }

    public addNewRootObject() {
        this.step = 1 + Number(!this.investmentHasStages);
        this.currentStageId = 0;
        this.currentLevelId = 0;
        this.currentBuildingId = 0;
        this.currentSubjectId = 0;
        this.activeObject = [];
        this.editedObject = {investment_object_type_id: 1 + Number(!this.investmentHasStages)};
    }

    public goBack() {
        this.$emit('back');
    }

    public openDuplicateSubjectDialog(id: number, duplications?: number) {
        this.duplicateSubjectDialog = true;
        this.duplicateData.id = Number(id);
        this.duplicateData.count = duplications ? Number(duplications) : duplications;
    }

    public openDuplicateObjectDialog(id: number, duplications?: number) {
        this.duplicateObjectDialog = true;
        this.duplicateData.id = Number(id);
        this.duplicateData.count = duplications ? Number(duplications) : duplications;
    }

    public duplicateObject() {
        this.duplicateObjectDialog = false;
        this.objectsController.duplicateObject(this.duplicateData.id, Number(this.duplicateData.count));
    }

    public removeObject(item: InvestmentObjectGenerated) {
        this.objectsController.dropObject(item.id);
        this.step = item.investment_object_type_id > 1 ? item.investment_object_type_id : 1;
        switch (item.investment_object_type_id) {
            case 1:
                this.currentStageId = 0;
                this.currentBuildingId = 0;
                this.currentLevelId = 0;
                break;
            case 2:
                if (this.investmentHasStages) {
                    this.activeObject = [this.currentStageId];
                }
                this.currentBuildingId = 0;
                this.currentLevelId = 0;
                break;
            case 3:
                this.activeObject = [this.currentBuildingId];
                this.currentBuildingId = item.parent_id || 0;
                this.currentLevelId = 0;
                if (item.subjects) {
                    this.activeSubject = [];
                }
                break;
        }
    }

    public cancelObjectEdit() {
        this.step = 5;
        this.activeObject = [];
        this.objectEditing = false;
    }

    public cancelSubjectEdit() {
        this.step = 5;
        this.setDefaultSubject();
        this.activeSubject = [];
    }

    public duplicateSubject() {
        this.duplicateSubjectDialog = false;
        this.subjectsController.duplicateSubject(this.duplicateData.id, Number(this.duplicateData.count));
    }

    public removeSubject(item: InvestmentSubjectGenerated) {
        this.subjectsController.dropSubject(item.id);
        if (this.editedSubject.id === item.id) {
            this.setDefaultSubject();
        }
    }

    public stageSaved() {
        if (!this.checked) {
            this.investmentHasStages = this.editedObject.hasStages || false;
            delete this.editedObject.hasStages;
        }
        if (this.investmentHasStages) {
            this.checked = true;
            this.objectsController.storeObject(this.editedObject);
            this.currentStageId = findLastId(this.objectsController.getObjectsTable());
            this.activeObject.push(this.currentStageId);
        }

        this.step++;
    }

    public buildingSaved() {
        this.objectsController.storeObject(this.editedObject, this.currentStageId);
        this.currentBuildingId = findLastId(this.objectsController.getObjectsTable());
        this.openObject.push(this.currentStageId);
        this.activeObject = [this.currentBuildingId];
        this.step++;
    }

    public levelSaved() {
        this.objectsController.storeObject(this.editedObject, this.currentBuildingId);
        this.currentLevelId = findLastId(this.objectsController.getObjectsTable());
        this.openObject.push(this.currentBuildingId);
        this.activeObject = [this.currentLevelId];
        this.step++;
    }

    public editedObjectSaved() {
        this.objectsController.updateObject(this.editedObject);
        this.step = 5;
        this.activeObject = [];
        this.objectEditing = false;
    }

    public submitTemplate() {
        const investmentTree = {
            objects: this.objectsController.getObjectsTable(),
            subjects: this.subjectsController.getSubjectTable(),
        };
        this.$emit('submit', investmentTree);
    }

    public subjectSaved() {
        if (!this.editedSubject.id) {
            if (this.editedSubject.code === '{{automatyczny}}') {
                this.generateSubjectCode();
            }
            this.currentSubjectId = findLastId(this.subjectsController.getSubjectTable()) + 1;
            this.subjectsController.storeSubject(this.editedSubject, this.currentLevelId);
        } else {
            this.subjectsController.updateSubject(this.editedSubject);
            this.currentSubjectId = this.editedSubject.id;
            this.activeSubject = [];
        }
        this.setDefaultSubject();

        this.step = 5;
    }

    public generateSubjectCode() {
        this.editedSubject.code = this.investmentData.name.slice(0, 3);
        this.editedSubject.code += '{{id_inwestycji}}';

        if (this.investmentHasStages) {
            this.editedSubject.code += '/' + this.currentStage.name.slice(0, 3) + this.currentStage.id;
            this.editedSubject.code +=
                '/' +
                this.currentBuilding.name.slice(0, 3) +
                (this.currentStage.children.findIndex(
                        (item: BlockInvestmentBuilding) => item.id === this.currentBuildingId,
                    ) +
                    1);
        } else {
            this.editedSubject.code +=
                '/' +
                this.currentBuilding.name.slice(0, 3) +
                (this.objectsTree.findIndex((item) => item.id === this.currentBuildingId) + 1);
        }

        this.editedSubject.code += '/' + this.currentLevel.name.slice(0, 3) + this.currentLevel.level;
        const subjectType = this.subjectTypes.find(
            (e: InvestmentSubjectType) => this.editedSubject.investment_subject_type_id === e.id,
        );

        this.editedSubject.code += '/' + (subjectType ? subjectType.name.slice(0, 1) : '');
        this.editedSubject.code += '{{numer_przedmiotu}}';
        this.editedSubject.code = this.editedSubject.code.toLowerCase();

    }

    public setDefaultSubject() {
        this.editedSubject = {
            id: 0,
            price: 0,
            volume: 0,
            name: '',
            description: '',
            public_status: 'hidden',
            private_status: 'free',
            code: '{{automatyczny}}',
            investment_subject_type_id: this.currentLevel.level && this.currentLevel.level < 0 ? 6 : 4,
            rooms: [],
            properties: [],
            media: [],
        } as InvestmentSubjectGenerated;
    }

    @Watch('step', {immediate: true})
    public onStepChange(val: number) {
        if (!this.objectEditing && (val && val < 5)) {
            switch (val) {
                case 1:
                    this.editedObject = {investment_object_type_id: 1, icon: 'mdi-city'};
                    this.$set(this.editedObject, 'name', 'Etap {{numer_kategorii}}');
                    break;
                case 2:
                    this.editedObject = {investment_object_type_id: 2, icon: 'mdi-office-building'};
                    this.$set(this.editedObject, 'lowestLevel', -1);
                    this.$set(this.editedObject, 'name', 'Budynek {{numer_kategorii}}');
                    break;
                case 3:
                    this.editedObject = {investment_object_type_id: 3, icon: 'mdi-stairs'};
                    this.$set(this.editedObject, 'name', 'Poziom {{numer_poziomu}}');
                    break;
                case 4:
                    if (!this.editedSubject.id) {
                        this.setDefaultSubject();
                    }
                    break;
            }
        }
    }

    @Watch('activeSubject')
    public onActiveSubjectChange(val: InvestmentSubjectGenerated[], oldVal: InvestmentSubjectGenerated[]) {
        if (val && val.length > 0 && !this.duplicateSubjectDialog) {
            const subject = this.subjectsController
                .getSubjectTable()
                .find((item: InvestmentSubjectGenerated) => val[0].id === item.id);
            this.editedSubject = JSON.parse(JSON.stringify(subject));
            this.step = 4;
        } else {
            if (
                !val[0] &&
                !(_.isEqual(oldVal[0], val[0])) &&
                this.subjectsController
                    .getSubjectTable()
                    .find((item: InvestmentSubjectGenerated) => oldVal[0].id === item.id) &&
                this.step === 4
            ) {
                this.activeSubject = [oldVal[0]];
            }
        }
    }

    @Watch('activeObject')
    public onActiveObjectChange(val: number[], [oldVal]: number[]) {
        if (val && val.length > 0) {
            const object = this.objectsController.getObjectsTable().find(({id}) => id === val[0]);
            if (object && !this._.isEmpty(object)) {
                if (!this.objectEditing) {
                    switch (object.investment_object_type_id) {
                        case 1:
                            this.currentStageId = object.id;
                            this.openObject = [this.currentStageId];
                            this.currentBuildingId = 0;
                            this.currentSubjectId = 0;
                            this.currentLevelId = 0;
                            break;
                        case 2:
                            this.currentBuildingId = object.id;
                            this.currentStageId = object.parent_id || 0;
                            this.openObject = [this.currentStageId, this.currentBuildingId];
                            this.currentSubjectId = 0;
                            this.currentLevelId = 0;
                            break;
                        case 3:
                            this.currentLevelId = object.id;
                            this.currentSubjectId = 0;
                            this.currentBuildingId = object.parent_id || 0;
                            this.setDefaultSubject();
                            break;
                    }
                    this.step = object.investment_object_type_id + 1;
                    this.activeSubject = [];
                } else {
                    switch (object.investment_object_type_id) {
                        case 1:
                            this.currentStageId = object.id;
                            this.openObject = [this.currentStageId];
                            this.currentBuildingId = 0;
                            this.currentSubjectId = 0;
                            this.currentLevelId = 0;
                            break;
                        case 2:
                            this.currentBuildingId = object.id;
                            this.currentStageId = object.parent_id || 0;
                            this.openObject = [this.currentStageId, this.currentBuildingId];
                            this.currentLevelId = 0;
                            this.currentSubjectId = 0;
                            break;
                        case 3:
                            this.currentLevelId = object.id;
                            this.currentSubjectId = 0;
                            this.currentBuildingId = object.parent_id || 0;
                            break;
                    }
                    this.editedObject = object;
                    this.step = object.investment_object_type_id;
                }
            }
        } else {
            if (
                oldVal &&
                this.objectsController
                    .getObjectsTable()
                    .find((item: InvestmentObjectGenerated) => oldVal === item.id) &&
                Number(this.step) > 1 + Number(!this.investmentHasStages)
            ) {
                this.activeObject = [oldVal];
            }
        }
    }
}
