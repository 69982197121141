import {Land} from '@/modules/lands/shared';
import {MediaFile} from '@/modules/media/shared/models/media-file';

export class InvestmentObjectDataRequest {
    public id: number = 0;
    public name?: string = '';
    // tslint:disable-next-line:variable-name
    public parent_id?: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_id?: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_object_type_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_object_type_icon_id: number = 0;
    // tslint:disable-next-line:variable-name
    public land_id: number = 0;
    public land?: Land;
    public description?: string = '';
    public thumbnail?: MediaFile;
    // tslint:disable-next-line:variable-name
    public thumbnail_id: number = 0;
}

