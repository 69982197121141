import {DataRequest} from '@/shared/types';

export class InvestmentSubjectReservationDataRequest extends DataRequest {
    // tslint:disable-next-line:variable-name
    public investment_subject_id: number = 0;
    // tslint:disable-next-line:variable-name
    public reserving_client_id: number = 0;
    // tslint:disable-next-line:variable-name
    public reservation_start_date: string = '';
    // tslint:disable-next-line:variable-name
    public reservation_end_date: string = '';

}
