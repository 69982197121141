import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';
import {ProspectSetting} from '@/modules/investments/shared/models/prospect-setting';
import {ProspectSettingDataRequest} from '@/modules/investments/shared/requests/prospect-setting-data-request';
import {DataRequest} from '@/shared/types';

export class ProspectDataRequest extends DataRequest {
    // tslint:disable-next-line:variable-name
    public investment_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_subject_id: number = 0;
    // tslint:disable-next-line:variable-name
    public land_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_objects?: InvestmentObject[];

    // Step 1

    // tslint:disable-next-line:variable-name
    public company_name?: string;
    public address?: string;
    public phone?: string;
    public fax?: string;
    public nip?: string;
    public email?: string;
    public regon?: string;
    // tslint:disable-next-line:variable-name
    public website_address?: string;

    // Step 2

    // tslint:disable-next-line:variable-name
    public completed_investment_address?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public completed_investment_start_date?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public completed_investment_end_date?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public another_completed_investment_address?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public another_completed_investment_start_date?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public another_completed_investment_end_date?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public last_completed_investment_address?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public last_completed_investment_start_date?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public last_completed_investment_end_date?:
        ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public is_enforcement_proceedings?: ProspectSettingDataRequest<boolean> = {} as ProspectSettingDataRequest<boolean>;

    // Step 3

    // tslint:disable-next-line:variable-name
    public plot_address?: string = '';
    // tslint:disable-next-line:variable-name
    public land_register_info: string = '';
    // tslint:disable-next-line:variable-name
    public existing_mortgage_load?: ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;
    // tslint:disable-next-line:variable-name
    public law_status: string = '';
    // tslint:disable-next-line:variable-name
    public has_no_plan: boolean = false;

    // tslint:disable-next-line:variable-name
    // public bank_consent_information?: ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;

    // tslint:disable-next-line:variable-name
    public plan_purpose?: string = '';
    // tslint:disable-next-line:variable-name
    public acceptable_building_height?: string = '';
    // tslint:disable-next-line:variable-name
    public acceptable_building_percentage?: string = '';
    // tslint:disable-next-line:variable-name
    public planned_investments: string = '';
    // tslint:disable-next-line:variable-name
    public authorization_info: string = '';
    // tslint:disable-next-line:variable-name
    public build_approval?: boolean = false;
    // tslint:disable-next-line:variable-name
    public is_build_approval_final?: boolean = false;
    // tslint:disable-next-line:variable-name
    public is_build_approval_contested?: boolean = false;
    // tslint:disable-next-line:variable-name
    public planned_build_dates?: string = '';
    // tslint:disable-next-line:variable-name
    public transfer_of_ownership_date?: string = '';
    // tslint:disable-next-line:variable-name
    public number_of_buildings: string = '';
    // tslint:disable-next-line:variable-name
    public arrangement_of_buildings: string = '';
    // tslint:disable-next-line:variable-name
    public way_of_subject_measurement: string = '';

    // tslint:disable-next-line:variable-name
    // public owned_means_percentage_share: string = '';


    // tslint:disable-next-line:variable-name
    public owned_means: string = '';


    // tslint:disable-next-line:variable-name
    // public financial_means_type: string = '';

    // tslint:disable-next-line:variable-name
    // public credit_institution: string = '';


    // tslint:disable-next-line:variable-name
    public owned_means_institutions: string = '';
    // tslint:disable-next-line:variable-name
    public is_bank_bill_open?: boolean = false;
    // tslint:disable-next-line:variable-name
    public financial_institution_guarantee: string = '';
    // tslint:disable-next-line:variable-name
    public cash_safety_rules: string = '';
    // tslint:disable-next-line:variable-name
    public institution_of_cash_safety: string = '';
    // tslint:disable-next-line:variable-name
    public investment_schedule: string = '';
    // tslint:disable-next-line:variable-name
    public allowing_of_valorization: boolean = false;
    // tslint:disable-next-line:variable-name
    public principles_of_valorization: string = '';
    // tslint:disable-next-line:variable-name
    public terms_of_withdrawal_from_the_developer_contract: string = '';
    // tslint:disable-next-line:variable-name
    public readable_documents?: ProspectSettingDataRequest<string[]> =
    {} as ProspectSettingDataRequest<string[]>;
    // tslint:disable-next-line:variable-name
    public readable_documents_description?: ProspectSettingDataRequest<string> =
    {} as ProspectSettingDataRequest<string>;

    // tslint:disable-next-line:variable-name
    public escrow_account_bank_name: string = '';

    // tslint:disable-next-line:variable-name
    public escrow_bank_trademark: string = '';


    // tslint:disable-next-line:variable-name
    public specific_bank_consent_information: string = '';
    // tslint:disable-next-line:variable-name
    public bank_consent_information: string = '';


    // Step 4

    // tslint:disable-next-line:variable-name
    public media_available: string[] = [];
    // tslint:disable-next-line:variable-name
    public location_of_apartment_in_building: string = '';
    // tslint:disable-next-line:variable-name
    public determination_of_position_standard: string = '';
    // tslint:disable-next-line:variable-name
    public price_per_meter?: string = '';
    // tslint:disable-next-line:variable-name
    public number_of_storeys: string = '';
    // tslint:disable-next-line:variable-name
    public manufacturing_technology?: string = '';

    // tslint:disable-next-line:variable-name
    // public standard_of_area_finishing: string = '';
    // tslint:disable-next-line:variable-name
    // public count_of_stop_subjects: string = '';

    // tslint:disable-next-line:variable-name
    // public count_of_flats?: ProspectSettingDataRequest<string> = {} as ProspectSettingDataRequest<string>;

    // tslint:disable-next-line:variable-name
    public public_road_access?: boolean = false;
    public attachments: ProspectSettingDataRequest<string[]> = {} as ProspectSettingDataRequest<string[]>;




    // tslint:disable-next-line:variable-name
    public building_law_notification_number: string = '';
    // tslint:disable-next-line:variable-name
    public guarantee_fund_due_percentage: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_objects_details: string = '';
    // tslint:disable-next-line:variable-name
    public selected_legal_act: string = '';
    // tslint:disable-next-line:variable-name
    public selected_legal_act_details: string = '';

    // tslint:disable-next-line:variable-name
    public area_use: string = '';
    // tslint:disable-next-line:variable-name
    public max_and_min_buildings_intensity: string = '';
    // tslint:disable-next-line:variable-name
    public max_buildings_height: string = '';
    // tslint:disable-next-line:variable-name
    public min_bioactive_area_percentage: string = '';
    // tslint:disable-next-line:variable-name
    public min_parking_spots_count: string = '';
    // tslint:disable-next-line:variable-name
    public protection_conditions: string = '';
    // tslint:disable-next-line:variable-name
    public flood_threat_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public culture_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public other_areas_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public communication_requirements_and_rules: string = '';
    // tslint:disable-next-line:variable-name
    public technical_infrastructure_requirements_and_rules: string = '';

    // tslint:disable-next-line:variable-name
    public neighbourhood_area_use: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_max_and_min_buildings_intensity: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_max_buildings_height: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_min_bioactive_area_percentage: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_min_parking_spots_count: string = '';

    // tslint:disable-next-line:variable-name
    public buildings_and_land_development_function: string = '';
    // tslint:disable-next-line:variable-name
    public method_of_buildings_and_land_development_use: string = '';

    // tslint:disable-next-line:variable-name
    public dimensions: string = '';
    // tslint:disable-next-line:variable-name
    public architectural_form: string = '';
    // tslint:disable-next-line:variable-name
    public building_line_location: string = '';
    // tslint:disable-next-line:variable-name
    public area_use_intensity: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_protection_conditions: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_flood_threat_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_culture_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_other_areas_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_communication_requirements_and_rules: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_technical_infrastructure_requirements_and_rules: string = '';

    // tslint:disable-next-line:variable-name
    public provided_investments_plan_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_commune_plan_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_conditions_decision_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_environmental_conditions_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_restricted_use_act_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_local_reconstruction_plan_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_flood_threat_maps_info: string = '';

    // tslint:disable-next-line:variable-name
    public public_road_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_railway_line_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_airport_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_antiflood_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_nuclear_object_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_transmission_network_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_regional_broadband_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public cpk_dependent_investment_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_access_infrastructure_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_oil_industry_location_decision: string = '';


    // tslint:disable-next-line:variable-name
    public build_approval_due_date: string = '';
    // tslint:disable-next-line:variable-name
    public end_date: string = '';

    // tslint:disable-next-line:variable-name
    public self_reliance_certificate_issue_date: string = '';
    // tslint:disable-next-line:variable-name
    public separate_ownership_establishment_date: string = '';
    // tslint:disable-next-line:variable-name
    public are_business_premises_bought_along: boolean = false;
    // tslint:disable-next-line:variable-name
    public business_premises_info: string = '';
    // tslint:disable-next-line:variable-name
    public business_premises_or_its_fraction_price: string = '';
    // tslint:disable-next-line:variable-name
    public final_transfer_of_business_premises_ownership_date: string = '';


    // tslint:disable-next-line:variable-name
    public price: number = 0;
    // tslint:disable-next-line:variable-name
    public area: number = 0;

    // tslint:disable-next-line:variable-name
    public flats_count: string = '';

    // tslint:disable-next-line:variable-name
    public parking_spots_count: string = '';

    // tslint:disable-next-line:variable-name
    public finishing_standard: string = '';



}


