
import FormComponent from '@/shared/components/layout/form/form-component';
import Component from 'vue-class-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
import { logger } from '@/shared/services';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { InvestmentObject } from '@/modules/investments/shared/models/investment-object';
import { InvestmentSubjectCopyOperationDataRequest } from '@/modules/investments/shared/requests/subjects/operations/investment-subject-copy-operation-data-request';
import Vue from 'vue';

import PreventUnload from '@/shared/PreventUnload.vue';
import { subjectCodeVariables } from '@/shared/config/investments';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import { subjectCodeRules } from '../../shared/helpers/subjects';

@Component({
        components: {
            FormActions,
            PreventUnload,
            VariableTextField,
        },
    })
    export default class CopySubjectsForm extends FormComponent<InvestmentSubjectCopyOperationDataRequest> {
        public store = this.$store.state.investmentsState.subjectOperationsState;
        public nameInput: any = null;
        public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
        public objectError = false;
        public openObject: InvestmentObject[] = [];
        public storeAction: string = '';
        public updateAction: string = '';
        public requestData = new InvestmentSubjectCopyOperationDataRequest();
        public subjectCodeVariables = subjectCodeVariables;
        public subjectCodeRules = subjectCodeRules;

        get investments() {
            return this.$store.state.investmentsState.dataList;
        }

        get objects() {
            return this.$store.state.investmentsState.objectsState.dataList;
        }

        get formTitle() {
            return 'Skopiuj przedmioty';
        }

        public created() {
            //
        }

        public updateObject(e: InvestmentObject[]) {
            this.itemData.investment_object_id = e[0];
            if (e && e[0] && !this.openObject.includes(e[0]) && e[0].children.length > 0) {
                this.openObject.push(e[0]);
            }
            this.$forceUpdate();
        }

        public initForm() {
            this.form = this.$refs.moveSubjectsForm;
            this.nameInput = this.$refs.nameInput as Vue;
            this.nameInput = this.nameInput.$el.querySelector('input');
        }

        public async submitForm(e: any, next?: boolean) {
            if (!this.form || this.busy) {
                return;
            }

            const item = JSON.parse(JSON.stringify(this.itemData));

            item.investment_object_id = item.investment_object_id.id;
            item.selection = item.selection.map((subject: InvestmentSubject) => subject.id);

            await this.form.validate();

            if (this.valid) {
                this.busy = true;

                this.$store
                    .dispatch(this.actionsTypes.COPY_SUBJECTS_CHECK, item)
                    .then((response) => {
                        if (response && response === 200) {
                            this.valid = true;
                            this.$router.push({ name: 'copy-subjects-summary' });
                        }
                    })
                    .catch((err) => logger.error(err))
                    .then(() => {
                        this.busy = false;
                    });
            }
        }

        public putInName(text: string) {
            if (!this.itemData.name) {
                return text;
            }
            return (
                this.itemData.name.slice(0, this.nameInput.selectionStart) +
                text +
                this.itemData.name.slice(
                    this.nameInput.selectionStart === this.nameInput.selectionEnd
                        ? this.nameInput.selectionStart
                        : this.nameInput.selectionEnd,
                )
            );
        }
    }
