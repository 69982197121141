
    import { Component } from 'vue-property-decorator';
    import FormComponent from '@/shared/components/layout/form/form-component';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import { prospectSettingsModule } from '../../../shared/state/submodules/subjects';
    import { ProspectSettingDataRequest } from '@/modules/investments/shared/requests/prospect-setting-data-request';
    import { prospectSettingsNames } from '@/shared/config/investments';
    import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
    import DatePicker from '@/shared/components/elements/date-picker.vue';
    import { callSuper } from '@/shared/helpers';

    @Component({
        components: {
            FormActions,
            DatePicker,
            Wysiwyg,
        },
    })
    export default class ProspectSettingsForm extends FormComponent<ProspectSettingDataRequest<any>> {
        public actionsTypes = prospectSettingsModule.actionsTypes;
        public store = this.$store.state.investmentsState.prospectSettingsState;
        public storeAction = this.actionsTypes.STORE_ITEM;
        public updateAction = this.actionsTypes.UPDATE_ITEM;
        public requestData = new ProspectSettingDataRequest<any>();

        public prospectSettingsNames = prospectSettingsNames;

        public created() {
            //
        }

        get formTitle(): string {
            return this.edit ? 'Edytuj pole prospektu' : 'Dodaj pole prospektu';
        }

        public initForm() {
            this.form = this.$refs.createProspectSettingForm;
        }
    }
