import {InvestmentSubjectGenerated} from '../../../shared/models/investment-subject';
import {findLastId} from '../helpers';
import {CRUD} from './CRUD';

export class SubjectController {
    private itemsCRUD!: CRUD<InvestmentSubjectGenerated>;

    constructor(private subjectsTable: InvestmentSubjectGenerated[] = []) {
        this.itemsCRUD = new CRUD<InvestmentSubjectGenerated>(this.subjectsTable);
    }

    public getSubjectTable() {
        return this.subjectsTable;
    }

    public storeSubject(data: InvestmentSubjectGenerated, objectId?: number) {
        const currentId = findLastId(this.subjectsTable) + 1;
        const newSubject: InvestmentSubjectGenerated = {
            ...data,
            id: currentId,
            investment_object_id: objectId ? objectId : data.investment_object_id,
        };

        this.subjectsTable.push(newSubject);

        return newSubject;
    }

    public updateSubject(data: InvestmentSubjectGenerated) {
        this.itemsCRUD.setTable(this.subjectsTable);

        const response = this.itemsCRUD.updateItem(data);

        this.subjectsTable = response.table;

        return response.item;
    }

    public showSubject(id: number) {
        this.itemsCRUD.setTable(this.subjectsTable);
        return this.itemsCRUD.showItem(id) || {} as InvestmentSubjectGenerated;
    }

    public dropSubject(id: number) {
        this.itemsCRUD.setTable(this.subjectsTable);
        this.subjectsTable = this.itemsCRUD.dropItem(id);
    }

    public copySubject(id: number, objectId: number, editData?: object) {
        this.itemsCRUD.setTable(this.subjectsTable);

        const editItem = {
            investment_object_id: objectId,
            ...editData,
        };

        const response = this.itemsCRUD.cloneItem(id, editItem);

        this.subjectsTable = response.table;

        return response.item;
    }

    public cloneSubject(id: number, editData?: object) {
        this.itemsCRUD.setTable(this.subjectsTable);

        const response = this.itemsCRUD.cloneItem(id, editData);

        this.subjectsTable = response.table;

        return response.item;
    }

    public duplicateSubject(id: number, duplications?: number) {
        this.itemsCRUD.setTable(this.subjectsTable);

        this.subjectsTable = this.itemsCRUD.duplicateItem(id, duplications);
    }

    public dropSubjectDuplications() {
        this.itemsCRUD.setTable(this.subjectsTable);

        this.subjectsTable = this.itemsCRUD.dropDuplications();
    }

    public acceptSubjectDuplications() {
        const subjectsTable = [...this.subjectsTable.filter(({duplications = 0}) => duplications && duplications > 0)];
        subjectsTable.forEach(({id = 0, duplications = 0}) => {
            for (let i = 0; i < duplications; i++) {
                this.cloneSubject(id, {duplications: undefined});
            }
            this.duplicateSubject(id, undefined);
        });
    }

    public duplicatedByObject(objectId: number, newObjectId: number, storey?: number) {
        const subjectRooms = this.subjectsTable.filter((item) => item.investment_object_id === objectId);
        subjectRooms.forEach(({id = 0}) => {
            const subject = this.copySubject(id, newObjectId, {duplications: undefined});
            if (storey !== null && subject.rooms && subject.rooms.length > 0) {
                const rooms = subject.rooms.map((room) => {
                    return {...room, storey};
                });
                this.updateSubject({id: subject.id, rooms} as InvestmentSubjectGenerated);
            }
        });
    }

    public objectHasSubjects(objectId: number) {
        return this.subjectsTable.filter(
            (item) => item.investment_object_id === objectId,
        ).length > 0;
    }

    public hasDuplications() {
        return this.subjectsTable.filter(({duplications = 0}) => duplications && duplications > 0).length > 0;
    }
}
