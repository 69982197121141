import {Component, Prop, Watch} from 'vue-property-decorator';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';
import Vue from 'vue';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {InvestmentObjectDataRequest} from '@/modules/investments/shared/requests/investment-object-data-request';
import {investmentObjectsModule} from '@/modules/investments/shared/state/submodules';
import {SaveResponse} from '@/shared/types';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import ThumbnailForm from '../thumbnail-form.vue';

@Component({
    components: {
        ThumbnailForm,
    },
})
export class ThemeComponent extends Vue {
    @Prop(Object) public thumbnail!: MediaFile | null;
    @Prop(Object) public subject!: InvestmentSubject;
    @Prop(Array) public objects!: InvestmentObject[];
    public rememberForObject = false;
    public objectFormData: InvestmentObjectDataRequest = {} as InvestmentObjectDataRequest;
    public objectsState = this.$store.state.investmentsState.objectsState;
    public objectActions = investmentObjectsModule.actionsTypes;
    public objectMutations = investmentObjectsModule.mutationsTypes;

    get thumbnailPhoto() {
        return this.thumbnail;
    }

    set thumbnailPhoto(value) {
        this.$emit('update:thumbnail', value);
    }

    get loadingObject() {
        return this.objectsState.loading;
    }

    get parentObjects() {
        return this.objects;
    }

    set parentObjects(value) {
        this.$emit('update:objects', value);
    }

    get editedObject() {
        return this.objectsState.editedItem;
    }

    set editedObject(value) {
        this.$store.commit(this.objectMutations.UPDATE_EDITED, value);
    }

    get objectMediaFileDialog() {
        return this.objectsState.formDialog;
    }

    set objectMediaFileDialog(value) {
        this.$store.commit(this.objectMutations.UPDATE_FORM_DIALOG, value);
    }

    get error(): string {
        return this.objectsState.error;
    }

    set error(value) {
        this.$store.commit(this.objectMutations.UPDATE_ERROR_TITLE, value);
    }

    get errorMessages(): object {
        return this.objectsState.errorMessages;
    }

    set errorMessages(value) {
        this.$store.commit(this.objectMutations.UPDATE_ERROR_MESSAGES, value);
    }

    public objectSaved({data}: SaveResponse<InvestmentObject>) {
        this.editedObject = {} as InvestmentObjectDataRequest;
        if (data) {
            const index = this.parentObjects.findIndex((e) => e.id === data.id);
            if (index !== -1) {
                this.parentObjects[index] = data;
                this.thumbnail = data.thumbnail;
            }
        }
        this.objectMediaFileDialog = false;
    }

    public openMediaFileDialog() {
        this.objectMediaFileDialog = true;
        this.editedObject = {} as InvestmentObjectDataRequest;
    }

    public cancelObjectForm() {
        this.error = '';
        this.errorMessages = {};
        this.editedObject = {} as InvestmentObjectDataRequest;
        this.objectMediaFileDialog = false;
    }

    public mounted() {
        this.changeThumbnail();
    }

    public changeThumbnail() {
        const object = this.parentObjects.find((e) => e.thumbnail);
        if (this.editedObject && this.editedObject.thumbnail) {
            this.thumbnail = this.editedObject.thumbnail;
        } else if (object) {
            this.thumbnail = object.thumbnail;
        } else {
            this.thumbnail = null;
        }
    }

    @Watch('subject')
    public onSubjectChange() {
        this.changeThumbnail();
    }
}
