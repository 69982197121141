
import {Component, Prop, Vue} from 'vue-property-decorator';
import { InvestmentObject } from '@/modules/investments/shared/models/investment-object';
import InvestmentObjectMedia from '@/modules/investments/components/objects/media/media.vue';
import ItemsTable from '@/shared/components/layout/list.vue';
import TotalCosts from '@/modules/investments/components/total-costs.vue';
import {costStatuses} from '@/shared/config/costs';
import {HeaderFieldType} from '@/shared/types';
import { investmentSubjectsModule } from '@/modules/investments/shared/state/submodules';

@Component({
    components: {
        ItemsTable,
        InvestmentObjectMedia,
        TotalCosts,
    }
})
export default class ObjectSimpleDetails extends Vue {
    @Prop(Object) public object!: InvestmentObject;
    public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));
    public actionsTypes = investmentSubjectsModule.actionsTypes;
    public mutationTypes = investmentSubjectsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;

    public headers: HeaderFieldType[] = [
        {text: 'ID', value: 'id'},
        {text: 'Nazwa', value: 'name'},
        {text: 'Typ', value: 'type'},
    ];
    public props = investmentSubjectsModule.moduleProps;
    public actionTypes = investmentSubjectsModule.actionsTypes;
    public store: any = this.$store.state.investmentsState.subjectsState;

    public created() {
        this.props.loadListOnCreate = true;
        //
    }
}
