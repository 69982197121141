
import { Component } from 'vue-property-decorator';
import { InvestmentObjectDataRequest } from '@/modules/investments/shared/requests/investment-object-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import { investmentObjectsModule, investmentObjectTypesModule } from '@/modules/investments/shared/state/submodules';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';

import ObjectTypeForm from '../settings/objects/form.vue';
import {InvestmentObjectType} from '@/modules/investments/shared/models/objects/investment-object-type';
import {Land, landModule} from '@/modules/lands/shared';
import ObjectMediaFileInput from '@/modules/investments/components/objects/helpers/object-media-file-input.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        ObjectMediaFileInput,
        FormActions,
        IconChooser,
        ObjectTypeForm,
        AutoCompleteWithAddNew,
    },
    props: {
        parent: Object,
    },
})
export default class InvestmentObjectForm extends FormComponent<InvestmentObjectDataRequest> {
    public store = this.$store.state.investmentsState.objectsState;
    public actionsTypes = investmentObjectsModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new InvestmentObjectDataRequest();

    // data required for add new type
    public objectTypesState = this.$store.state.investmentsState.objectTypesState;
    public investmentObjectTypesModule = investmentObjectTypesModule;

    public objectTypesForm = ObjectTypeForm;

    public searchLand = '';
    public landSearch: any = null;
    public landActions = landModule.actionsTypes;

    get typesLoading() {
        return this.objectTypesState.loading;
    }

    get landsLoading() {
        return this.$store.state.landsState.loading;
    }

    get lands() {
        return this.$store.state.landsState.data;
    }

    public searchLands(search: string) {
        clearTimeout(this.landSearch);

        this.landSearch = setTimeout(() => {
            if (!this._.isEqual(this.searchLand, search)) {
                this.searchLand = search;
                this.$store.dispatch(this.landActions.FETCH_DATA, { filters: { search } });
            }
        }, 500);
    }

    public landChanged() {
        this.clearError('land_id');
        if (this.itemData.land_id) {
            this.itemData.land = this.lands.find((e: Land) => (e.id === this.itemData.land_id));
            if (this.itemData.land) {
                this.searchLand = this.itemData.land.name;
            }
        } else {
            delete this.itemData.land;
            this.$store.dispatch(this.landActions.FETCH_DATA, { simple: false });
        }
    }

    get formTitle() {
        return this.edit
            ? `Edytuj kategorię ${this.itemData.parent_id && this.itemData.parent_id > 0 ? 'podrzędny' : ''} inwestycji`
            : `Dodaj kategorię ${this.itemData.parent_id && this.itemData.parent_id > 0 ? 'podrzędny' : ''} inwestycji`;
    }

    public created() {
        this.$store.dispatch(this.investmentObjectTypesModule.actionsTypes.FETCH_DATA, {
            simple: false,
        });
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        if (!this.itemData.name && this.itemData.investment_object_type_id) {
            this.itemData.name = this.objectTypesState.data.find((objectType: InvestmentObjectType) => {
                return objectType.id === this.itemData.investment_object_type_id;
            }).name;
        }

        if (this.itemData.thumbnail) {
            this.$set(this.itemData, 'thumbnail_id', this.itemData.thumbnail.id);
        } else {
            this.$set(this.itemData, 'thumbnail_id', null);
        }

        if (!this.itemData.parent_id) {
            this.$delete(this.itemData, 'parent_id');
        }
        return callSuper(this, 'submitForm', e, next);
    }

    public initForm() {
        this.initFocus();
        this.form = this.$refs.createInvestmentObjectForm;
         }

    public initFocus() {
        if (this.edit && this.itemData.land) {
            this.searchLand = this.itemData.land.name;
        }
    }

    public changeName(value: any) {
        if (value) {
            value = value.name;
            if (
                !this.itemData.name ||
                (!this.edit &&
                    this.objectTypesState.data.map((e: InvestmentObjectType) => e.name).includes(this.itemData.name))
            ) {
                this.$set(this.itemData, 'name', value);
            }
        }
    }

    public changeInvestmentObjectTypeId(value: any) {
        if (value) {
            value = value.id;
            this.itemData.investment_object_type_id = value;
        }
    }
}
