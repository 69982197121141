
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {prospectSettingsModule} from '../../../shared/state/submodules/subjects';
import SubjectRoomTypeForm from './form.vue';
import {ProspectSetting} from '@/modules/investments/shared/models/prospect-setting';
import {ProspectSettingDataRequest} from '@/modules/investments/shared/requests/prospect-setting-data-request';
import {prospectSettingsHeaders} from '@/modules/investments/shared/config';
import {prospectSettingsNames} from '@/shared/config/investments';
import {ToolbarProps} from '@/shared/models/toolbar-props';

@Component({
    components: {
        ItemsTable,
        SubjectRoomTypeForm,
    },
})
export default class RoomsTypesSetting extends ListComponent<ProspectSetting,
    ProspectSettingDataRequest<any>> {
    public headers = prospectSettingsHeaders;
    public actionsTypes = prospectSettingsModule.actionsTypes;
    public mutationTypes = prospectSettingsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.investmentsState.prospectSettingsState;
    public props: ModuleProps = prospectSettingsModule.moduleProps;
    public prospectSettingsNames = prospectSettingsNames;

    public toolbarProps: ToolbarProps = new ToolbarProps();

    public mounted() {
        this.toolbarProps.addButton = false;
    }
}
