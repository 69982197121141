
import {Component, Prop, Vue} from 'vue-property-decorator';
import {TicketPriority, ticketsModule} from '@/modules/tickets/shared';

import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';

@Component({
  components: {
    IconContrast,
    TextContrast,
  },
})
export default class PriorityPicker extends Vue {
  @Prop(Boolean) public small !: boolean;
  @Prop(Number) public ticketId !: number;
  @Prop(Object) public priority!: any;
  @Prop(Boolean) public readOnly!: boolean;

  public menu = false;
  public store: any = this.$store.state.ticketsState;
  public beforeLoadedChip = {
    name: 'Ładowanie',
    id: 1,
    description: 'Brak',
    default: false,
    color: '#f1f1f1',
    icon: {
      id: 1,
      slug: 'mdi-alert-circle-outline',
    },
    order: 0,
    ticket_priority_icon_id: 0,
  };
  public chosenOption: null | TicketPriority = null;
  public actionTypes = ticketsModule.actionsTypes;
  public mutationTypes = ticketsModule.mutationsTypes;

  get priorities() {
    return this.store.prioritiesState.data;
  }

  public fetchPriorities() {
    this.$store.dispatch(this.actionTypes.FETCH_DATA, {simple: false})
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
        });
  }

  public changePriority() {
    const id = this.ticketId || this.$route.params.id;
    this.$store.dispatch(this.actionTypes.UPDATE_ITEM, {
      id,
      ticket_priority_id: this.activePriority?.id,
    })
        .then(({data}) => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'success', text: 'Priorytet zmieniony'});
        })
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
        });
  }

  get activePriority() {
    return this.chosenOption
        ? this.chosenOption
        : this.$props.priority
            ? this.$props.priority
            : this.beforeLoadedChip;
  }

  public created() {
    if (this.priorities.length === 0) {
      this.fetchPriorities();
    }
  }
}
