
import {FormRules} from '@/shared/validation/form-rules';
import {Component, Vue, Watch} from 'vue-property-decorator';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import ObjectsList from '@/modules/investments/components/generator/components/objects/list.vue';
import SubjectsList from '@/modules/investments/components/generator/components/subjects/list.vue';
import {InvestmentObjectGenerated} from '../../shared/models/investment-object';
import {InvestmentSubjectGenerated} from '../../shared/models/investment-subject';
import {getObjectOrObjectWithSubjects, getTreeViewObject, searchTree} from './helpers';
import {ObjectController} from '@/modules/investments/components/generator/controllers/ObjectController';
import {SubjectController} from '@/modules/investments/components/generator/controllers/SubjectController';
import {privateStatuses, publicStatuses} from '@/shared/config/investments';
import {parsePrice} from '@/shared/helpers';
import SelectMediaList from '@/modules/media/components/select.vue';
import {
    investmentSubjectPropertiesListGeneratorHeaders,
    investmentSubjectRoomsListGeneratorEditorHeadersTab,
} from '@/modules/investments/shared/config';
import {InvestmentGeneratorTree} from '@/modules/investments/components/generator/types';
import {investmentSubjectRoomTypesModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentObjectsState} from '@/modules/investments/shared/state/state';

@Component({
    props: {
        itemData: Object,
    },
    components: {
        AutoCompleteWithAddNew,
        SelectMediaList,
        ActionConfirmDialog,
        ObjectsList,
        SubjectsList,
    },
})
export default class InvestmentEditor extends Vue {
    public itemData!: InvestmentGeneratorTree;
    public formRules = new FormRules();
    public parsePrice = parsePrice;
    public roomTypesActions = investmentSubjectRoomTypesModule.actionsTypes;
    public roomsHeaders = investmentSubjectRoomsListGeneratorEditorHeadersTab;
    public propertiesHeaders = investmentSubjectPropertiesListGeneratorHeaders;
    public store: InvestmentObjectsState = this.$store.state.investmentsState;
    public subjectsController = new SubjectController();
    public objectController = new ObjectController(this.subjectsController);
    public currentObject: InvestmentObjectGenerated = {} as InvestmentObjectGenerated;
    public currentSubject: InvestmentSubjectGenerated = {} as InvestmentSubjectGenerated;
    public volumeSum: any;
    public volumeSumEndPoint: number = 0;
    public areaSum: any;
    public areaSumEndPoint: number = 0;
    public headers: object[] = [
        { text: 'Typ', value: 'investment_subject_room_type_id', },
        { text: 'Nazwa', value: 'name' },
        { text: 'Poziom', value: 'storey' },
        { text: 'Powierzchnia', value: 'area' },
        { text: 'Kubatura', value: 'volume' },
        { text: 'Kubatura', value: 'volume' },
    ];
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    @Watch('itemData')
    public onTreeChange() {
        this.subjectsController = new SubjectController(this.itemData.subjects);
        this.objectController = new ObjectController(this.subjectsController, this.itemData.objects);
    }

    public mounted() {
        this.$store.dispatch(this.roomTypesActions.FETCH_DATA, {simple: true});
    }

    get objectHasDuplications() {
        return this.objectController.hasDuplications();
    }

    public saveTree() {
        const objectsTable = this.objectController.getObjectsTable();

        const investmentsTree = getTreeViewObject(
            this.objectController.getObjectsTable(),
            this.subjectsController.getSubjectTable(),
        );
        this.$emit('submit', investmentsTree);
    }

    public changedObject() {
        if (!this._.isEmpty(this.currentSubject)) {
            this.currentSubject = {} as InvestmentSubjectGenerated;
        }
    }

    get subjectHasDuplications() {
        return this.subjects &&
            this.subjects.filter(({duplications = 0}) => duplications && duplications > 0).length > 0;
    }

    get objectsTable() {
        return this.objectController.getObjectsTable();
    }

    get subjects() {
        return getObjectOrObjectWithSubjects(
            [this.currentObject],
            this.subjectsController.getSubjectTable(),
        )[0].subjects;
    }

    get subjectsTable() {
        return this.subjectsController.getSubjectTable();
    }

    get subjectPrivateStatus() {
        return this.currentSubject ? privateStatuses.find(
            (status) => status.value === this.currentSubject.private_status,
        ) : {};
    }

    get subjectPublicStatus() {
        return this.currentSubject ? publicStatuses.find(
            (status) => status.value === this.currentSubject.public_status,
        ) : {};
    }

    public acceptAllDuplicationsObject() {
        this.objectController.acceptObjectDuplications();
    }

    get roomTypes() {
        return this.$store.state.investmentsState.subjectRoomTypesState.dataList;
    }

    public acceptAllDuplicationsSubject() {
        this.subjectsController.acceptSubjectDuplications();
    }

    public dropAllDuplicationsObject() {
        this.objectController.dropObjectDuplications();
    }

    public dropAllDuplicationsSubject() {
        this.subjectsController.dropSubjectDuplications();
    }

    // OBJECT
    public storeObject(data: InvestmentObjectGenerated) {
        const result = this.objectController.storeObject(data);
        if (result.parent_id) {
            const rootObject = this.investmentsTree.find((object) => searchTree(object, result.id));
            this.currentObject = {...searchTree(rootObject, result.id)};
        } else {
            this.currentObject = this.investmentsTree.find((object) => object.id === result.id);
        }
    }

    public duplicateObject(item: InvestmentObjectGenerated, duplications: number) {
        this.objectController.duplicateObject(item.id, duplications);
    }

    public editObject(data: InvestmentObjectGenerated) {
        this.objectController.updateObject(data);
        if (data.parent_id) {
            const rootObject = this.investmentsTree.find((object) => searchTree(object, data.id));
            this.currentObject = {...searchTree(rootObject, data.id)};
        } else {
            this.currentObject = this.investmentsTree.find((object) => object.id === data.id);
        }
    }

    public deleteObject(id: number) {
        this.objectController.dropObject(id);
        if (this.currentObject.id === id) {
            this.currentObject = {} as InvestmentObjectGenerated;
        }
    }

    // SUBJECT
    public async storeSubject(data: InvestmentSubjectGenerated) {
        const result = this.subjectsController.storeSubject(data, this.currentObject.id);
        if (this.subjects) {
            const subject = this.subjects.find((item) => item.id === result.id);
            if (subject) {
                this.currentSubject = subject;
            }
        }
    }

    public duplicateSubject(item: InvestmentSubjectGenerated, duplications: number) {
        this.subjectsController.duplicateSubject(item.id, duplications);
    }

    public async editSubject(data: InvestmentSubjectGenerated) {
        this.subjectsController.updateSubject(data);
        if (this.subjects) {
            const subject = this.subjects.find((item) => item.id === data.id);
            if (subject) {
                this.currentSubject = subject;
            }
        }
    }

    public deleteSubject(id: number) {
        this.subjectsController.dropSubject(id);
        if (this.currentSubject.id === id) {
            this.currentSubject = {} as InvestmentSubjectGenerated;
        }
    }

    get investmentsTree() {
        return getTreeViewObject(
            this.objectController.getObjectsTable(),
            this.subjectsController.getSubjectTable(),
        );
    }

    // public computedHeaders() {
    //     let volume = this.currentSubject.rooms?.map((el) => el.volume)
    // }

    @Watch('currentSubject')
    public volumeSumFunction() {
        this.volumeSum =  this.currentSubject.rooms?.map((el) => el.volume);
        for (let i = 0; i < this.volumeSum.length; i++) {
            const current = parseFloat(this.volumeSum[i]);
            this.volumeSum[i] = current;
        }
            /* tslint:disable */
            this.volumeSumEndPoint = this.volumeSum.reduce((a: any, b: any) => {
                if (isNaN(a)) {
                    return b;
                }
                if (isNaN(b)) {
                    return a;
                }
                else {
                    return a + b;
                }
            /* tslint:enable */
        });
        // this.computedHeaders();
    }

    @Watch('currentSubject')
    public areaSumFunction() {
        this.areaSum =  this.currentSubject.rooms?.map((el) => el.area);
        for (let i = 0; i < this.areaSum.length; i++) {
            const current = parseFloat(this.areaSum[i]);
            this.areaSum[i] = current;
        }
            /* tslint:disable */
            this.areaSumEndPoint = this.areaSum.reduce((a: any, b: any) => {
            return a + b;
            /* tslint:enable */
        });
    }
}
