
import {Component, Watch} from 'vue-property-decorator';
import GeneratorForm from '@/modules/investments/components/generator/components/form-component';
import {InvestmentTag} from '@/modules/investments/shared/models/investment-tag';
import {investmentTagsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentDataRequest} from '@/modules/investments/shared/requests/investment-data-request';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import TagsInput from '@/shared/components/elements/tags-input.vue';

@Component({
    components: {
        InvestmentTagChip,
        ActionConfirmDialog,
        DatePicker,
        TagsInput,
    },
    props: {
        itemData: Object,
    },
})
export default class InvestmentGeneratorForm extends GeneratorForm<InvestmentDataRequest> {

    get isMApp() {
        return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';
    }

    get startBefore() {
        return (v: string) =>
            !this.itemData.start_date ||
            !v ||
            new Date(this.itemData.start_date) <= new Date(this.itemData.end_date) ||
            'Data zakończenia nie może być wcześniej niż data rozpoczęcia';
    }
    public store: any = this.$store.state.investmentsState;
    public tagActions = investmentTagsModule.actionsTypes;
    public tagMutations = investmentTagsModule.mutationsTypes;
    public tagsSearch: any = null;
    public searchTag: string = '';

    public tags = [];
    private newTagDialog: boolean = false;

    public mounted() {
        this.form = this.$refs.investmentForm;
    }

    @Watch('tags', {deep: true})
    public updateTags(val: any[]) {
        this.$set(this.itemData, 'tags', val.map((el) => el.id));
    }
}
