
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module
import { investmentSubjectChangesValuationModule } from '../../../../shared/state/submodules/subjects';

// dataRequest
import { InvestmentSubjectChangesValuationDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-changes-valuation-data-request';

@Component({
    components: { FormActions },
})
export default class InvestmentSubjectChangesValuationForm extends
 FormComponent<InvestmentSubjectChangesValuationDataRequest> {
    public store = this.$store.state.investmentsState.subjectChangesValuationState;
    public actionsTypes = investmentSubjectChangesValuationModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public mutationTypes = investmentSubjectChangesValuationModule.mutationsTypes;
    public requestData = new InvestmentSubjectChangesValuationDataRequest();

    get formTitle(): string {
        return this.edit ? 'Edytuj wycenę zmian przedmiotu' : 'Dodaj wycenę zmian przedmiotu';
    }

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }

    public updateRequestData() {
        this.requestData = this.itemData;
    }
}
