
import {Component, Vue} from 'vue-property-decorator';
import {httpClient} from '@/shared/services';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import Draggable from 'vuedraggable';
import {TicketPriority} from '@/modules/tickets/shared/models/priority';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {AxiosResponse} from 'axios';

@Component({
  components: {
    IconContrast,
    TextContrast,
    Draggable,
  },
})
export default class ChangeOrderDialog extends Vue {
  public prioritiesLoading: boolean = false;
  public priorities: TicketPriority[] = [];
  public basePriorities: TicketPriority[] = [];
  public disabled: boolean = false;

  public created() {
    this.fetchPriorities();
  }

  public fetchPriorities() {
    this.prioritiesLoading = true;
    httpClient
        .get('api/v1/tickets/priorities')
        .then((response: AxiosResponse<ModuleFetchPayload<TicketPriority>>) => response.data)
        .then((response) => {
          if (response.data) {
            this.priorities = response.data;
            this.priorities.sort((a: TicketPriority, b: TicketPriority) => a.order - b.order);
            this.basePriorities = response.data;
          }
        })
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
        })
        .finally(() => {
          this.prioritiesLoading = false;
          this.disabled = false;
        });
  }

  public changeHierarchy({oldIndex, newIndex}: any) {
    const itemId = this.basePriorities[oldIndex].id;
    this.prioritiesLoading = true;
    this.disabled = true;
    httpClient
        .put(`api/v1/tickets/priorities/${itemId}`, {
          order: newIndex,
        })
        .then(() => {
          this.fetchPriorities();
          this.$store.commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie zmieniono kolejność'});
        })
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
          this.prioritiesLoading = false;
          this.disabled = false;
        });
  }

  public close() {
    this.$emit('close');
  }
}
