
import { Component, Watch } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import { investmentObjectTypesModule } from '@/modules/investments/shared/state/submodules';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { InvestmentObjectGenerated } from '@/modules/investments/shared/models/investment-object';
import GeneratorForm from '@/modules/investments/components/generator/components/form-component';
import ObjectsTypesSettingsForm from '@/modules/investments/components/settings/objects/form.vue';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import { levelVariables, objectVariables } from '@/shared/config/investments';
import { validLesserThanZeroLevel, validMinLevel } from '@/modules/investments/components/generator/rules';
import { InvestmentObjectType } from '@/modules/investments/shared/models/objects/investment-object-type';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        VariableTextField,
        FormActions,
        IconChooser,
        AutoCompleteWithAddNew,
    },
    props: {
        itemData: Object,
        children: Array,
        edit: {
            type: Boolean,
            default: false,
        },
        parent: Object,
        show: Boolean,
    },
})
export default class InvestmentGeneratorObjectForm extends GeneratorForm<InvestmentObjectGenerated> {
    public objectTypesState = this.$store.state.investmentsState.objectTypesState;
    public parent!: InvestmentObjectGenerated;
    public children!: InvestmentObjectGenerated[];
    public investmentObjectTypesModule = investmentObjectTypesModule;
    public objectTypesForm = ObjectsTypesSettingsForm;
    public validLesserThanZeroLevel = validLesserThanZeroLevel;
    public validMinLevel = validMinLevel;
    public investmentObjectType: InvestmentObjectType = {} as InvestmentObjectType;

    get variables() {
        const variables = [...objectVariables];
        if (this.itemData.investment_object_type_id === 3) {
            variables.push(...levelVariables);
        }
        return variables;
    }

    get typesLoading() {
        return this.objectTypesState.loading;
    }

    get formTitle() {
        return this.edit
            ? `Edytuj kategorię ${this.itemData.parent_id && this.itemData.parent_id > 0 ? 'podrzędny' : ''} inwestycji`
            : `Dodaj kategorię ${this.itemData.parent_id && this.itemData.parent_id > 0 ? 'podrzędny' : ''} inwestycji`;
    }

    public levelVariableValidation(v: string) {
        return (
            Boolean(v && !v.includes('{{numer_poziomu}}') || this.itemData.investment_object_type_id === 3) ||
            'Zmienna {{numer_poziomu}} nie jest dozwolona przy tym typie obiektu'
        );
    }

    public levelValidation() {
        return (
            Boolean(this.itemData.investment_object_type_id !== 3 || (this.itemData.parent_id && this.parent)) ||
            'Kondygnacja nie może być głównym obiektem'
        );
    }

    public levelStageValidation() {
        return (
            Boolean(
                !(this.itemData.investment_object_type_id === 1 && this.hasChildrenWithType(3)) &&
                    (this.itemData.investment_object_type_id !== 3 || this.parent.investment_object_type_id !== 1),
            ) || 'Kondygnacja nie może być pod Etapem'
        );
    }

    public levelInceptionValidation() {
        return (
            Boolean(
                !(!this.parent && this.itemData.investment_object_type_id === 3 && this.hasChildrenWithType(3)) &&
                    (this.itemData.investment_object_type_id !== 3 || this.parent.investment_object_type_id !== 3),
            ) || 'Kondygnacja nie może być pod Kondygnacją'
        );
    }

    public buildingInceptionValidation() {
        return (
            Boolean(
                !(this.itemData.investment_object_type_id === 2 && this.hasChildrenWithType(2)) &&
                    (this.itemData.investment_object_type_id !== 2 || this.parent.investment_object_type_id !== 2),
            ) || 'Budynek nie może być w Budynku'
        );
    }

    public stageOnlyRootValidation() {
        return (
            Boolean(
                this.itemData.investment_object_type_id !== 1 || !Boolean(this.itemData.parent_id && this.parent),
            ) || 'Etap nie może być obiektem podrzędnym'
        );
    }

    public buildingInLevelValidation() {
        return (
            Boolean(
                !(this.itemData.investment_object_type_id === 3 && this.hasChildrenWithType(2)) &&
                    (this.itemData.investment_object_type_id !== 2 ||
                        !this.itemData.parent_id ||
                        this.parent.investment_object_type_id !== 3),
            ) || 'Budynek nie może być pod Kondygnacją'
        );
    }

    public buildingInHouseValidation() {
        return (
            Boolean(
                (!(this.itemData.investment_object_type_id === 6 && this.hasChildrenWithType(2)) &&
                    this.itemData.investment_object_type_id !== 2) ||
                    !this.itemData.parent_id ||
                    this.parent.investment_object_type_id !== 6,
            ) || 'Budynek nie może być w Domu'
        );
    }

    public hasChildrenWithType(type: number) {
        return this.children && this.children.filter((child) => child.investment_object_type_id === type).length > 0;
    }

    public houseInBuildingValidation() {
        return (
            (!(this.itemData.investment_object_type_id === 2 && this.hasChildrenWithType(6)) &&
                (this.itemData.investment_object_type_id !== 6 ||
                    !this.itemData.parent_id ||
                    this.parent.investment_object_type_id !== 2)) ||
            'Dom nie może być w Budynku'
        );
    }

    public houseInLevelValidation() {
        return (
            (!(this.itemData.investment_object_type_id === 3 && this.hasChildrenWithType(6)) &&
                (this.itemData.investment_object_type_id !== 6 ||
                    !this.itemData.parent_id ||
                    this.parent.investment_object_type_id !== 3)) ||
            'Dom nie może być pod Kondygnacją'
        );
    }

    public houseInceptionValidation() {
        return (
            Boolean(
                !(this.itemData.investment_object_type_id === 6 && this.hasChildrenWithType(6)) &&
                    (this.itemData.investment_object_type_id !== 6 || this.parent.investment_object_type_id !== 6),
            ) || 'Dom nie może być w Domu'
        );
    }

    public created() {
        this.$store.dispatch(this.investmentObjectTypesModule.actionsTypes.FETCH_DATA, {
            simple: false,
        });
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        if (!this.itemData.parent_id) {
            delete this.itemData.parent_id;
        }
        return callSuper(this, 'submitForm', e, next);
    }

    public mounted() {
        this.form = this.$refs.investmentGeneratorObjectForm;
    }

    public changeName(value: string) {
        if (!this.itemData.name) {
            this.$set(this.itemData, 'name', value);
        }
    }

    @Watch('investmentObjectType', { deep: true , immediate: true})
    public onInvestmentObjetTypeChange(val?: InvestmentObjectType | null) {
        if (val && val.icon) {
            this.$set(this.itemData, 'icon', val.icon.slug);
            this.$set(this.itemData, 'investment_object_type_id', val.id);
        } else {
            this.$set(this.itemData, 'icon', 'mdi-shape');
        }
    }
}
