
import { Component, Watch } from 'vue-property-decorator';
import SelectMediaList from '@/modules/media/components/select.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { InvestmentSubjectMediaFile } from '@/modules/investments/shared/models/subjects/investment-subject-media-file';
import { ModuleState } from '@/shared/state/template/module-state';
import { InvestmentSubjectMediaFileDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-media-file-data-request';
import { ModuleProps } from '@/shared/state/template/module-props';
import { investmentSubjectMediaFilesModule } from '@/modules/investments/shared/state/submodules';
import { getIcon } from '@/modules/media/shared/helpers';
import SubjectMediaCarousel from '@/modules/investments/components/objects/subjects/media/carousel.vue';
import { MediaFileSelect } from '@/modules/media/shared/models/media-file-select';
import { FetchParams } from '@/shared/types';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        SelectMediaList,
        SubjectMediaCarousel,
    },
    props: {
        subjectId: Number,
        hideAddButton: Boolean,
    },
})
export default class InvestmentSubjectMedia extends ListComponent<
    InvestmentSubjectMediaFile,
    InvestmentSubjectMediaFileDataRequest
> {
    public props: ModuleProps = investmentSubjectMediaFilesModule.moduleProps;
    public actionsTypes = investmentSubjectMediaFilesModule.actionsTypes;
    public mutationTypes = investmentSubjectMediaFilesModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers: object[] = [];
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store: ModuleState<InvestmentSubjectMediaFile, InvestmentSubjectMediaFileDataRequest> =
        this.$store.state.investmentsState.subjectMediaFilesState;
    public mediaSelectDialog: boolean = false;
    public mediaDialog: boolean = false;
    public dialogId: number = 0;
    public getIcon = getIcon;
    public subjectId!: number;

    public created() {
        this.id = this.subjectId;
        this.$store.dispatch(this.actionsTypes.FETCH_DATA, { id: this.id, simple: true });
        this.fetchData();
    }

    public pageChange(page: number) {
        this.page = page;
    }

    public openDetails(index: number) {
        this.dialogId = index;
        this.mediaDialog = true;
    }

    public addMedia(items: number[]) {
        this.mediaSelectDialog = false;
        const mediaFiles = items.map((e: number) => {
            return { media_file_id: e } as InvestmentSubjectMediaFile;
        });
        const subjectMediaRequest: InvestmentSubjectMediaFileDataRequest = new InvestmentSubjectMediaFileDataRequest();
        subjectMediaRequest.investment_subject_id = this.id || 0;
        subjectMediaRequest.investment_subject_media_files = mediaFiles;
        this.$store.dispatch(this.actionsTypes.STORE_ITEM, subjectMediaRequest).then(() => {
            this.page = 1;
            this.$store.dispatch(this.actionsTypes.FETCH_DATA, { id: this.id, simple: true });
            this.fetchParams.per_page = 3;
            this.fetchData();
        });
    }

    get itemsList(): MediaFileSelect[] {
        return this.store.dataList.filter((value: any) => value.media_file) as MediaFileSelect[];
    }

    get items() {
        return this.store.data ? this.store.data.filter((item) => item.media_file) : [];
    }

    get columns() {
        switch (this.$vuetify.breakpoint.name) {
            case 'lg':
                return 2;
            case 'xl':
                return 3;
            default:
                return 3;
        }
    }

    get loadersAmount() {
        if (this.page === this.last_page) {
            return this.total - (this.page - 1) * this.columns;
        } else {
            return this.columns;
        }
    }

    @Watch('columns')
    public onColumnsChange(val: number) {
        this.fetchParams.per_page = this.columns;
        if (!this.loading) {
            this.fetchData();
        }
    }

    public parseParams(params: FetchParams) {
        params.per_page = this.columns;
        callSuper(this, 'parseParams', params);
        return params;
    }

    public mounted() {
        this.$watch('meta', this.onLastPageChange, {deep: true});
    }

    public onLastPageChange(val: number) {
        if (this.page > this.last_page) { this.page = val; }
    }
}
