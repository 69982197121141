

import {Component} from 'vue-property-decorator';
import TemplateObjectForm from '@/modules/investments/components/generator/templates/template/template-object-form';
import {BlockInvestmentLevel} from '@/modules/investments/components/generator/templates/blocks/models/types';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import {VariableFieldType} from '@/shared/types';
import {levelVariables, objectVariables} from '@/shared/config/investments';
import FormActions from './form-actions.vue';

@Component({
    components: {
        FormActions,
        VariableTextField,
    },
})
export default class BlockInvestmentLevelForm extends TemplateObjectForm<BlockInvestmentLevel> {
    public variables: VariableFieldType[] = [...levelVariables, ...objectVariables];
}

