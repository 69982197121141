

import {investmentObjectMediaFilesModule} from '@/modules/investments/shared/state/submodules';
import {Component, Prop, VModel} from 'vue-property-decorator';
import Vue from 'vue';
import {
    InvestmentObjectMediaFileDataRequest
} from '@/modules/investments/shared/requests/objects/investment-object-media-file-data-request';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import {FetchParams} from '@/shared/types';
import SelectMediaList from '@/modules/media/components/select.vue';

@Component({
    components: {
        MediaFileInput,
        SelectMediaList
    }
})
export default class ObjectMediaFileInput extends Vue {
    @Prop(Array) public rules!: any;
    @Prop(String) public label!: string;
    @Prop(Boolean) public outlined!: boolean;
    @Prop(Number) public investmentObjectId!: number;
    @Prop(Object) public filters!: FetchParams;
    @VModel({type: Object}) public mediaFile!: number;
    public objectsState = this.$store.state.investmentsState.objectsState;
    public objectMediaFilesState = this.$store.state.investmentsState.objectMediaFilesState;
    public objectMediaFileActions = investmentObjectMediaFilesModule.actionsTypes;
    public objectMediaFileMutations = investmentObjectMediaFilesModule.mutationsTypes;
    public objectMediaFileFetchAction = this.objectMediaFileActions.FETCH_DATA;
    public objectMediaFileStoreAction = this.objectMediaFileActions.STORE_ITEM;

    public onPreSave(mediaFiles: any, cb?: () => void) {
        const objectMediaRequest: InvestmentObjectMediaFileDataRequest = {} as InvestmentObjectMediaFileDataRequest;
        objectMediaRequest.investment_object_id = this.investmentObjectId;
        objectMediaRequest.investment_object_media_files = mediaFiles.map(({id}: any) => {
            return {media_file_id: id};
        });
        this.$store.dispatch(this.objectMediaFileActions.STORE_ITEM, objectMediaRequest).then(() => {
            this.$store.dispatch(this.objectMediaFileActions.FETCH_DATA, {
                filters: {investment_object_id: this.investmentObjectId},
            });
        });
    }
}

