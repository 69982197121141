
    import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
    import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
    import { parseDate } from '@/shared/helpers';
    import { investmentsObjectsListHeaders } from '@/modules/investments/shared/config';
    import { mdiHome } from '@mdi/js';
    import {
        investmentSubjectOperationsModule,
        investmentSubjectPropertyTypesModule,
        investmentSubjectTypesModule,
    } from '@/modules/investments/shared/state/submodules';
    import {
        mediaActions,
        privateStatuses,
        propertyActions,
        publicStatuses,
        subjectCodeVariables,
    } from '@/shared/config/investments';
    import { FormRules } from '@/shared/validation/form-rules';
    import SelectMediaList from '@/modules/media/components/select.vue';
    import { errorsToArray, httpClient, logger } from '@/shared/services';
    import ErrorBoundary from '@/shared/components/error-boundary/error-boundary.vue';
    import { simplifyData, subjectCodeRules } from '@/modules/investments/shared/helpers/subjects';
    import {
        InvestmentSubjectPropertyType
    } from '@/modules/investments/shared/models/subjects/investment-subject-property-type';
    import {
        InvestmentSubjectProperty
    } from '@/modules/investments/shared/models/subjects/investment-subject-property';
    import { InvestmentSubjectType } from '@/modules/investments/shared/models/subjects/investment-subject-type';
    import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
    import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
    import PropertyCheckbox from '@/modules/investments/components/subjects/property-checkbox.vue';
    import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
    import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state/types';
    import VariableTextField from '@/shared/components/elements/variable-text-field.vue';

    @Component({
        components: {
            ActionConfirmDialog,
            PropertyCheckbox,
            ErrorBoundary,
            SelectMediaList,
            TopToolbar,
            TooltipBtn,
            VariableTextField,
        },
    })
    export default class InvestmentSubjectDetails extends Vue {
        @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
        @Prop(String) public id!: string;

        public store: any = this.$store.state.investmentsState.subjectOperationsState;
        public errorsToArray = errorsToArray;
        public propertyTypesActions = investmentSubjectPropertyTypesModule.actionsTypes;
        public propertyTypesMutations = investmentSubjectPropertyTypesModule.mutationsTypes;
        public typesActions = investmentSubjectTypesModule.actionsTypes;
        public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
        public mutationTypes = investmentSubjectOperationsModule.mutationsTypes;
        public headers = investmentsObjectsListHeaders;
        public itemData: any = {
            properties: [],
            media: {},
        };
        public mediaActions = mediaActions;
        public parents: string[] = [];
        public validProperty: boolean = false;
        public validData: boolean = false;
        public parseDate = parseDate;
        public icons: object = {
            mdiHome,
        };
        public privateStatuses = privateStatuses;
        public publicStatuses = publicStatuses;
        public edit = {};
        public description: any = null;
        public selectedPropertyFieldIndex: number = 0;
        public nameInput: any = null;
        public searchPropertyTypesResponse: InvestmentSubjectPropertyType[] = [];
        public timer: any = null;
        public formRules = new FormRules();
        public propertyForm: any = null;
        public dataForm: any = null;
        public propertyActions = propertyActions;
        public mediaSelectDialog = false;
        public newTypeDialog = false;
        public subjectTypesState = this.$store.state.investmentsState.subjectTypesState;
        public subjectPropertyTypesState = this.$store.state.investmentsState.subjectPropertyTypesState;
        public users: any = [];
        public assignedUserLoading: boolean = false;
        public subjectCodeVariables = subjectCodeVariables;
        public subjectCodeRules = subjectCodeRules;

        public created() {
            if (this.editedItem.selection && this.editedItem.selection.length > 0) {
                this.$store.dispatch(this.typesActions.FETCH_DATA, { simple: false });
            } else {
                this.$router.push({ name: 'subjects-list' });
            }

            this.$store
                .dispatch(adminActionsTypes.FETCH_USER_DATA)
                .then((res) => {
                    this.users = this.pullToArraysHeadCurrentUser(res.data);
                })
                .finally(() => {
                    this.assignedUserLoading = false;
                });
        }

        get errorMessages() {
            return this.store.errorMessages;
        }

        public clearError(name: string) {
            if (this.errorMessages[name]) {
                delete this.errorMessages[name];
                if (this.errorMessages.length > 0) {
                    return;
                }
                return (this.store.error = '');
            }
        }

        get propertyValues() {
            return this.itemData.properties
                .filter((item: InvestmentSubjectProperty) => item.value)
                .map((item: InvestmentSubjectProperty) => item.value);
        }

        public putInName(text: string) {
            if (!this.itemData.name) {
                return text;
            }
            return (
                this.itemData.name.slice(0, this.nameInput.selectionStart) +
                text +
                this.itemData.name.slice(
                    this.nameInput.selectionStart === this.nameInput.selectionEnd
                        ? this.nameInput.selectionStart
                        : this.nameInput.selectionEnd,
                )
            );
        }

        public createPropertyType() {
            this.newTypeDialog = false;
            this.$store
                .dispatch(this.propertyTypesActions.STORE_ITEM, this.subjectPropertyTypesState.editedItem)
                .then((response: ModuleShowPayload<InvestmentSubjectPropertyType>) => {
                    if (response.data && response.data.id) {
                        this.$store
                            .dispatch(this.propertyTypesActions.FETCH_DATA, {
                                simple: false,
                            })
                            .then(() => {
                                if (response.data && response.data.id) {
                                    const properties = this.itemData.properties;
                                    properties[this.selectedPropertyFieldIndex].investment_subject_property_type =
                                        response.data;
                                    this.itemData.properties = properties;
                                    this.$forceUpdate();
                                }
                            });
                    }
                });
        }

        public putInDescription(text: string) {
            if (!this.itemData.description) {
                return text;
            }
            return (
                this.itemData.description.slice(0, this.description.selectionStart) +
                text +
                this.itemData.description.slice(
                    this.description.selectionStart === this.description.selectionEnd
                        ? this.description.selectionStart
                        : this.description.selectionEnd,
                )
            );
        }

        public pullToArraysHeadCurrentUser(arrayUsers: [any]) {
            const currentArray = [...arrayUsers];
            const { id, name } = this.currentUser;
            const currentUser = {
                ...this.currentUser,
                name: `(Ja) ${name}`,
            };
            const arrayWithoutCurrentUser = this._.filter(currentArray, this._.negate(this._.matches({ id })));
            return [currentUser, ...arrayWithoutCurrentUser];
        }

        public addProperty() {
            this.itemData.properties.push({ public: false });
            this.$forceUpdate();
        }

        public async submitForm() {
            await this.propertyForm.validate();
            await this.dataForm.validate();

            if (this.validData && this.validProperty) {
                const edit: any = {};
                for (const key in this.itemData) {
                    if (
                        (this.itemData.hasOwnProperty(key) &&
                            this.itemData[key] &&
                            (this.itemData[key].length > 0 || Object.keys(this.itemData[key]).length > 0)) ||
                        typeof this.itemData[key] === 'boolean'
                    ) {
                        edit[key] = this.itemData[key];
                        if (key === 'description' && !this.itemData.hasOwnProperty('description_replace')) {
                            edit.description_replace = false;
                        }
                    }
                }
                const item = simplifyData({ edit, selection: this.editedItem.selection });

                this.$store
                    .dispatch(this.actionsTypes.EDIT_SUBJECTS_CHECK, item)
                    .then((response) => {
                        if (response && response === 200) {
                            this.validData = true;
                            this.editedItem.edit = edit;
                            this.$router.push({ name: 'edit-subjects-summary' });
                        }
                    })
                    .catch((err) => logger.error(err));
            }
        }

        public searchTypes(search: string) {
            if (
                search &&
                search.length > 2 &&
                (!this.itemData.investment_subject_type_id || this.itemData.investment_subject_type_id.name !== search)
            ) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.$store.dispatch(this.typesActions.FETCH_DATA, { filters: { search }, simple: false });
                }, 300);
            }
        }

        public updatePropertyType(property: any, item: string | InvestmentSubjectType, index?: number) {
            if (item) {
                this.clearError(`edit.properties.${index}.investment_subject_property_type_id`);
                if (typeof item === 'string') {
                    if (this.subjectPropertyTypesState.data.length < 1) {
                        if (property.action === 'replace' || property.action === 'add') {
                            this.newTypeDialog = true;
                            this.$store.commit(this.propertyTypesMutations.UPDATE_EDITED, { name: item });
                        } else {
                            property.investment_subject_property_type = null;
                        }
                    }
                } else {
                    property.investment_subject_property_type = item;
                }
            }
        }

        public searchPropertyTypes(search: string, index: number) {
            if (
                search &&
                search.length > 2 &&
                (!this.itemData.properties[index].investment_subject_property_type ||
                    this.itemData.properties[index].investment_subject_property_type.name !== search)
            ) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.selectedPropertyFieldIndex = index;
                    this.$store.dispatch(this.propertyTypesActions.FETCH_DATA, { filters: { search }, simple: false });
                }, 300);
            }
        }

        get editedItem() {
            return this.store.editedItem;
        }

        set editedItem(value) {
            this.$store.commit(this.mutationTypes.UPDATE_EDITED, value);
        }

        get currentUser() {
            return this.$store.state.authState.user;
        }

        public mounted() {
            this.nameInput = this.$refs.nameInput as Vue;
            this.nameInput = this.nameInput.$el.querySelector('input');
            this.description = this.$refs.description as Vue;
            this.description = this.description.$el.querySelector('textarea');

            if (this.editedItem.edit) {
                this.itemData = Object.assign(
                    {
                        properties: this.editedItem.edit.properties ? this.editedItem.edit.properties : [],
                        media: this.editedItem.edit.media ? this.editedItem.edit.media : {},
                    },
                    this.editedItem.edit,
                );
            }
            this.propertyForm = this.$refs.propertyForm;
            this.dataForm = this.$refs.dataForm;
        }

        public uniqueCode(v: string) {
            if (v) {
                httpClient.post('api/v1/investments/subjects/validate-code', { code: v }).catch((err: any) => {
                    return this.errorMessages.code.push(err.message);
                });
            }
        }

        @Watch('itemData.code', { deep: true })
        public onCodeChange(val: string) {
            this.clearError('code');
            this.uniqueCode(val);
        }
    }
