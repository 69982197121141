
import EventDetails from '@/modules/calendar/components/details.vue';
import EventForm from '@/modules/calendar/components/form.vue';
import { Contractor } from '@/modules/contractors/shared/models/contractor';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import SystemBar from '@/shared/components/elements/system-bar.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import Vue from 'vue';
import { Inject, Component, Prop, PropSync } from 'vue-property-decorator';
import RelatedContractorsForm from './form.vue';
import RelatedContractorsListItem from './list-item.vue';

@Component({
    components: {
        ActionConfirmDialog,
        EventDetails,
        SystemBar,
        TooltipBtn,
        EventForm,
        NameReduce,
        RelatedContractorsListItem,
        AutoCompleteWithAddNew,
        RelatedContractorsForm,
    },
})
export default class RelatedContractorsList extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    @Prop(Array) public relatedContractors!: Contractor[];
    @Prop(Boolean) public loading!: boolean;
    @Prop(Boolean) public isMobile!: boolean;
    @PropSync('x', Number) public xSync!: number;
    @PropSync('y', Number) public ySync!: number;
}
