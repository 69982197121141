
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class SubFormActions extends Vue {
    @Prop(Boolean) public canGoBack!: boolean;
    @Prop(Boolean) public valid!: boolean;
    @Prop(Boolean) public optional!: boolean;
    @Prop(Boolean) public submit!: boolean;
}
