
import {Component, Prop, Vue} from 'vue-property-decorator';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import ItemsTable from '@/shared/components/layout/list.vue';
import {investmentSubjectRoomsModule} from '@/modules/investments/shared/state/submodules';
import {investmentSubjectRoomsListHeaders} from '@/modules/investments/shared/config';
import {ModuleState} from '@/shared/state/template/module-state';
import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';
import {
    InvestmentSubjectRoomDataRequest
} from '@/modules/investments/shared/requests/subjects/investment-subject-room-data-request';
import {ToolbarProps} from '@/shared/models/toolbar-props';
import {HeaderFieldType} from '@/shared/types';
import InvestmentSubjectMedia from '@/modules/investments/components/objects/subjects/media/media.vue';
import {parsePrice} from '@/shared/helpers';

@Component({
    components: {
        ItemsTable,
        InvestmentSubjectMedia,
    }
})
export default class SubjectSimpleDetails extends Vue {
    @Prop(Object) public subject!: InvestmentSubject;

    public parsePrice = parsePrice;
    public actionsTypes = investmentSubjectRoomsModule.actionsTypes;
    public mutationTypes = investmentSubjectRoomsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers: HeaderFieldType[] = [
        {text: 'Nazwa', value: 'name'},
        {text: 'Typ', value: 'type'},
        {text: 'Piętro', value: 'storey', align: 'right'},
        {text: 'Powierzchnia', value: 'area', align: 'right'},
    ];
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public toolbarProps = new ToolbarProps();
    public props = investmentSubjectRoomsModule.moduleProps;
    public subjectId!: number;
    public store: ModuleState<InvestmentSubjectRoom,
        InvestmentSubjectRoomDataRequest> = this.$store.state.investmentsState.subjectRoomsState;

    public created() {
        this.toolbarProps.addButton = false;
    }
}
