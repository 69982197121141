
import ListComponent from '@/shared/components/layout/list/list-component';
import { Component, Watch } from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { HeaderFieldType } from '@/shared/types';

import { Change } from '@/modules/changes/shared/models/change';

import { InvestmentSubjectChangesValuationDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-changes-valuation-data-request';
import InvestmentSubjectChangesValuationForm from '../changesValuation/form.vue';
import { InvestmentSubjectCatalogChange } from '../../../../shared/models/subjects/investment-subject-catalog-change';
import { investmentSubjectCatalogChangesModule } from '../../../../shared/state/submodules/subjects';
import { investmentSubjectCatalogChangesListHeaders } from '../../../../shared/config/index';

import { investmentSubjectChangesValuationModule } from '../../../../shared/state/submodules/subjects';

@Component({
    props: {
        subjectId: Number,
        changeCatalogId: Number,
    },
    components: {
        ItemsTable,
        InvestmentSubjectChangesValuationForm,
    },
})
export default class InvestmentSubjectChangesCatalogList extends ListComponent<
    InvestmentSubjectCatalogChange,
    InvestmentSubjectChangesValuationDataRequest
> {
    public subjectId!: number;
    public changeCatalogId!: number;

    public headers: HeaderFieldType[] = investmentSubjectCatalogChangesListHeaders;
    public store = this.$store.state.investmentsState.subjectCatalogChangesState;
    public props: ModuleProps = investmentSubjectCatalogChangesModule.moduleProps;
    public mutationTypes = investmentSubjectCatalogChangesModule.mutationsTypes;
    public actionsTypes = investmentSubjectCatalogChangesModule.actionsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;

    public changesValuationStore = this.$store.state.investmentsState.subjectChangesValuationState;
    public changesValuationActionsTypes = investmentSubjectChangesValuationModule.actionsTypes;
    public changesValuationFetchAction = this.changesValuationActionsTypes.FETCH_DATA;

    public selected: Change[] = [];
    public changesValuation: any = [];

    get createButtonDisabled() {
        return this.selected.length === 0 ? true : false;
    }

    public created() {
        this.id = this.subjectId;
        this.fetchData();
    }

    public createChangesValuation() {
        this.changesValuation = this.selected.map((e) => {
            const object = {
                investment_subject_id: this.subjectId,
                change_id: e.id,
                count: (e as any).count === null || (e as any).count === undefined ? null : (e as any).count,
            };

            return object;
        });

        this.store.editedItem = {
            investment_subject_id: this.subjectId,
            change_catalog_id: this.changeCatalogId,
            name: '',
            changes_valuation: this.changesValuation,
        };
    }

    public itemSaved() {
        this.formDialog = false;
        if (this.subjectId) {
            this.$store.dispatch(this.changesValuationFetchAction, { id: this.subjectId }).then(({ data }) => {
                this.changesValuationStore.data = data;
            });
            this.selected = [];
        }
    }

    @Watch('formDialog')
    public onFormDialogOpen() {
        if (this.formDialog) {
            this.createChangesValuation();
        }
    }

    @Watch('subjectId')
    public changeSubjectId(newVal: number) {
        this.id = newVal;
        if (this.page !== 1) {
            this.page = 1;
        } else {
            this.fetchData();
        }
    }
}
