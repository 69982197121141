
import { Component, Watch } from 'vue-property-decorator';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import { parseDate, parsePrice } from '@/shared/helpers';
import InvestmentSubjectMedia from '@/modules/investments/components/objects/subjects/media/media.vue';
import { investmentsObjectsListHeaders } from '@/modules/investments/shared/config';
import { mdiHome } from '@mdi/js';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import {
    investmentSubjectPropertiesModule,
    investmentSubjectsModule,
    prospectSettingsModule,
    investmentSubjectChangesValuationModule,
    investmentSubjectReservationModule,
} from '@/modules/investments/shared/state/submodules';
import InvestmentSubjectRoomsList from '@/modules/investments/components/objects/subjects/rooms/list.vue';
import {
    privateStatuses,
    prospectDefaultAttachments,
    prospectTexts,
    publicStatuses,
} from '@/shared/config/investments';
import InvestmentSubjectForm from '@/modules/investments/components/objects/subjects/form.vue';
import { InvestmentObject } from '@/modules/investments/shared/models/investment-object';
import InvestmentSubjectPropertyForm from '@/modules/investments/components/objects/subjects/properties/form.vue';
import { InvestmentSubjectProperty } from '@/modules/investments/shared/models/subjects/investment-subject-property';
import { httpClient, logger } from '@/shared/services';
import { ProspectDataRequest } from '@/modules/investments/shared/requests/prospect-data-request';
import ProspectForm from './prospect/form.vue';
// @ts-ignore
import { pl } from '@abbjs/slownie';
import { AxiosResponse } from 'axios';
import { ListPayload, ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { Client } from '@/modules/client/shared/models/client';
import { Land } from '@/modules/lands/shared';
import { Investment } from '@/modules/investments/shared/models/investment';
import { ModuleProps } from '@/shared/state/template/module-props';
import { ProspectSetting } from '@/modules/investments/shared/models/prospect-setting';
import { InvestmentSubjectsState } from '@/modules/investments/shared/state/state';
import { ProspectSettingDataRequest } from '@/modules/investments/shared/requests/prospect-setting-data-request';
import ItemsTable from '@/shared/components/layout/list.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import InvestmentSubjectPlanList from '@/modules/investments/components/plans/list.vue';
import SubjectCardDialog from '@/modules/investments/components/objects/subjects/card-dialog.vue';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';
import LinkedEvents from '@/modules/calendar/components/linked-events.vue';
import { nextTick } from 'process';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import { changesValuationHeaders } from '@/modules/changes/shared/config/index';
import { Change } from '@/modules/changes/shared/models/change';
import { HeaderFieldType, SaveResponse } from '@/shared/types';
import { InvestmentSubjectChangesValuationDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-changes-valuation-data-request';
import { InvestmentSubjectChangesValuation } from '@/modules/investments/shared/models/subjects/investment-subject-changes-valuation';
import { isNullOrUndefined } from 'util';
import AcceptancesList from '@/modules/acceptances/components/list.vue';

import echoInstance from '@/echo';
import InvestmentSubjectChangesCatalogList from '../subjects/changesCatalog/list.vue';

import InvestmentSubjectChangesValuationsList from '../subjects/changesValuation/list.vue';
import InvestmentSubjectChangesValuationForm from '../subjects/changesValuation/form.vue';
import DataChangedWarningDialog from '@/shared/components/dialogs/data-changed-warning-dialog.vue';

Component.registerHooks(['beforeRouteLeave']);

import InvestmentSubjectReservationForm from '@/modules/investments/components/objects/subjects/reservation/form.vue';
import { InvestmentSubjectReservation } from '@/modules/investments/shared/models/subjects/investment-subject-reservation';



@Component({
    props: {
        id: String,
        investment_subject_id: Number,
    },
    components: {
        SubjectCardDialog,
        InvestmentSubjectPropertyForm,
        ProspectForm,
        InvestmentSubjectForm,
        ActionConfirmDialog,
        InvestmentSubjectMedia,
        InvestmentSubjectRoomsList,
        TopToolbar,
        TooltipBtn,
        InvestmentSubjectPlanList,
        ObservableWatch,
        LinkedEvents,
        ItemsTable,
        FormActions,
        AcceptancesList,
        DataChangedWarningDialog,
        InvestmentSubjectChangesCatalogList,
        InvestmentSubjectChangesValuationsList,
        InvestmentSubjectChangesValuationForm,
        InvestmentSubjectReservationForm,
    },
})
export default class InvestmentSubjectDetails extends DetailsComponent<InvestmentSubject> {
    public store: InvestmentSubjectsState = this.$store.state.investmentsState.subjectsState;
    public loadingClient: boolean = false;
    public loadingSettings: boolean = false;
    public saveDialog: boolean = false;
    public actionsTypes = investmentSubjectsModule.actionsTypes;
    public mutationTypes = investmentSubjectsModule.mutationsTypes;
    public propertyMutationTypes = investmentSubjectPropertiesModule.mutationsTypes;
    public prospectSettingsActions = prospectSettingsModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public headers = investmentsObjectsListHeaders;
    public moduleProps: ModuleProps = investmentSubjectsModule.moduleProps;
    public parentObjects: InvestmentObject[] = [];
    public parents: string[] = [];
    public parseDate = parseDate;
    public tab: any = 0;
    public icons: object = {
        mdiHome,
    };
    public subjectCardDialog: boolean = false;
    public formKey = new Date().toString() + Math.random();

    public subjectPropertiesState = this.$store.state.investmentsState.subjectPropertiesState;
    public propertiesActions = investmentSubjectPropertiesModule.actionsTypes;

    public changesValuation: any = [];


    public units = [];
    public selected: Change[] = [];
    public dataChanged: boolean = false;
    public dataChangedWarning: string = '';

    public subjectReservationState = this.$store.state.investmentsState.subjectReservationState;
    public reservationMutationsTypes = investmentSubjectReservationModule.mutationsTypes;

    public oldPrivateStatus: string = '';

    public updatePublicStatus() {
        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, { id: this.id, public_status: this.item.public_status });
    }

    public openReservationEditForm() {
        this.reservationFormDialog = true;
    }

    public updatePrivateStatus() {
        if (this.item.private_status === 'reserved') {
            this.openReservationForm();
        } else {
            this.$store.dispatch(this.actionsTypes.UPDATE_ITEM,
            { id: this.id, private_status: this.item.private_status })
            .then((response) => {
                this.item = response.data;
            });
        }
    }

    public openPropertyForm() {
        this.propertyFormDialog = true;
        this.propertyEditedItem = {
            investment_subject_id: Number(this.id),
            public: false,
        };
    }

    get itemPrivateStatus() {
        return privateStatuses.find((status) => status.value === this.item.private_status);
    }

    get itemPublicStatus() {
        return publicStatuses.find((status) => status.value === this.item.public_status);
    }

    get loadingProperties() {
        return this.subjectPropertiesState.loading;
    }

    get editedItem() {
        return this.store.editedItem;
    }

    set editedItem(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, value);
    }

    get editedProspect(): ProspectDataRequest {
        return this.store.editedProspect;
    }

    set editedProspect(value) {
        this.$store.commit(this.mutationTypes.UPDATE_PROSPECT_EDITED, value);
    }

    get prospectFormDialog() {
        return this.store.prospectFormDialog;
    }

    set prospectFormDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_PROSPECT_FORM_DIALOG, value);
    }

    public cancelReservationForm() {
        if (!this.item.reserving_client_id) {
            this.item.private_status = this.oldPrivateStatus;
        }

        this.reservationFormDialog = false;
    }


    get removePropertyDialog() {
        return this.subjectPropertiesState.removeDialog;
    }

    set removePropertyDialog(value) {
        this.$store.commit(this.propertyMutationTypes.UPDATE_REMOVE_DIALOG, value);
    }

    get joinedAddressData() {
        const reservingClient = this.item.reserving_client!;
        return reservingClient.address && reservingClient.city && reservingClient.zip ? `${reservingClient.address} ${reservingClient.city} ${this.zipWithSeparator(reservingClient.zip)}`  : '-';
    }
    get propertyFormDialog() {
        return this.subjectPropertiesState.formDialog;
    }

    set propertyFormDialog(value) {
        this.$store.commit(this.propertyMutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get propertyEditedItem() {
        return this.subjectPropertiesState.editedItem;
    }

    // get privateStatus() {
    //     return this.item.private_status;
    // }

    // set privateStatus(value) {
    //     if(value !== 'reserved') {
    //         this.item.private_status = value;
    //     } else {
    //         this.updatePrivateStatus();
    //     }
    // }

    set propertyEditedItem(value) {
        this.$store.commit(this.propertyMutationTypes.UPDATE_EDITED, value);
    }


    get itemPrivateStatuses() {
        return privateStatuses;
    }

    get itemPublicStatuses() {
        return publicStatuses;
    }


    get formDialog() {
        return this.store.formDialog;
    }

    set formDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get subjectProperties() {
        return this.subjectPropertiesState.data;
    }

    public created() {
        this.fetchDetails();
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`investments.subjects.${action}`);
    }

    public beforeRouteLeave(from: any, to: any, next: any) {
        const channelNameWithPrefix = `presence-client.${this.$store.state.authState.user.client_id}.investment_subject.${this.item.id}`;
        echoInstance.leaveChannel(channelNameWithPrefix);
        next();
    }

    public download() {
        httpClient
            .get(`/subjects/${this.item.uuid}/card`, {
                headers: {},
                responseType: 'arraybuffer',
                method: 'GET',
            })
            .then((response) => {
                if (response.data && response.data instanceof ArrayBuffer) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'karta' + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
            });
    }

    public getParent(object: InvestmentObject) {
        this.parents.push(object.name);
        if (object.parent) {
            this.getParent(object.parent);
        }
    }

    public editItem() {
        this.editedItem = { ...this.item };
        this.formDialog = true;
    }

    public getObjects(object: InvestmentObject) {
        if (object) {
            this.parentObjects.push(object);
            if (object.parent) {
                this.getObjects(object.parent);
            }
        }
    }

    public getObjectLand(object: InvestmentObject): Land | null {
        if (object.land) {
            return object.land;
        } else {
            if (object.parent) {
                return this.getObjectLand(object.parent);
            } else {
                return null;
            }
        }
    }

    get prospectSettings() {
        return this.$store.state.investmentsState.prospectSettingsState.dataList;
    }

    public getProspectSettingValue(key: string, ids: number[] = []): any {
        const prospectSetting = this.prospectSettings.find(
            (e: ProspectSetting) =>
                e.key === key || (e.key === key && e.assignable_id && ids.includes(e.assignable_id)),
        );
        return prospectSetting ? prospectSetting.value : '';
    }

    public generateProspect() {
        const itemData = localStorage.getItem(`prospectForm_${this.item.uuid}`);
        if (itemData) {
            this.saveDialog = true;
        } else {
            const investment: Investment = this.item.investment || null;
            const land = this.item.land ? this.item.land : this.getObjectLand(this.item.object);

            let landRegisterInfo = '';
            if (land) {
                if (land.land_register_name) {
                    landRegisterInfo += `${land.land_register_name}\n`;
                }
                if (land.land_register_number) {
                    landRegisterInfo += prospectTexts.landRegisterNumber.replace('{1}', land.land_register_number);
                }
            }

            this.editedProspect = new ProspectDataRequest();

            const editedProspect = {
                land_id: land ? land.id : null,
                investment_subject_id: this.item ? this.item.id : null,
                investment_id: investment.id ? investment.id : null,
                investment_objects: this.parentObjects,
                has_no_plan: investment ? investment.has_no_plan : false,
                authorization_info:
                    investment && investment.authorization_number && investment.issuing_authority
                        ? investment.authorization_number + ', ' + investment.issuing_authority
                        : '',
                financial_institution_guarantee: investment.financial_institution_guarantee
                    ? investment.financial_institution_guarantee
                    : '',

                owned_means_institutions: investment.owned_means_institutions
                    ? investment.owned_means_institutions
                    : '',

                land_register_info: landRegisterInfo,
                cash_safety_rules: investment.cash_safety_rules ? investment.cash_safety_rules : '',
                number_of_buildings: investment.number_of_buildings ? investment.number_of_buildings : '',
                number_of_storeys: this.item.number_of_storeys ? this.item.number_of_storeys : '',
                law_status: land ? land.law_status || prospectTexts.lawStatusNotApplicable : '',
                planned_investments: land ? land.planned_investments : '',
                investment_schedule: investment.investment_schedule ? investment.investment_schedule : '',
                plan_purpose: land ? land.plan_purpose : '',
                acceptable_building_height: land ? land.acceptable_building_height : '',
                acceptable_building_percentage: land ? land.acceptable_building_percentage : '',
                location_of_apartment_in_building: this.item.location_of_apartment_in_building
                    ? this.item.location_of_apartment_in_building
                    : '',
                determination_of_position_standard: this.item.determination_of_position_standard
                    ? this.item.determination_of_position_standard
                    : '',
                media_available: investment.media_available ? investment.media_available : [],
                allowing_of_valorization: investment.allowing_of_valorization
                    ? investment.allowing_of_valorization
                    : false,
                principles_of_valorization: investment.allowing_of_valorization
                    ? investment.principles_of_valorization
                    : prospectTexts.valoritzationNotAllowed,
                terms_of_withdrawal_from_the_developer_contract:
                    investment.terms_of_withdrawal_from_the_developer_contract
                        ? investment.terms_of_withdrawal_from_the_developer_contract
                        : '',
                way_of_subject_measurement: investment.way_of_subject_measurement
                    ? investment.way_of_subject_measurement
                    : '',

                arrangement_of_buildings: investment.arrangement_of_buildings
                    ? investment.arrangement_of_buildings
                    : '',

                institution_of_cash_safety: investment.institution_of_cash_safety
                    ? investment.institution_of_cash_safety
                    : '',

                readable_documents: {
                    value: [] as string[],
                } as ProspectSettingDataRequest<string[]>,
                attachments: {
                    value: prospectDefaultAttachments,
                } as ProspectSettingDataRequest<string[]>,

                area_use: investment.area_use ? investment.area_use : '',
                area_use_intensity: investment.area_use_intensity ? investment.area_use_intensity : '',

                building_law_notification_number: investment.building_law_notification_number
                    ? investment.building_law_notification_number
                    : '',

                building_line_location: investment.building_line_location ? investment.building_line_location : '',

                buildings_and_land_development_function: investment.buildings_and_land_development_function
                    ? investment.buildings_and_land_development_function
                    : '',

                communication_requirements_and_rules: investment.communication_requirements_and_rules
                    ? investment.communication_requirements_and_rules
                    : '',

                cpk_dependent_investment_location_decision: investment.cpk_dependent_investment_location_decision
                    ? investment.cpk_dependent_investment_location_decision
                    : '',

                culture_protection_requirements: investment.culture_protection_requirements
                    ? investment.culture_protection_requirements
                    : '',

                dimensions: investment.dimensions ? investment.dimensions : '',

                flood_threat_requirements: investment.flood_threat_requirements
                    ? investment.flood_threat_requirements
                    : '',

                guarantee_fund_due_percentage: investment.guarantee_fund_due_percentage
                    ? investment.guarantee_fund_due_percentage
                    : '',

                is_notifiable_according_to_building_law: investment.is_notifiable_according_to_building_law
                    ? investment.is_notifiable_according_to_building_law
                    : false,

                manufacturing_technology: this.item.manufacturing_technology ? this.item.manufacturing_technology : '',

                max_and_min_buildings_intensity: investment.max_and_min_buildings_intensity
                    ? investment.max_and_min_buildings_intensity
                    : '',

                max_buildings_height: investment.max_buildings_height ? investment.max_buildings_height : '',

                method_of_buildings_and_land_development_use: investment.method_of_buildings_and_land_development_use
                    ? investment.method_of_buildings_and_land_development_use
                    : '',

                min_bioactive_area_percentage: investment.min_bioactive_area_percentage
                    ? investment.min_bioactive_area_percentage
                    : '',

                min_parking_spots_count: investment.min_parking_spots_count ? investment.min_parking_spots_count : '',

                neighbourhood_area_use: investment.neighbourhood_area_use ? investment.neighbourhood_area_use : '',

                neighbourhood_max_and_min_buildings_intensity: investment.neighbourhood_max_and_min_buildings_intensity
                    ? investment.neighbourhood_max_and_min_buildings_intensity
                    : '',

                neighbourhood_max_buildings_height: investment.neighbourhood_max_buildings_height
                    ? investment.neighbourhood_max_buildings_height
                    : '',

                neighbourhood_min_bioactive_area_percentage: investment.neighbourhood_min_bioactive_area_percentage
                    ? investment.neighbourhood_min_bioactive_area_percentage
                    : '',

                neighbourhood_min_parking_spots_count: investment.neighbourhood_min_parking_spots_count
                    ? investment.neighbourhood_min_parking_spots_count
                    : '',

                neighbourhood_objects_details: investment.neighbourhood_objects_details
                    ? investment.neighbourhood_objects_details
                    : '',

                no_plan_communication_requirements_and_rules: investment.no_plan_communication_requirements_and_rules
                    ? investment.no_plan_communication_requirements_and_rules
                    : '',

                no_plan_culture_protection_requirements: investment.no_plan_culture_protection_requirements
                    ? investment.no_plan_culture_protection_requirements
                    : '',

                no_plan_flood_threat_requirements: investment.no_plan_flood_threat_requirements
                    ? investment.no_plan_flood_threat_requirements
                    : '',

                no_plan_other_areas_protection_requirements: investment.no_plan_other_areas_protection_requirements
                    ? investment.no_plan_other_areas_protection_requirements
                    : '',

                no_plan_protection_conditions: investment.no_plan_protection_conditions
                    ? investment.no_plan_protection_conditions
                    : '',

                no_plan_technical_infrastructure_requirements_and_rules:
                    investment.no_plan_technical_infrastructure_requirements_and_rules
                        ? investment.no_plan_technical_infrastructure_requirements_and_rules
                        : '',

                other_areas_protection_requirements: investment.other_areas_protection_requirements
                    ? investment.other_areas_protection_requirements
                    : '',
                protection_conditions: investment ? investment.protection_conditions : '',

                provided_investments_commune_plan_info: investment.provided_investments_commune_plan_info
                    ? investment.provided_investments_commune_plan_info
                    : '',

                provided_investments_conditions_decision_info: investment.provided_investments_conditions_decision_info
                    ? investment.provided_investments_conditions_decision_info
                    : '',

                provided_investments_environmental_conditions_info:
                    investment.provided_investments_environmental_conditions_info
                        ? investment.provided_investments_environmental_conditions_info
                        : '',

                provided_investments_flood_threat_maps_info: investment.provided_investments_flood_threat_maps_info
                    ? investment.provided_investments_flood_threat_maps_info
                    : '',

                provided_investments_local_reconstruction_plan_info:
                    investment.provided_investments_local_reconstruction_plan_info
                        ? investment.provided_investments_local_reconstruction_plan_info
                        : '',

                provided_investments_plan_info: investment.provided_investments_plan_info
                    ? investment.provided_investments_plan_info
                    : '',

                provided_investments_restricted_use_act_info: investment.provided_investments_restricted_use_act_info
                    ? investment.provided_investments_restricted_use_act_info
                    : '',

                public_access_infrastructure_decision: investment.public_access_infrastructure_decision
                    ? investment.public_access_infrastructure_decision
                    : '',

                public_airport_decision: investment.public_airport_decision ? investment.public_airport_decision : '',

                public_antiflood_decision: investment.public_antiflood_decision
                    ? investment.public_antiflood_decision
                    : '',

                public_nuclear_object_location_decision: investment.public_nuclear_object_location_decision
                    ? investment.public_nuclear_object_location_decision
                    : '',

                public_oil_industry_location_decision: investment.public_oil_industry_location_decision
                    ? investment.public_oil_industry_location_decision
                    : '',

                public_railway_line_location_decision: investment.public_railway_line_location_decision
                    ? investment.public_railway_line_location_decision
                    : '',

                public_regional_broadband_location_decision: investment.public_regional_broadband_location_decision
                    ? investment.public_regional_broadband_location_decision
                    : '',

                public_road_access: investment.public_road_access ? investment.public_road_access : false,

                public_road_decision: investment.public_road_decision ? investment.public_road_decision : '',

                public_transmission_network_location_decision: investment.public_transmission_network_location_decision
                    ? investment.public_transmission_network_location_decision
                    : '',

                selected_legal_act: investment.selected_legal_act ? investment.selected_legal_act : '',

                selected_legal_act_details: investment.selected_legal_act_details
                    ? investment.selected_legal_act_details
                    : '',

                technical_infrastructure_requirements_and_rules:
                    investment.technical_infrastructure_requirements_and_rules
                        ? investment.technical_infrastructure_requirements_and_rules
                        : '',

                flats_count: this.item.flats_count ? this.item.flats_count : '',
                price: this.item.price ? this.item.price : 0,
                owned_means: investment.owned_means ? investment.owned_means : '',
                architectural_form: investment.architectural_form ? investment.architectural_form : '',

                build_approval_due_date: investment.build_approval_due_date ? investment.build_approval_due_date : '',
                end_date: investment.end_date ? investment.end_date : '',

                self_reliance_certificate_issue_date: this.item.self_reliance_certificate_issue_date
                    ? this.item.self_reliance_certificate_issue_date
                    : '',

                separate_ownership_establishment_date: this.item.separate_ownership_establishment_date
                    ? this.item.separate_ownership_establishment_date
                    : '',

                are_business_premises_bought_along: this.item.are_business_premises_bought_along
                    ? this.item.are_business_premises_bought_along
                    : false,

                business_premises_info: this.item.business_premises_info ? this.item.business_premises_info : '',

                business_premises_or_its_fraction_price: this.item.business_premises_or_its_fraction_price
                    ? this.item.business_premises_or_its_fraction_price
                    : '',

                final_transfer_of_business_premises_ownership_date: this.item
                    .final_transfer_of_business_premises_ownership_date
                    ? this.item.final_transfer_of_business_premises_ownership_date
                    : '',

                area: this.item.area ? this.item.area : 0,
                parking_spots_count: this.item.parking_spots_count ? this.item.parking_spots_count : '',

                escrow_account_bank_name: investment.escrow_account_bank_name
                    ? investment.escrow_account_bank_name
                    : '',

                escrow_bank_trademark: investment.escrow_bank_trademark ? investment.escrow_bank_trademark : '',

                bank_consent_information: investment.bank_consent_information
                    ? investment.bank_consent_information
                    : '',

                specific_bank_consent_information: investment.specific_bank_consent_information
                    ? investment.specific_bank_consent_information
                    : '',

                price_per_meter: this.item.price_per_meter ? this.item.price_per_meter : '',
                finishing_standard: this.item.finishing_standard ? this.item.finishing_standard : '',

                build_approval: investment.build_approval ? investment.build_approval : '',

                is_build_approval_contested: investment.is_build_approval_contested
                    ? investment.is_build_approval_contested
                    : false,

                is_build_approval_final: investment.is_build_approval_final
                    ? investment.is_build_approval_final
                    : false,

                is_bank_bill_open: investment.is_bank_bill_open ? investment.is_bank_bill_open : false,
            } as ProspectDataRequest;

            this.editedProspect = Object.assign(this.editedProspect, editedProspect);

            if (land && land.plot_address) {
                this.editedProspect.plot_address = prospectTexts.landAddress;
                this.editedProspect.plot_address = this.editedProspect.plot_address.replace('{1}', land.plot_address);
                this.editedProspect.plot_address = this.editedProspect.plot_address.replace('{2}', land.plot_number);
            }

            if (investment && investment.planned_start_date && investment.planned_end_date) {
                this.editedProspect.planned_build_dates = prospectTexts.plannedBuildDates.replace(
                    '{1}',
                    parseDate(investment.planned_start_date),
                );
                this.editedProspect.planned_build_dates = this.editedProspect.planned_build_dates.replace(
                    '{2}',
                    parseDate(investment.planned_end_date),
                );
            }

            if (this.item.transfer_of_ownership_date) {
                this.editedProspect.transfer_of_ownership_date = prospectTexts.transferOfOwnershipDate.replace(
                    '{1}',
                    this.item.transfer_of_ownership_date,
                );
            }

            this.loadingClient = true;
            httpClient
                .get('api/v1/admin/client')
                .then((res) => res.data)
                .then(({ data }: ModuleShowPayload<Client>) => {
                    this.loadingClient = false;
                    if (data) {
                        this.editedProspect = {
                            ...this.editedProspect,
                            nip: data.nip || '',
                            regon: data.regon || '',
                            phone: data.phone || '',
                            email: data.email || '',
                            fax: data.fax || '',
                            website: data.website_address || '',
                        };

                        this.$set(this.editedProspect, 'company_name', data.company_name);

                        if (data.krs || data.nip) {
                            this.editedProspect.company_name += `\nNr ${data.krs ? data.krs : data.nip}`;
                        }

                        if (data.address) {
                            this.editedProspect.address = data.address;
                        }

                        if (data.zip && data.city) {
                            this.editedProspect.address += `\n${data.zip} ${data.city}`;
                        }

                        this.loadingSettings = true;
                        this.$store
                            .dispatch(this.prospectSettingsActions.FETCH_DATA, { simple: true })
                            .then((response: ListPayload<ProspectSetting>) => {
                                this.loadingSettings = false;
                                if (response.data) {
                                    this.editedProspect = {
                                        ...this.editedProspect,
                                        completed_investment_address: {
                                            value: this.getProspectSettingValue('completed_investment_address'),
                                        } as ProspectSettingDataRequest<string>,
                                        completed_investment_start_date: {
                                            value: this.getProspectSettingValue('completed_investment_start_date'),
                                        } as ProspectSettingDataRequest<string>,
                                        completed_investment_end_date: {
                                            value: this.getProspectSettingValue('completed_investment_end_date'),
                                        } as ProspectSettingDataRequest<string>,
                                        another_completed_investment_address: {
                                            value: this.getProspectSettingValue('another_completed_investment_address'),
                                        } as ProspectSettingDataRequest<string>,
                                        another_completed_investment_start_date: {
                                            value: this.getProspectSettingValue(
                                                'another_completed_investment_start_date',
                                            ),
                                        } as ProspectSettingDataRequest<string>,
                                        another_completed_investment_end_date: {
                                            value: this.getProspectSettingValue(
                                                'another_completed_investment_end_date',
                                            ),
                                        } as ProspectSettingDataRequest<string>,
                                        last_completed_investment_address: {
                                            value: this.getProspectSettingValue('last_completed_investment_address'),
                                        } as ProspectSettingDataRequest<string>,
                                        last_completed_investment_start_date: {
                                            value: this.getProspectSettingValue('last_completed_investment_start_date'),
                                        } as ProspectSettingDataRequest<string>,
                                        last_completed_investment_end_date: {
                                            value: this.getProspectSettingValue('last_completed_investment_end_date'),
                                        } as ProspectSettingDataRequest<string>,
                                        is_enforcement_proceedings: {
                                            value: Boolean(this.getProspectSettingValue('is_enforcement_proceedings')),
                                        } as ProspectSettingDataRequest<boolean>,
                                        readable_documents_description: {
                                            value: this.getProspectSettingValue('readable_documents_description'),
                                        } as ProspectSettingDataRequest<string>,
                                    };

                                    if (land) {
                                        this.$set(this.editedProspect, 'existing_mortgage_load', {
                                            assignable_id: land.id,
                                            value: this.getProspectSettingValue('existing_mortgage_load', [land.id]),
                                            remember: false,
                                        } as ProspectSettingDataRequest<string>);
                                    }

                                    const attachments = this.getProspectSettingValue('attachments');

                                    if (attachments) {
                                        this.$set(this.editedProspect, 'attachments', {
                                            value: attachments,
                                        } as ProspectSettingDataRequest<string[]>);
                                    }

                                    const readableDocuments = this.getProspectSettingValue('readable_documents');

                                    if (readableDocuments) {
                                        this.$set(this.editedProspect, 'readable_documents', {
                                            value: readableDocuments,
                                        } as ProspectSettingDataRequest<string[]>);
                                    }

                                    this.prospectFormDialog = true;
                                }
                            })
                            .catch((err) => {
                                this.loadingSettings = false;
                                logger.error(err);
                            });
                    }
                })
                .catch((err) => {
                    this.loadingClient = false;
                    logger.error(err);
                });
        }
    }

    public roomsSaved(next: boolean) {
        this.fetchDetails();
        if (!next) { return; }
        this.$nextTick(() => {
            this.$set(this.$store.state.investmentsState.subjectRoomsState, 'formDialog', true);
        });
    }

    public abortSavedItemData() {
        localStorage.removeItem(`prospectForm_${this.item.uuid}`);
        localStorage.removeItem(`prospectFormStep_${this.item.uuid}`);
        this.saveDialog = false;
        this.generateProspect();
    }

    public closeProspectDialog() {
        localStorage.removeItem(`prospectForm_${this.item.uuid}`);
        localStorage.removeItem(`prospectFormStep_${this.item.uuid}`);
        this.prospectFormDialog = false;
    }

    public restoreSavedItemData() {
        const itemData = localStorage.getItem(`prospectForm_${this.item.uuid}`);
        if (itemData) {
            this.saveDialog = false;
            this.editedProspect = JSON.parse(itemData);
            this.prospectFormDialog = true;
        }
    }

    public reservationItemSaved({ data }: SaveResponse<InvestmentSubject>) {
        this.reservationEditedItem = {
            id: Number(this.id),
            investment_subject_id: Number(this.id),
            reserving_client_id: data.reserving_client_id,
            reservation_start_date: data.reservation_start_date,
            reservation_end_date: data.reservation_end_date,
        };

        this.item = data;
        this.reservationFormDialog = false;

        this.$forceUpdate();
    }

    public openReservationForm() {
        if (!!this.item.reserving_client_id) {
            this.reservationEditedItem = {
                id: Number(this.id),
                investment_subject_id: Number(this.id),
                reserving_client_id: this.item.reserving_client_id,
                reservation_start_date: this.item.reservation_start_date,
                reservation_end_date: this.item.reservation_end_date,
            };
        } else {
            this.reservationEditedItem = {
                id: Number(this.id),
                investment_subject_id: Number(this.id),
                ...this.reservationEditedItem
            };
        }


        this.reservationFormDialog = true;
    }

    get reservationFormDialog() {
        return this.subjectReservationState.formDialog;
    }

    set reservationFormDialog(value) {
        this.$store.commit(this.reservationMutationsTypes.UPDATE_FORM_DIALOG, value);
    }

    get reservationEditedItem() {
        return this.subjectReservationState.editedItem;
    }

    set reservationEditedItem(value) {
        this.$store.commit(this.reservationMutationsTypes.UPDATE_EDITED, value);
    }

    public editProperty(property: InvestmentSubjectProperty) {
        this.propertyFormDialog = true;
        this.propertyEditedItem = Object.assign({}, property);
    }

    public removeProperty(property: InvestmentSubjectProperty) {
        this.removePropertyDialog = true;
        this.propertyEditedItem = property;
    }

    public removePropertyRequest() {
        this.$store
            .dispatch(this.propertiesActions.REMOVE_ITEM, this.propertyEditedItem.id)
            .then((response) => {
                if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                    this.fetchProperties();
                    this.removePropertyDialog = false;
                }
            })
            .catch((err) => logger.error(err));
    }

    public propertyItemSaved() {
        this.propertyFormDialog = false;
        this.fetchProperties();
    }

    public fetchProperties() {
        this.$store.dispatch(this.propertiesActions.FETCH_DATA, {
            id: this.id,
        });
    }

    public fetchCallback(response: ModuleShowPayload<InvestmentSubject>) {
        this.parents = [];

        const channelName = `client.${this.$store.state.authState.user.client_id}.investment_subject.${this.$store.state.investmentsState.subjectsState.current.id}`;

        echoInstance.join(channelName)
        .listen('.investment_subject_updated', (e: any) => {
            this.dataChanged = true;
            this.dataChangedWarning = e.description;
        });
        this.fetchProperties();
        if (response.data) {
            this.getObjects(this.item.object);
            this.getParent(this.item.object);
        }
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }

    public onPlanSelection() {
        this.fetchDetails();
    }

    public zipWithSeparator(zip: string) {
        if (zip && typeof zip === 'string') {
            return [zip.slice(0, 2), zip.slice(2)].join('-');
        }
    }

    @Watch('formDialog')
    public onFormDialogChange(val: boolean) {
        if (val) {
            this.formKey =  new Date().toString() + Math.random();

        }
    }

    @Watch('item.private_status')
    public onPrivateStatusUpdate(newStatus: string, oldStatus: string) {
        this.oldPrivateStatus = oldStatus;
    }
}
