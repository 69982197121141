
    import { Component } from 'vue-property-decorator';
    import { contractorsModule } from '../shared/state/module';
    import { contractorsListHeaders } from '@/modules/contractors/shared/config';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import ContractorDetails from '@/modules/contractors/components/details.vue';
    import ContractorForm from '@/modules/contractors/components/form.vue';
    import { ContractorDataRequest } from '@/modules/contractors/shared/requests/contractor-data-request';
    import { contractorsListFilters } from '@/modules/contractors/shared/config/filters';
    import { ModuleProps } from '@/shared/state/template/module-props';
    import { Contractor } from '../shared/models/contractor';
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { ListFilter } from '@/shared/types';

    import UserDialog from '../../admin/components/users/card.vue';

    @Component({
        components: {
            ItemsTable,
            ContractorDetails,
            ContractorForm,
            UserDialog,
        },
    })
    export default class ContractorsList extends ListComponent<
        Contractor,
        ContractorDataRequest
    > {
        public headers: object[] = contractorsListHeaders;
        public store = this.$store.state.contractorsState;
        public actionsTypes = contractorsModule.actionsTypes;
        public mutationTypes = contractorsModule.mutationsTypes;
        public fetchAction = this.actionsTypes.FETCH_DATA;
        public removeAction = this.actionsTypes.REMOVE_ITEM;
        public props: ModuleProps = contractorsModule.moduleProps;
        public filterConfig: ListFilter[] = contractorsListFilters;

        public guardianDialog: any = null;

        public created() {
            this.fetchData();
        }

        get mainWarning(): string {
            return this.store.current && this.store.current.related_investments ? 'Kontrahent powiązany z inwestycją' : '';
        }
    }
