var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":"","loading":_vm.commentsLoading,"tile":""}},[_c('div',{ref:"messagesWrapper",staticClass:"messages--wrapper"},[_c('v-slide-x-transition',{attrs:{"group":"","mode":"out-in"}},_vm._l((_vm.comments),function(comment){return _c('message-card',{key:comment.id,attrs:{"from":comment.user,"message":comment.content,"createdAt":comment.created_at,"updatedAt":comment.updated_at,"isEdited":_vm.isEdited === comment.id,"ownerId":comment.user.id},on:{"delete":() => {
                        _vm.deleteComment(comment.id);
                    },"edit":() => {
                        _vm.editComment(comment.id, comment.content);
                    },"acceptEdit":() => {
                        _vm.acceptEditComment(comment.id);
                    },"cancel":() => {
                        _vm.isEdited = null;
                    }},model:{value:(_vm.editedComment),callback:function ($$v) {_vm.editedComment=$$v},expression:"editedComment"}})}),1),_c('infinite-scroll',{directives:[{name:"show",rawName:"v-show",value:(_vm.comments.length > 0),expression:"comments.length > 0"}],key:"infinite-scroll",attrs:{"url":_vm.url,"meta":_vm.meta,"root":_vm.messagesWrapper},on:{"fetchSuccess":_vm.appendExtra,"update:meta":function($event){_vm.meta=$event}}},[_c('comment-loader')],1)],1),_c('v-divider'),_c('v-footer',{attrs:{"padless":"","elevation":"2"}},[_c('v-card',{staticClass:"pa-4 d-flex align-start tooltip-mbl",staticStyle:{"width":"100%"}},[_c('v-textarea',{attrs:{"label":"Komentarz","auto-grow":"","hide-details":"","outlined":"","disabled":!_vm.isPermitted('create')},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return ((e) => {
                        const keyCode = e.keyCode;
                        if (keyCode === 13 && !e.shiftKey) {
                            e.preventDefault();
                            _vm.sendComment();
                        }
                    }).apply(null, arguments)}},model:{value:(_vm.commentMessage),callback:function ($$v) {_vm.commentMessage=$$v},expression:"commentMessage"}}),_c('tooltip-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['enter']),expression:"['enter']"}],attrs:{"tooltipDisabled":_vm.isPermitted('create'),"tooltipText":"Brak uprawnień","disabled":!_vm.isPermitted('create'),"content-class":"ma-4 mt-0","color":"primary"},on:{"shortkey":_vm.sendComment,"click":_vm.sendComment}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.name !== 'xs' ? 'Wyślij' : '')+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-send")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }