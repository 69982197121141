
import {Component, Watch, Vue, Inject, Prop, Ref} from 'vue-property-decorator';
import ticket from './ticket.vue';
import draggable from 'vuedraggable';
import {ticketsModule} from '@/modules/tickets/shared/state/module';
import {httpClient, logger} from '@/shared/services';
import {Ticket} from '../../shared';
import {TicketsState} from '../../shared/state/state';
import {objectToQueryString} from '@/shared/helpers';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import TicketLoader from '@/shared/components/loaders/ticket-loader.vue';
import {MetaItem} from '@/shared/types';
import {TicketStatusWithTickets} from '@/modules/tickets/shared/models/status';
import {AxiosResponse} from 'axios';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {SortableEvent} from 'sortablejs';

@Component({
    components: {
        draggable,
        ticket,
        TooltipBtn,
        InfiniteScroll,
        TicketLoader,
    },
})
export default class Table extends Vue {
    @Inject() public readonly permissionCheck!: (arg1: string) => boolean;
    @Inject() public readonly ticketsEventBus!: Vue;
    @Ref() public scrollable!: HTMLElement;
    @Prop(String) public title!: string;
    @Prop(Number) public statusIndex!: number;
    @Prop(Object) public status!: TicketStatusWithTickets;
    @Prop(String) public filters!: string;
    @Prop(String) public search!: string;
    @Prop(Boolean) public statusLoading!: boolean;
    @Prop(Boolean) public searchReload!: boolean;

    public meta = {} as MetaItem;
    public actionsTypes = ticketsModule.actionsTypes;
    public mutationsTypes = ticketsModule.mutationsTypes;
    public isLoading = false;

    public headerMouseOver = false;
    public store: any = null;
    public selectedItem: Ticket | null = null;
    public stopInfiniteScroll = false;
    public dragOptions = {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    };

    get ticketsCount() {
        return this.status.tickets_count;
    }

    get items() {
        return this.status.data;
    }

    get statusId() {
        return this.status.id;
    }

    get isAnyTicketDragged() {
        return this.store.isAnyTicketDragged;
    }

    set isAnyTicketDragged(value) {
        this.$store.commit(this.mutationsTypes.SET_ANY_DRAGGED, value);
    }

    get tableToRefresh() {
        return this.store && this.store.statusWithNewTicket === this.statusIndex;
    }

    get fetchParams() {
        return {filters: {...JSON.parse(this.filters), search: this.search}};
    }

    public created() {
        this.store = this.$store.state.ticketsState as TicketsState;
        this.ticketsEventBus.$on('ticketDropped', (statusId: number) => {
            if (this.statusId !== statusId) {
                return;
            }
        });
    }

    public createTicket() {
        this.$emit('createTicket', this.statusId);
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`tickets.${action}`);
    }

    public fetchData() {
        this.stopInfiniteScroll = true;
        if (this.tableToRefresh) {
            this.$store.state.ticketsState.statusWithNewTicket = null;
        }

        this.isLoading = true;

        this.scrollable.scroll(0, 0);

        let url = `/api/v1/tickets/statuses/${this.statusId}/tickets?`;
        url += objectToQueryString(this.fetchParams);
        httpClient
            .get(url)
            .then((response: AxiosResponse<ModuleFetchPayload<Ticket>>) => response.data)
            .then((response) => {
                if (response.data && response.meta) {
                    this.isLoading = false;
                    if (this.status) {
                        this.status.data = response.data;
                        this.status.tickets_count = response.meta.total;
                    }
                    this.meta = response.meta;
                }
            })
            .catch((err) => {
                logger.error(err);
                this.isLoading = false;
            });
        this.stopInfiniteScroll = false;
    }

    public appendExtra({data}: any) {
        if (this.status) {
            this.status.data.push(...data);
        }
    }

    public onDrag(e: SortableEvent) {
        if (e.oldIndex !== undefined) {
          const vMain = document.querySelector('.v-main');
          vMain?.appendChild(e.from.querySelector('.sortable-fallback') as Element);
          this.selectedItem = this.items[e.oldIndex];
          this.store.isAnyTicketDragged = true;
        }
    }

    public async onDrop(e: SortableEvent) {
        const oldParent = e.from;
        const newParent = e.to;
        this.isAnyTicketDragged = false;
        if (oldParent === newParent || !this.selectedItem) {
            return;
        }
        let newStatusId: any;
        newParent.className.split(' ').forEach((element: string) => {
            if (element.includes('status-')) {
                newStatusId = parseInt(element.split('-')[1], 10);
            }
        });

        if (newStatusId === undefined) {
            return;
        }

        const itemIndex = this.items.map(({id}) => id).indexOf(this.selectedItem.id);

        if (itemIndex === -1) {
            return;
        }


        this.$emit('update-statuses', {
            oldStatusId: this.statusId,
            newStatusId,
            itemIndex,
            selectedItem: this.selectedItem,
        });


        await this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, {
            id: this.selectedItem.id,
            ticket_status_id: newStatusId,
        });

        if (this.ticketsCount < 10 && this.items.length !== this.ticketsCount) {
            this.fetchData();
        }

        this.selectedItem = null;
        this.ticketsEventBus.$emit('ticketDropped', newStatusId);

    }

    public onRemove(id: number) {
        if (this.status) {
            this.status.data = this.items.filter((el: Ticket) => {
                return el.id !== id;
            });
        }
    }

    @Watch('isLoading')
    public onIsLoadingChanged(val: boolean) {
        this.$emit('loading', !val);
    }

    @Watch('tableToRefresh')
    public onTableToRefreshChange(val: any) {
        if (!val) {
            return;
        }
        this.fetchData();
    }

    @Watch('searchReload')
    public onSearchReload(val: boolean) {
        if (val === false) {
            this.isLoading = false;
        } else {
            this.isLoading = true;
        }
    }

}
