

import {Component, Watch} from 'vue-property-decorator';
import SelectMediaList from '@/modules/media/components/select.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {InvestmentObjectMediaFile} from '@/modules/investments/shared/models/objects/investment-object-media-file';
import {ModuleState} from '@/shared/state/template/module-state';
import {
    InvestmentObjectMediaFileDataRequest
} from '@/modules/investments/shared/requests/objects/investment-object-media-file-data-request';
import {ModuleProps} from '@/shared/state/template/module-props';
import {investmentObjectMediaFilesModule} from '@/modules/investments/shared/state/submodules';
import {getIcon} from '@/modules/media/shared/helpers';
import ObjectMediaCarousel from '@/modules/investments/components/objects/media/carousel.vue';
import {MediaFileSelect} from '@/modules/media/shared/models/media-file-select';
import {MediaFile} from '@/modules/media/shared/models/media-file';

@Component({
    components: {
        SelectMediaList,
        ObjectMediaCarousel,
    },
    props: {
        parentObjectId: Number,
    },
})
export default class InvestmentObjectMedia extends ListComponent<InvestmentObjectMediaFile,
    InvestmentObjectMediaFileDataRequest> {
    public props: ModuleProps = investmentObjectMediaFilesModule.moduleProps;
    public actionsTypes = investmentObjectMediaFilesModule.actionsTypes;
    public objectsState = this.$store.state.investmentsState.objectsState;
    public mutationTypes = investmentObjectMediaFilesModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers: object[] = [];
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store: ModuleState<InvestmentObjectMediaFile, InvestmentObjectMediaFileDataRequest> =
        this.$store.state.investmentsState.objectMediaFilesState;
    public mediaSelectDialog: boolean = false;
    public mediaDialog: boolean = false;
    public dialogId: number = 0;
    public getIcon = getIcon;
    public parentObjectId!: number;

    public created() {
        this.id = this.parentObjectId;
        this.$store.dispatch(this.fetchAction, {id: this.id, simple: true});
        this.fetchData();
    }

    public pageChange(page: number) {
        this.page = page;
    }

    public itemInParent(item: MediaFile) {
        const mediaFile = this.itemsList.find((e: InvestmentObjectMediaFile) => {
            return e.media_file_id === item.id;
        });

        return mediaFile ? mediaFile.investment_object_id === this.objectsState.current.parent_id : false;
    }

    public openDetails(index: number) {
        this.dialogId = index;
        this.mediaDialog = true;
    }

    public addMedia(items: number[]) {
        this.mediaSelectDialog = false;
        const mediaFiles = items.map((e: number) => {
            return {media_file_id: e} as InvestmentObjectMediaFile;
        });
        const objectMediaRequest: InvestmentObjectMediaFileDataRequest = new InvestmentObjectMediaFileDataRequest();
        objectMediaRequest.investment_object_id = this.id || 0;
        objectMediaRequest.investment_object_media_files = mediaFiles;
        this.$store.dispatch(this.actionsTypes.STORE_ITEM, objectMediaRequest).then(() => {
            this.page = 1;
            this.$store.dispatch(this.actionsTypes.FETCH_DATA, {id: this.id, simple: true});
            this.fetchData();
        });
    }

    get mediaList(): MediaFileSelect[] {
        return this.itemsList.map((item: InvestmentObjectMediaFile) => item.media_file) as MediaFileSelect[];
    }

    get itemsList(): InvestmentObjectMediaFile[] {
        return this.store.dataList.filter((value: any) => value.media_file) as InvestmentObjectMediaFile[];
    }

    @Watch('parentObjectId')
    public onObjectIdChange(value: number) {
        this.id = this.parentObjectId;
        this.$store.dispatch(this.fetchAction, {id: this.id, simple: true});
        this.fetchData();
    }

    get items() {
        return this.store.data ? this.store.data.filter((item) => item.media_file) : [];
    }
}

