
import {Component} from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleState} from '@/shared/state/template/module-state';
import {investmentTagsModule} from '@/modules/investments/shared/state/submodules';
import {tagsListHeaders} from '@/modules/investments/shared/config';
import {InvestmentTag} from '@/modules/investments/shared/models/investment-tag';
import {InvestmentTagDataRequest} from '@/modules/investments/shared/requests/investment-tag-data-request';
import InvestmentTagForm from './form.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';

@Component({
    components: {
        ItemsTable,
        InvestmentTagChip,
        InvestmentTagForm,
    },
})
export default class InvestmentTagsList extends ListComponent<InvestmentTag, InvestmentTagDataRequest> {
    public headers = tagsListHeaders;
    public actionsTypes = investmentTagsModule.actionsTypes;
    public mutationTypes = investmentTagsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store: ModuleState<InvestmentTag, InvestmentTagDataRequest> =
        this.$store.state.investmentsState.investmentTagsState;
    public props: ModuleProps = investmentTagsModule.moduleProps;
}
