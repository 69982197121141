

import {Component, Prop} from 'vue-property-decorator';
import TemplateObjectForm from '@/modules/investments/components/generator/templates/template/template-object-form';
import {BlockInvestmentStage} from '@/modules/investments/components/generator/templates/blocks/models/types';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import {objectVariables} from '@/shared/config/investments';
import {VariableFieldType} from '@/shared/types';
import FormActions from './form-actions.vue';

@Component({
    components: {
        VariableTextField,
        FormActions,
    },
})
export default class BlockInvestmentStageForm extends TemplateObjectForm<BlockInvestmentStage> {
    @Prop(Boolean) public checked!: boolean;
    public variables: VariableFieldType[] = objectVariables;
}

