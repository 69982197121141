
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {knownFieldNames} from '@/shared/config/investments';

@Component({
    props: {
        itemData: Object,
        propertyKey: String,
    }
})
export default class PropertyCheckbox extends Vue {
    public knownFieldNames = knownFieldNames;
}
