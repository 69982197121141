
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';

import {statusesModule, statusListHeaders, TicketStatus, TicketStatusDataRequest} from '@/modules/tickets/shared';
import StatusForm from './form.vue';
import ChangeClosingStatuses from './change-closing-statues.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
  components: {
    ItemsTable,
    StatusForm,
    ChangeClosingStatuses,
    TooltipBtn,
  },
})
export default class TicketStatusesList extends ListComponent<TicketStatus, TicketStatusDataRequest> {
  public headers: object[] = statusListHeaders;
  public actionsTypes = statusesModule.actionsTypes;
  public mutationTypes = statusesModule.mutationsTypes;
  public fetchAction: string = this.actionsTypes.FETCH_DATA;
  public removeAction: string = this.actionsTypes.REMOVE_ITEM;
  public store: any = this.$store.state.ticketsState.statusesState;
  public props: ModuleProps = statusesModule.moduleProps;
  public loadingSetNewDefaultPriority = false;
  public changeClosingDialog = false;

  public mounted() {
    this.fetchData();
  }

  public setNewDefaultPriority(id: number) {
    this.loadingSetNewDefaultPriority = true;
    this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, {
      id,
      default: true,
    })
        .then(() => {
          this.fetchData();
          this.$store.commit('SHOW_SNACKBAR', {type: 'success', text: 'Zmienionio domyślny priorytet'});
        })
        .catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {type: 'error', text: 'Coś poszło nie tak'});
          this.loadingSetNewDefaultPriority = false;
        });
  }

  public goToTickets() {
    this.store.relatedDialog = false;
    this.$router.push({name: 'tickets-list'});
  }
}
