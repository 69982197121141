
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import {investmentSubjectTypesModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubjectType} from '@/modules/investments/shared/models/subjects/investment-subject-type';
import {InvestmentSubjectTypeDataRequest} from '@/modules/investments/shared/requests/subjects/investment-subject-type-data-request';
import {investmentSubjectsTypeListHeaders} from '@/modules/investments/shared/config';
import SubjectTypeForm from './form.vue';

@Component({
    components: {
        ItemsTable,
        SubjectTypeForm,
    },
})
export default class SubjectTypesList extends ListComponent<InvestmentSubjectType,
    InvestmentSubjectTypeDataRequest> {
    public headers: object[] = investmentSubjectsTypeListHeaders;
    public actionsTypes = investmentSubjectTypesModule.actionsTypes;
    public mutationTypes = investmentSubjectTypesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.investmentsState.subjectTypesState;
    public props: ModuleProps = investmentSubjectTypesModule.moduleProps;
}
