

import {Component} from 'vue-property-decorator';
import TemplateObjectForm from '@/modules/investments/components/generator/templates/template/template-object-form';
import {BlockInvestmentBuilding} from '@/modules/investments/components/generator/templates/blocks/models/types';
import {VariableFieldType} from '@/shared/types';
import VariableTextField from '@/shared/components/elements/variable-text-field.vue';
import {objectVariables} from '@/shared/config/investments';
import {validLesserThanZeroLevel, validMinLevel} from '@/modules/investments/components/generator/rules';
import FormActions from './form-actions.vue';

@Component({
    components: {FormActions, VariableTextField},
})
export default class BlockInvestmentBuildingForm extends TemplateObjectForm<BlockInvestmentBuilding> {
    public variables: VariableFieldType[] = objectVariables;

    public validLesserThanZeroLevel = validLesserThanZeroLevel;
    public validMinLevel = validMinLevel;
}

