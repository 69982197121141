
import { HeaderFieldType } from '@/shared/types';

export const landListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa gruntu', value: 'name', align: 'center' },
    { text: 'Adres ewidencyjny', value: 'plot_address', align: 'center' },
    { text: 'Numer ewidencyjny', value: 'plot_number', align: 'center' },
    { text: 'Inwestycja', value: 'investment', align: 'center' },
    { text: 'Powiązany obiekt', value: 'assignable', align: 'center' },
    { text: 'Powierzchnia działki', value: 'plot_area', align: 'center' },
    { text: 'Status', value: 'status', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];
