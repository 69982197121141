
import FormComponent from '@/shared/components/layout/form/form-component';
import Component from 'vue-class-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {investmentSubjectOperationsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubjectMoveOperationDataRequest} from '@/modules/investments/shared/requests/subjects/operations';
import {logger} from '@/shared/services';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';

import PreventUnload from '@/shared/PreventUnload.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        PreventUnload
    },
})
export default class MoveSubjectsForm extends FormComponent<InvestmentSubjectMoveOperationDataRequest> {
    public store = this.$store.state.investmentsState.subjectOperationsState;
    public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
    public objectError = false;
    public openObject: InvestmentObject[] = [];
    public storeAction: string = '';
    public updateAction: string = '';
    public requestData = new InvestmentSubjectMoveOperationDataRequest();

    get investments() {
        return this.$store.state.investmentsState.dataList;
    }

    get objects() {
        return this.$store.state.investmentsState.objectsState.dataList;
    }

    get formTitle() {
        return 'Przenieś przedmioty';
    }

    public updateObject(e: InvestmentObject[]) {
        this.itemData.investment_object_id = e[0];
        if (e && e[0] && !this.openObject.includes(e[0]) && e[0].children.length > 0) {
            this.openObject.push(e[0]);
        }
        this.$forceUpdate();
    }

    public initForm() {
        this.form = this.$refs.moveSubjectsForm;
         }

    public async submitForm(e: any, next?: boolean) {
        if (!this.form || this.busy) {
            return;
        }

        const item = JSON.parse(JSON.stringify(this.itemData));

        item.investment_object_id = item.investment_object_id.id;
        item.selection = item.selection.map((subject: InvestmentSubject) => subject.id);

        await this.form.validate();

        if (this.valid) {
            this.busy = true;

            this.$store.dispatch(this.actionsTypes.MOVE_SUBJECTS_CHECK, item)
                .then((response) => {
                    if (response && response === 200) {
                        this.valid = true;
                        this.$router.push({name: 'move-subjects-summary'});
                    }
                })
                .catch((err) => logger.error(err))
                .then(() => {
                    this.busy = false;
                });
        }
    }

    public created() {
        //
    }
}
