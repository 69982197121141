
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: { NameReduce },
})
export default class ShowMore extends Vue {
    @Prop(String) public icon!: string;
    @Prop(Array) public items!: any[];
    @Prop(Boolean) public isMobile!: boolean;
    @Prop({ type: Number, default: 4 }) public baseDivision!: number;
    @Prop(String) public hrefPrefix!: string;

    public showMoreItems = false;

    get division() {
        if (this.isMobile) {
            return 2;
        } else {
            return this.baseDivision;
        }
    }

    get cols() {
        return 12 / this.division;
    }

    get maxWidth() {
        return `${(400 - 84) / this.division}px`;
    }
}
