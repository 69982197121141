
import {Component, Prop, Vue} from 'vue-property-decorator';
import { Investment } from '../shared/models/investment';
import { parseDate } from '@/shared/helpers';
import TotalCosts from '@/modules/investments/components/total-costs.vue';
import { costStatuses } from '@/shared/config/costs';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';

@Component({
    components: {
        TotalCosts,
        InvestmentTagChip,
    }
})
export default class InvestmentSimpleDetails extends Vue {
    @Prop(Object) public investment!: Investment;
    public parseDate = parseDate;
    public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));


}
