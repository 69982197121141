var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('items-table',{attrs:{"headers":_vm.headers,"props":_vm.props,"filter-config":_vm.filterConfig,"fetch-action":_vm.fetchAction,"customCreateItem":"","mutation-types":_vm.mutationTypes,"remove-action":_vm.removeAction,"remove-warning":_vm.mainWarning},on:{"create-item":function($event){return _vm.createItem({
                is_company: false,
                contractor_type_id: 1
            })}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('contractor-form',{ref:"materialForm",attrs:{"item-data":_vm.editedItem,"edit":_vm.edit,"show":_vm.formDialog},on:{"saved":function($event){return _vm.itemSaved()},"canceled":function($event){return _vm.closeForm()},"next":function($event){return _vm.createItem({
                        is_company: false,
                        contractor_type_id: 1
                    })}}})]},proxy:true},{key:"item.short_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":`/contractors/${item.id}`}},[_vm._v(" "+_vm._s(item.short_name ? item.short_name : item.name ? item.name : '-')+" ")])]}},{key:"item.type",fn:function({ item }){return [_vm._v(" "+_vm._s(item.type && item.type.name ? item.type.name : '')+" ")]}},{key:"item.specialization",fn:function({ item }){return [_vm._v(" "+_vm._s(item.specialization && item.specialization.name ? item.specialization.name : '')+" ")]}},{key:"item.phones",fn:function({ item }){return [(item.phones && item.phones.length > 0)?_c('div',_vm._l((item.phones),function(phone){return _c('div',{key:phone},[_c('a',{attrs:{"href":`tel:+48${phone}`}},[_vm._v(_vm._s(phone))])])}),0):_c('div',[_vm._v(" - ")])]}},{key:"item.emails",fn:function({ item }){return [(item.emails && item.emails.length > 0)?_c('div',_vm._l((item.emails),function(email){return _c('div',{key:email},[_c('a',{attrs:{"href":`mailto:${email}`}},[_vm._v(_vm._s(email))])])}),0):_c('div',[_vm._v(" - ")])]}},{key:"item.user",fn:function({ item }){return [_c('div',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({on:{"click":function($event){_vm.guardianDialog = item.id}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.user && item.user.name ? item.user.name : '-')+" ")])]}}],null,true)},[(_vm.guardianDialog === item.id)?_c('user-dialog',{attrs:{"id":String(item.user_id)}}):_vm._e()],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }