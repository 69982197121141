
import { Contractor } from '@/modules/contractors/shared/models/contractor';
import { contractorsModule } from '@/modules/contractors/shared/state/module';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { httpClient } from '@/shared/services';
import Vue from 'vue';
import {Component, Prop, Inject} from 'vue-property-decorator';
import ContractorForm from '@/modules/contractors/components/form.vue';
import { investmentsModule } from '../../shared/state/module';
import { SaveResponse } from '@/shared/types';
import { ContractorDataRequest } from '@/modules/contractors/shared/requests/contractor-data-request';

@Component({
    components: {
        AutoCompleteWithAddNew,
        ContractorForm,
    }
})
export default class RelatedContractorsForm extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    @Prop(Array) public relatedContractors!: Contractor[];
    public store = this.$store.state.investmentsState;
    public actionsTypes = investmentsModule.actionsTypes;
    public contractorsStore = this.$store.state.contractorsState;
    public contractorsActionType = contractorsModule.actionsTypes;
    public selectedContractors: Contractor[] = [];
    public updateLoading = false;

    public contractorsModule = contractorsModule;
    public ContractorForm = ContractorForm;
    public contractorFormDialog: boolean = false;
    public newContractor!: Contractor;

    get editedItem() {
        return this.$store.state.contractorsState.editedItem;
    }

    set editedItem(val) {
        this.$store.commit(this.contractorsModule.mutationsTypes.UPDATE_EDITED, val);
    }

    get props() {
        return this.contractorsModule.moduleProps;
    }

    get current() {
        return this.store.current;
    }

    get items() {
        if (this.contractors.length === 0) {
            return this.relatedContractors;
        } else {
            return this.contractors;
        }
    }

    get contractors() {
        return this.contractorsStore.dataList.filter(( contractor: any ) => {
            if (contractor.is_company) {
                return contractor;
            }
        });
    }

    get contractorsLoading() {
        return this.contractorsStore.loadingList;
    }

    public createItem() {
        this.editedItem = {
            is_company: true,
        } as ContractorDataRequest;
        this.contractorFormDialog = true;
    }

    public mounted() {
        this.selectedContractors = this.relatedContractors;
        this.fetchContractorsAll();
    }

    public remove({ id }: Contractor) {
        this.selectedContractors = this.selectedContractors.filter((contractor) => contractor.id !== id);
    }

    public fetchContractorsAll() {
        this.$store.dispatch(this.contractorsActionType.FETCH_DATA, { simple: true });
    }

    public sendNewContractors() {
        this.updateLoading = true;
        const id = this.store.current.id;
        const url = `/api/v1/investments/${id}/contractors`;
        httpClient
            .put(url, { id, contractors: this.selectedContractors })
            .then(({ data }: any) => {
                this.$emit('saved', data.data);
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Zmiany zostały zapisane',
                });
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            })
            .finally(() => {
                this.updateLoading = false;
            });
    }

    public itemSaved({ data }: SaveResponse<any>) {
        this.selectedContractors.push(data);
        this.$store
        .dispatch(this.contractorsActionType.FETCH_DATA, { simple: true })
        .then((res) => this.contractorFormDialog = false);
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`contractors.${action}`);
    }
}
