
import { Vue, Component, Prop } from 'vue-property-decorator';

import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module


import { investmentSubjectPropertyTypesModule, investmentSubjectsModule } from '../../../shared/state/submodules/subjects';

// dataRequest
import { InvestmentSubjectDataRequest } from '../../../shared/requests/investment-subject-data-request';

import { httpClient } from '@/shared/services';
import { apiConstants } from '@/modules/investments/shared/config';

import { investmentObjectsModule } from '@/modules/investments/shared/state/submodules';


@Component({
    components: {FormActions},
})
export default class ImportFromCsvForm extends Vue {
    @Prop(Number) public investmentId!: number;
    @Prop(Number) public objectId!: number;
    public actionsTypes = investmentSubjectsModule.actionsTypes;
    public store = this.$store.state.investmentsState.subjectsState;
    public fetchAction = investmentObjectsModule.actionsTypes.FETCH_DATA;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new InvestmentSubjectDataRequest();
    public valid: boolean = false;
    public file: File | null = null;
    public instructionDialog: boolean = false;
    public subjects: any = null;
    public rooms: any = null;

    public loading: boolean = false;
    public uploading: boolean = false;

    public propertyTypes: [] = [];
    public expanded: [] = [];
    public singleExpand: boolean = true;
    public expandedRooms: [] = [];
    public errorMessages: any = null;
    public error: any = null;


    public roomsListHeaders: any = [
        {
            text: 'Nazwa',
            sortable: false,
            value: 'nazwa'
        },
        {
            text: 'Typ pomieszczenia',
            sortable: false,
            value: 'typ'
        },
        {
            text: 'Poziom',
            sortable: false,
            value: 'poziom'
        },
        {
            text: 'Powierzchnia',
            sortable: false,
            value: 'powierzchnia'
        },
        {
            text: 'Kubatura',
            sortable: false,
            value: 'kubatura'
        },
    ];

    public cancelForm() {
        this.$emit('canceled');
        this.file = null;
        this.subjects = null;
        this.rooms = null;
        this.errorMessages = null;
        this.error = null;
    }

    public clearInput() {
        this.file = null;
        this.subjects = null;
        this.rooms = null;
        this.errorMessages = null;
        this.error = null;
    }

    get subjectsListHeaders() {
        let propertyTypes = [];
        propertyTypes = this.propertyTypes.map((el: any) => {
            return {
                text: el,
                value: el,
                sortable: false,
            };
        });

        return [
            {
                text: 'Nazwa',
                sortable: false,
                value: 'nazwa'
            },
            {
                text: 'Identyfikator',
                sortable: false,
                value: 'identyfikator'
            },
            {
                text: 'Cena',
                sortable: false,
                value: 'cena'
            },
            {
                text: 'Typ przedmiotu',
                sortable: false,
                value: 'typ'
            },
        ].concat(propertyTypes);


    }

    public filterRooms({item}: any) {
        return this.expandedRooms = this.rooms.filter((el: any) => el.identyfikator === item.identyfikator);
    }

    public fetchFilePreview(file: any) {

        if (file) {

            this.loading = true;

            const url = apiConstants.investmentSubjects + '/preview-csv';

            const formData = new FormData();
            formData.append('file', file);

            return httpClient.post(url, {
                investment_id: this.investmentId,
                investment_object_id: this.objectId,
                file,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }}).then((res) => {
                    this.errorMessages = null;
                    this.error = null;
                    this.loading = false;
                    this.valid = true;
                    this.subjects = res.data.subjects;
                    this.rooms = res.data.rooms;
                    this.propertyTypes = res.data.propertiesHeaders;

                }).catch((e: any) => {
                    this.loading = false;
                    this.valid = false;
                    this.errorMessages = e.errors;
                    this.error = e.message;
                    this.subjects = e.data.subjects;
                    this.rooms = e.data.rooms;
                    this.propertyTypes = e.data.propertiesHeaders;
                });

        }

    }



    public uploadFile() {

        if (this.file) {

            this.uploading = true;

            const url = apiConstants.investmentSubjects + '/upload-csv';

            const formData = new FormData();
            formData.append('file', this.file);

            return httpClient.post(url, {
                investment_id: this.investmentId,
                investment_object_id: this.objectId,
                file: this.file,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((res) => {
                this.errorMessages = null;
                this.error = null;
                this.uploading = false;
                this.cancelForm();

                this.$store.dispatch(this.fetchAction, { id: this.investmentId, page: 1});

                if (res.status === 200) {
                    this.$store.commit('SHOW_SNACKBAR', {
                        type: 'success',
                        text: 'Zaimportowano plik CSV',
                    });
                }

            }).catch((e: any) => {
                this.uploading = false;
                this.errorMessages = e.errors;
                this.error = e.message;
            });


        }
    }

}
