import {InvestmentObjectGenerated} from '@/modules/investments/shared/models/investment-object';
import {findLastId} from '../helpers';
import {CRUD} from './CRUD';
import {SubjectController} from './SubjectController';
import {BlockInvestmentLevel} from '@/modules/investments/components/generator/templates/blocks/models/types';

export class ObjectController {
    private itemsCRUD!: CRUD<InvestmentObjectGenerated>;

    constructor(private subjectsController: SubjectController, private objectsTable: InvestmentObjectGenerated[] = []) {
        this.itemsCRUD = new CRUD<InvestmentObjectGenerated>(this.objectsTable);
    }

    public getObjectsTable() {
        return this.objectsTable;
    }

    public storeObject(data: InvestmentObjectGenerated, parentId?: number) {
        const currentId = findLastId(this.objectsTable) + 1;
        const newObject: InvestmentObjectGenerated = {
            ...data,
            id: currentId,
            parent_id: parentId ? parentId : data.parent_id,
        };

        this.objectsTable.push(newObject);

        return newObject;
    }

    public updateObject(data: InvestmentObjectGenerated) {
        this.itemsCRUD.setTable(this.objectsTable);

        const response = this.itemsCRUD.updateItem(data);

        this.objectsTable = response.table;

        return response.item;
    }

    public showObject(id: number): InvestmentObjectGenerated {
        this.itemsCRUD.setTable(this.objectsTable);
        return this.itemsCRUD.showItem(id) || {} as InvestmentObjectGenerated;
    }

    public dropObject(id: number) {
        this.itemsCRUD.setTable(this.objectsTable);
        if (this.objectHasChildren(id)) {
            const objects = this.objectsTable.filter((item) => item.parent_id === id);
            objects.forEach((child) => {
                this.dropObject(child.id);
            });
        }
        if (this.subjectsController.objectHasSubjects(id)) {
            const objects = this.subjectsController.getSubjectTable().filter(
                (item) => item.investment_object_id === id,
            );
            objects.forEach((subject) => {
                this.subjectsController.dropSubject(subject.id);
            });
        }
        this.objectsTable = this.itemsCRUD.dropItem(id);
    }

    public copyObject(id: number, parentId: number, editData: object) {
        this.itemsCRUD.setTable(this.objectsTable);

        const editItem = {
            parent_id: parentId,
            ...editData,
        };

        const response = this.itemsCRUD.cloneItem(id, editItem);

        this.objectsTable = response.table;

        return response.item;
    }

    public cloneObject(id: number, editData?: object): InvestmentObjectGenerated {
        this.itemsCRUD.setTable(this.objectsTable);

        const response = this.itemsCRUD.cloneItem(id, editData);

        this.objectsTable = response.table;

        return response.item;
    }

    public duplicateObject(id: number, duplications?: number) {
        this.itemsCRUD.setTable(this.objectsTable);

        this.objectsTable = this.itemsCRUD.duplicateItem(id, duplications);
    }

    public dropObjectDuplications() {
        this.objectsTable = this.itemsCRUD.dropDuplications();
    }

    public acceptObjectDuplications() {
        const objectsTable = [...this.objectsTable.filter(
            ({duplications = 0}) => duplications && duplications > 0)
        ] as BlockInvestmentLevel[];

        objectsTable.forEach(({id, duplications = 1, level = 0, investment_object_type_id}) => {
            for (let i = 0; i < duplications; i++) {
                const editData = {duplications: undefined} as BlockInvestmentLevel;
                if (level && investment_object_type_id === 3) {
                    editData.level = (level + (duplications - i));
                }
                const newObject = this.cloneObject(id, editData);
                if (this.objectHasChildren(id)) {
                    this.duplicatedByParent(id, newObject.id);
                }
                if (this.subjectsController.objectHasSubjects(id)) {
                    this.subjectsController.duplicatedByObject(id, newObject.id, newObject.level);
                }
            }
            this.duplicateObject(id, undefined);
        });
    }


    public duplicatedByParent(objectId: number, newObjectId: number) {
        const objectChildren = this.objectsTable.filter((item) => item.parent_id === objectId);
        objectChildren.forEach(({id, duplications = 0}) => {
            for (let i = 0; i <= duplications; i++) {
                const newObject = this.copyObject(id, newObjectId, {duplications: undefined});
                if (this.objectHasChildren(id)) {
                    this.duplicatedByParent(id, newObject.id);
                }
                if (this.subjectsController.objectHasSubjects(id)) {
                    this.subjectsController.duplicatedByObject(id, newObject.id);
                }
            }
        });
    }

    public objectHasChildren(objectId: number) {
        return this.objectsTable.filter(
            (item) => item.parent_id === objectId,
        ).length > 0;
    }

    public hasDuplications() {
        return this.objectsTable.filter(({duplications = 0}) => duplications && duplications > 0).length > 0;
    }
}
