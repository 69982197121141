
import FormComponent from '@/shared/components/layout/form/form-component';
import { InvestmentSubjectReservationDataRequest } from '@/modules/investments/shared/requests/subjects/investment-subject-reservation-data-request';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { InvestmentSubjectReservation } from '@/modules/investments/shared/models/subjects/investment-subject-reservation';
import { ModuleState } from '@/shared/state/template/module-state';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { investmentSubjectReservationModule } from '@/modules/investments/shared/state/submodules';
import { callSuper } from '@/shared/helpers';

import DatePicker from '@/shared/components/elements/date-picker.vue';

import { contractorsModule } from '@/modules/contractors/shared/state/module';
import { reservationDurations } from '@/shared/config/investments';
import { Contractor } from '@/modules/contractors/shared/models/contractor';
import { parseDate } from '@/shared/helpers';
import moment from 'moment';

@Component({
    components: {
        FormActions,
        AutoCompleteWithAddNew,
        DatePicker,
    },
})
export default class InvestmentSubjectReservationForm extends FormComponent<InvestmentSubjectReservationDataRequest> {
    public store: ModuleState<InvestmentSubjectReservation, InvestmentSubjectReservationDataRequest> =
        this.$store.state.investmentsState.subjectReservationState;
    public actionsTypes = investmentSubjectReservationModule.actionsTypes;
    public mutationTypes = investmentSubjectReservationModule.mutationsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;

    public requestData = new InvestmentSubjectReservationDataRequest();
    public clients: any = [];
    public reservingClientLoading: boolean = false;

    public reservationDurations = reservationDurations;
    public reservationDuration = 0;
    public parseDate = parseDate;
    public moment = moment;

    get formTitle(): string {
        return this.edit ? 'Edytuj rezerwację przedmiotu inwestycji' : 'Dodaj rezerwację przedmiotu inwestycji';
    }

    public created() {
        this.reservingClientLoading = true;
        this.setReservationDuration();
        this.$store
            .dispatch(contractorsModule.actionsTypes.FETCH_DATA, { filters: { is_company: false } })
            .then((res) => {
                this.clients = res.data.filter((contractor: Contractor) => contractor.type.name === 'Klient');
            })
            .finally(() => {
                this.reservingClientLoading = false;
            });
    }

    public onReservationStartDateChange(value: string) {
        if (this.reservationDuration === 0) {
            this.itemData.reservation_start_date = this.parseDate(value);
        } else if (this.edit) {
            this.itemData.reservation_start_date = this.parseDate(value);
            if (this.reservationDuration !== 0) {
                const reservationEndDate = new Date(value);
                reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);
                this.itemData.reservation_end_date = this.parseDate(reservationEndDate);
            }
        } else if (!this.edit) {
            const reservationEndDate = new Date(value);
            reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);

            this.itemData.reservation_start_date = this.parseDate(value);
            this.itemData.reservation_end_date = this.parseDate(reservationEndDate);
        }
        this.$forceUpdate();
    }

    public onReservationEndDateChange(value: string) {
        this.itemData.reservation_end_date = this.parseDate(value);
    }

    public onReservationDurationChange() {
        const reservationEndDate = new Date(this.itemData.reservation_start_date);
        reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);

        this.$set(this.itemData, 'reservation_end_date', this.parseDate(reservationEndDate));
    }

    public cancelForm() {
        if (!this.edit) {
            this.reservationDuration = 30;
        }
        callSuper(this, 'cancelForm');
    }

    public initForm() {
        this.form = this.$refs.createReservationForm;

        if (!this.edit) {
            this.reservationDuration = 30;
        }
    }

    public setReservationDuration() {
        if (this.itemData.reservation_start_date && this.itemData.reservation_end_date) {
            const startDate = moment(this.itemData.reservation_start_date);
            const endDate = moment(this.itemData.reservation_end_date);

            const duration = this.reservationDurations.find(
                (val) => val.value === endDate.diff(startDate, 'days'),
            );

            if (!duration) { return; }

            this.reservationDuration = duration.value;
        }
    }

    @Watch('store.formDialog')
    public onFormDialogChange() {
        if (this.store.formDialog) {
            if (this.edit) {
                this.reservationDuration = 0;
                this.$forceUpdate();
            } else {
                this.itemData.reservation_start_date = this.parseDate(new Date());
                this.reservationDuration = 30;
                this.$forceUpdate();
            }
        }
    }
}
