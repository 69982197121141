import {FormRules} from '@/shared/validation/form-rules';
import {Component, Vue} from 'vue-property-decorator';
import {InvestmentGeneratorItem} from '@/modules/investments/components/generator/types';

@Component
export default class GeneratorList<T extends InvestmentGeneratorItem> extends Vue {
    public formDialog: boolean = false;
    public removing: boolean = false;
    public editedItem: T = {} as T;
    public value!: T;
    public formRules: FormRules = new FormRules();

    get edit() {
        return this.editedItem && this.editedItem.id && this.editedItem.id > 0;
    }

    public editItem(item?: T) {
        if (item) {
            this.editedItem = JSON.parse(JSON.stringify(item));
        }
        this.formDialog = true;
    }

    public duplicateItem(id: number, duplications?: string) {
        if ((Number(duplications) <= 50) || (duplications === undefined)) {
            this.$emit('edit', {id, duplications : (duplications ? Number(duplications) : duplications)});
        }
    }

    public removeItem(id: number) {
        this.$emit('remove', id);
    }

    public submitForm() {
        this.$emit(this.edit ? 'edit' : 'create', this.editedItem);
        this.formDialog = false;
    }

    public newItem(item?: any) {
        if (item) {
            this.editedItem = {...item};
        }
        this.formDialog = true;
    }
}
