
import Component from 'vue-class-component';
import ListComponent from '@/shared/components/layout/list/list-component';
import { InvestmentObject } from '@/modules/investments/shared/models/investment-object';
import { InvestmentObjectDataRequest } from '@/modules/investments/shared/requests/investment-object-data-request';
import InvestmentObjectForm from '@/modules/investments/components/objects/form.vue';
import { Watch } from 'vue-property-decorator';
import InvestmentObjectDetails from '@/modules/investments/components/objects/details.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import {
    investmentObjectsModule,
    investmentObjectTypesModule,
    investmentSubjectsModule,
} from '@/modules/investments/shared/state/submodules';
import InvestmentObjectMedia from '@/modules/investments/components/objects/media/media.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { SaveResponse } from '@/shared/types';
import InvestmentSubjectsList from '@/modules/investments/components/objects/subjects/list.vue';
import { InvestmentObjectsState } from '@/modules/investments/shared/state/state/objects';
import TotalCosts from '@/modules/investments/components/total-costs.vue';
import { costStatuses } from '@/shared/config/costs';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { callSuper } from '@/shared/helpers';
import ImportFromCsvForm from './subjects/import-from-csv-form.vue';
import _ from 'lodash';

@Component({
    props: {
        investmentId: Number,
        customCreateItem: Boolean,
    },
    components: {
        TextContrast,
        ActionConfirmDialog,
        InvestmentObjectDetails,
        InvestmentObjectMedia,
        InvestmentSubjectsList,
        InvestmentObjectForm,
        TotalCosts,
        TooltipBtn,
        ImportFromCsvForm,
    },
})
export default class InvestmentObjectsList extends ListComponent<InvestmentObject, InvestmentObjectDataRequest> {
    public headers: object[] = [];
    public investmentId!: number;
    public actionsTypes = investmentObjectsModule.actionsTypes;
    public mutationTypes = investmentObjectsModule.mutationsTypes;
    public typesActions = investmentObjectTypesModule.actionsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public props: ModuleProps = investmentObjectsModule.moduleProps;
    public store: InvestmentObjectsState = this.$store.state.investmentsState.objectsState;
    public parent: any = {};
    public ob = [];
    public searchObject: string = '';
    public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));
    public currentStatus = 0;
    public disableDelete = false;
    public csvImportFormDialog: boolean = false;

    public created() {
        this.id = Number(this.investmentId);
        this.fetchData();
    }

    public deleteItem(item: InvestmentObject) {
        callSuper(this, 'deleteItem', item);
    }

    get openObject() {
        return this.store.openObject;
    }

    set openObject(val) {
        this.$store.commit(this.mutationTypes.UPDATE_OPEN, val);
    }

    get activeObjectAmountOfChildrenAndSubjects() {
        const haveChildren = (children: InvestmentObject[]) => (children && children.length > 0 ? true : false);
        const activeObject = this.activeObject[0];
        let numberOfChildObjects = 0;
        let numberOfSubjects = 0;

        const countChildrenAndSubjects = (object: InvestmentObject) => {
            if (object && haveChildren(object.children)) {
                numberOfChildObjects += object.children.length;
                object.children.forEach((child: InvestmentObject) => {
                    countChildrenAndSubjects(child);
                });
            }
            if (object && object.subject_count) {
                numberOfSubjects += object.subject_count;
            }
        };

        countChildrenAndSubjects(activeObject);
        return { children: numberOfChildObjects, subjects: numberOfSubjects };
    }

    get warningInfo() {
        const { children, subjects } = this.activeObjectAmountOfChildrenAndSubjects;

        const childrenCount = this.props.nameNumeral(children, false);
        const subjectsCount = investmentSubjectsModule.moduleProps.nameNumeral(subjects, false);

        if (children > 0 && subjects > 0) {
            return `Pod tą kategorią jest/są ${childrenCount} oraz ${subjectsCount}`.replaceAll('inwestycji', '');
        }
        if (children > 0) {
            return `Pod tą kategorią jest/są ${childrenCount}`.replaceAll('inwestycji', '');
        }
        if (subjects > 0) {
            return `Pod tą kategorią jest/są ${subjectsCount}`.replaceAll('inwestycji', '');
        }
        return '';
    }

    get activeObject() {
        return this.store.activeObject;
    }

    set activeObject(val) {
        this.$store.commit(this.mutationTypes.UPDATE_ACTIVE, val);
    }

    public async objectSaved({ data, edit, next }: SaveResponse<InvestmentObject>) {
        if (!edit) {
            if (data.parent_id) {
                const object = this.activeObject[0];
                if (object) {
                    object.children.push(data);
                    if (!this.openObject.includes(object)) {
                        this.openObject.push(object);
                    }
                    if (!next) {
                        this.activeObject = [data];
                    }
                }
            } else {
                if (this.items.length + 1 < this.per_page) {
                    this.items.push(data);
                } else {
                    this.itemSaved();
                }
            }
        } else {
            for (const item in this.activeObject[0]) {
                if (this.activeObject[0].hasOwnProperty(item) && item !== 'children') {
                    this.activeObject[0][item] = data[item];
                }
            }
        }
        this.formDialog = false;
    }

    public editItem(item: InvestmentObject) {
        if (item.parent) {
            this.parent = {
                name: item.parent.name,
                icon: item.parent.type.icon.slug,
            };
        }
        callSuper(this, 'editItem', item);
    }

    public objectRemoved() {
        if (this.store.current && this.store.current.parent_id ? this.store.current.parent_id > 0 : false) {
            const parent = this.store.openObject.find(
                ({ id }: InvestmentObject) => id === (this.store.current ? this.store.current.parent_id : 0),
            );
            if (parent) {
                const childIndex = parent.children.indexOf(this.store.current);
                parent.children.splice(childIndex, 1);
            }
        } else {
            const objectIndex = this.items.indexOf(this.store.current || ({} as InvestmentObject));
            this.items.splice(objectIndex, 1);
        }
        this.store.current = {} as InvestmentObject;
    }

    public createChildObject(item?: InvestmentObject) {
        if (item) {
            this.editedItem = {
                parent_id: item.id,
                investment_id: this.investmentId,
                investment_object_type_id: item.investment_object_type_id,
            } as InvestmentObjectDataRequest;
            this.parent = {
                name: item.name,
                icon: item.type.icon && item.type.icon.slug ? item.type.icon.slug : 'mdi-shape',
            };
            this.formDialog = true;
        }
    }

    public formNext(item: InvestmentObject) {
        if (item.parent_id ? item.parent_id : 0 > 0 && item.parent) {
            this.createChildObject(item.parent as InvestmentObject);
        } else {
            this.setDefaultData();
            this.formDialog = true;
        }
    }

    public reloadCount() {
        this.fetchData();
    }

    @Watch('activeObject')
    private changeActiveObject(newVal: InvestmentObject[], oldVal: InvestmentObject[]) {
        if (newVal.length > 0 && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.$store.commit(this.mutationTypes.UPDATE_CURRENT, newVal[0]);
            if (!this.openObject.includes(newVal[0])) {
                if (newVal[0].parent_id ? newVal[0].parent_id : 0 > 0) {
                    this.openObject.push(newVal[0]);
                    if (oldVal.length > 0) {
                         if (newVal[0].parent_id === oldVal[0].parent_id) {
                             if (this.openObject.indexOf(oldVal[0]) >= 0) {
                                this.openObject.splice(this.openObject.indexOf(oldVal[0]), 1);
                            }
                        }
                    }
                } else {
                    this.openObject = [newVal[0]];
                }
            }
        } else if (newVal.length === 0 && oldVal.length !== 0) {
            this.activeObject = [];
        }
    }

    @Watch('items')
    private itemSubjectsSum() {
        if (!this.items) {
            return;
        }

        const getChildrenSubjectsSum = (children: InvestmentObject[]) => {
            let childrenSubjectsSum = 0;

            children.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    item.subject_count += getChildrenSubjectsSum(item.children);
                }
                childrenSubjectsSum += item.subject_count;
            });

            return childrenSubjectsSum;
        };

        this.items.forEach((item) => {
            item.subject_count += getChildrenSubjectsSum(item.children);
        });
    }


}
