import { render, staticRenderFns } from "./ticket-loader.vue?vue&type=template&id=82520226&scoped=true&"
import script from "./ticket-loader.vue?vue&type=script&lang=ts&"
export * from "./ticket-loader.vue?vue&type=script&lang=ts&"
import style0 from "./ticket-loader.vue?vue&type=style&index=0&id=82520226&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82520226",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VContainer,VProgressCircular,VSkeletonLoader})
