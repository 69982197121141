
import { Component, Vue, Watch } from 'vue-property-decorator';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import SystemBar from '@/shared/components/elements/system-bar.vue';
import EventForm from '../form.vue';
import EventDetails from '../details.vue';
import { httpClient } from '@/shared/services';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import RelatedContractorsListItem from './list-item.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { Contractor } from '@/modules/contractors/shared/models/contractor';
import RelatedContractorsForm from './form.vue';
import RelatedContractorsList from './list.vue';

@Component({
    components: {
        ActionConfirmDialog,
        EventDetails,
        SystemBar,
        TooltipBtn,
        EventForm,
        NameReduce,
        RelatedContractorsListItem,
        AutoCompleteWithAddNew,
        RelatedContractorsForm,
        RelatedContractorsList,
    },
})
export default class RelatedContractors extends Vue {
    public store = this.$store.state.investmentsState;
    public relatedContractors: Contractor[] = [];
    public x: number = 0;
    public y: number = 0;
    public contractorDialog = false;
    public listDialog = false;
    public loading = true;

    get disabled() {
        return !this.store.current || this._.isEmpty(this.store.current);
    }

    get isMobile() {
        return (this as any).$vuetify.breakpoint.smAndDown;
    }

    public showMenu(event: MouseEvent) {
        this.fetchContractors();
        this.listDialog = true;
        const target = event.target as Element;
        const boundingReact = target.getBoundingClientRect();
        this.x = boundingReact.x + 500 < window.innerWidth ? boundingReact.x - 250 : window.innerWidth - 500;
        this.y = boundingReact.top + 36;
    }

    public closeDialog() {
        this.listDialog = false;
    }

    public fetchContractors() {
        const id = this.store.current.id;
        const url = `/api/v1/investments/${id}/contractors`;
        httpClient.get(url).then(({ data }) => {
            this.relatedContractors = data.data;
            this.loading = false;
        });
    }

    public onRelatedContractorsSaved(data: any) {
        this.contractorDialog = false;
        this.relatedContractors = data;
    }


    /** Called from Investment details,
     * makes sure that global scrollbar is visible when changing route
     */
    public onRouteLeave() {
        this.listDialog = false;
        (document.querySelector('html') as HTMLElement).style.overflow = 'auto';
    }

    @Watch('listDialog')
    @Watch('contractorDialog')
    @Watch('isMobile')
    /** Fixes scrollbar bug that occurs when resizing or closing form */
    public handleScrollbarsDisplay() {
        if (this.isMobile && this.listDialog) {
            (document.querySelector('html') as HTMLElement).style.overflow = 'hidden';
        } else {
            (document.querySelector('html') as HTMLElement).style.overflow = 'auto';
        }
    }
}
