

import {Component, Vue} from 'vue-property-decorator';
import {investmentSubjectOperationsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {
    InvestmentSubjectOperationDataRequest
} from '@/modules/investments/shared/requests/subjects/investment-subject-operation-data-request';

@Component
export default class RemoveSubjectsSummary extends Vue {
    public store = this.$store.state.investmentsState.subjectOperationsState;
    public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
    public mutationTypes = investmentSubjectOperationsModule.mutationsTypes;
    public success: boolean = false;

    get editedItem() {
        return this.store.editedItem;
    }

    set editedItem(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, value);
    }

    get loading() {
        return this.store.loadingItem;
    }

    public created() {
        if (!this.editedItem.selection || this.editedItem.selection.length < 1) {
            this.$router.push({name: 'subjects-list'});
        }
    }

    public submitOperation() {
        const item = Object.assign({}, this.editedItem);
        item.selection = item.selection.map((e: InvestmentSubject) => e.id);
        this.$store.dispatch(this.actionsTypes.REMOVE_SUBJECTS, item).then((result) => {
            this.success = (result === 204);
            this.editedItem = {} as InvestmentSubjectOperationDataRequest;
        });
    }
}
